import React, { useEffect, useState } from "react";
import { useRouter } from "../../../common/useRouter";
import { useTranslation } from "react-i18next";
import {
	Box,
	Flex,
	Button,
	Text,
	IconButton,
	Image,
	Tooltip,
	Link
} from "@chakra-ui/react";
import { MdDiamond, MdCheckCircleOutline } from "react-icons/md";
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../../common/api_routes";
import CustomTable from "../../../components/custom-table";
import { WebUrl } from "../../../routes";
import {
	Role,
	_DIAMOND_SHAPE_OPTIONS,
	_DIAMOND_CLARITY_OPTIONS,
	_DIAMOND_COLOR_OPTIONS,
	_DIAMOND_FINISH_LEVEL_OPTIONS,
	_DIAMOND_FLUORESCENSE_OPTIONS,
	_DIAMOND_LAB_OPTIONS,
	_DIAMOND_LOCATION_OPTIONS,
	_DIAMOND_TINGE_SHADE_OPTIONS,
	_DIAMOND_TINGE_MILKY_OPTIONS,
	_DIAMOND_TINGE_EYE_CLEAN_OPTIONS,
	_DIAMOND_TREATMENT_OPTIONS,
	_DIAMOND_TYPE_OPTION,
	_DIAMOND_AVAILABILITY_OPTIONS,
	ApiKey,
	CommonStatus,
	SupplierProfileStatus
} from "../../../common/constant";
import moment from "moment";
import ApiEngine from "../../../common/api-engine";
import { createMultiPartFormBody, stringIsNullOrEmpty, isObjectEmpty, isVerifiedSupplier, numberWithCurrencyFormat, getLabCertificateTrackUrl, renderDiamondTypeBadge } from "../../../common/util";
import { showCustomDialog, showCustomInputDialog } from "../../../application/action/app_action";
import CustomMediaUploader from "components/custom-media-uploader";
import { RiDeleteBin6Line } from "react-icons/ri";
import DiamondDetailModal from "../../../components/diamond-detail-modal";
import DiamondDetail from "../../../components/diamond-detail";
import DiamondDetailGrid from "../../../components/diamond-detail-grid";

/// <summary>
/// Author: CK
/// </summary>
export default () => {
	var _dispatch = useDispatch();
	var _router = useRouter();
	const { t } = useTranslation();
	const _USER_DATA = useSelector((state) => state.authState.userData);
	const [apiUrl, setApiUrl] = useState();
	const [selectedDiamonds, setSelectedDiamonds] = useState([]);
	const [checkAll, setCheckAll] = useState(false);
	const [allDiamonds, setAllDiamonds] = useState([]);
	const [uploadModal, setUploadModal] = useState({});
	const [uploadedMedia, setUploadedMedia] = useState([]);
	const [mediaToDelete, setMediaToDelete] = useState([]);
	const [selectedDiamond, setSelectedDiamond] = useState();

	const _COLUMNS = [
		..._USER_DATA?.user?.roleId == Role._SUPPLIER ?
			[{
				id: 'checkbox',
				headerWidth: '1%',
				customHeader: (
					<div className="checkbox checkbox-css checkbox-inline inbox-checkbox">
						<input id="checkboxSelectAll" type="checkbox" onChange={() => setCheckAll(!checkAll)} checked={checkAll} />
						<label htmlFor="checkboxSelectAll"></label>
					</div>
				),
				Cell: ({ row }) => {
					return (
						<div className="checkbox checkbox-css checkbox-inline inbox-checkbox">
							<input
								id={`checkbox${row.id}`}
								type="checkbox"
								onChange={() => updateSelectedDiamonds(row.original.id)}
								checked={selectedDiamonds.find(t => t == row.original.id)} />
							<label htmlFor={`checkbox${row.id}`}></label>
						</div>
					)
				},
				width: 30,
				disableSortBy: true,
			}] : [],
		,
		// {
		// 	id: 'deleteBtn',
		// 	headerWidth: '1%',
		// 	customHeader: (
		// 		<span />
		// 	),
		// 	Cell: ({ row }) => {
		// 		return (
		// 			<IconButton
		// 				variant='solid'
		// 				bg='red.400'
		// 				color='white'
		// 				size='xs'
		// 				aria-label={t('DELETE')}
		// 				icon={<RiDeleteBin6Line />}
		// 				onClick={() => {
		// 					_dispatch(showCustomDialog({
		// 						success: true,
		// 						content: t("DELETE_DIAMOND"),
		// 						onCancel: () => { },
		// 						onConfirm: async () => {
		// 							await deleteDiamond(row.original.id);
		// 						}
		// 					}));
		// 				}}
		// 			/>
		// 		)
		// 	},
		// 	width: 30,
		// 	disableSortBy: true,
		// },
		// {
		// 	Header: "TYPE",
		// 	accessor: "typeId",
		// 	Cell: ({ row }) => {
		// 		return <Text>{t(_DIAMOND_TYPE_OPTION.find(i => i.id == row.original.typeId)?.label)}</Text>
		// 	}
		// },
		{
			Header: "UPLOADED_DATE_TIME",
			accessor: "createdAt",
			Cell: ({ row }) => {
				return <Text>{moment(row.original.updatedAt).format('YYYY-MM-DD h:mm:ss A')}</Text>
			}
		},
		..._USER_DATA?.user?.roleId != Role._SUPPLIER ?
			[{
				Header: "SUPPLIER",
				accessor: "supplierName"
			}] : [],
		{
			Header: "STOCK_NO",
			accessor: "stockNo",
			Cell: ({ row }) => {
				return <Flex alignItems={'center'}>
					{renderDiamondTypeBadge(row.original.typeId)}
					<Text ml="3px">{row.original.stockNo}</Text>
				</Flex>
			}
		},
		{
			Header: "DETAIL",
			Cell: ({ row }) => {
				return <Tooltip label={t("DETAIL")}><IconButton style={{ padding: '10px' }} bgColor={"brand.800"} color={'white'} icon={<MdDiamond size={'16px'} />} onClick={() => setUploadModal(row.original)} /></Tooltip>
			}
		},
		{
			Header: "AVAILABILITY",
			accessor: "availability",
			Cell: ({ row }) => {
				return <Button
					fontSize={'12px'}
					className="link-btn"
					variant='link'
					onClick={() => {
						setSelectedDiamond(row.original);
					}}
				>
					{t(_DIAMOND_AVAILABILITY_OPTIONS.find(i => i.id == row.original.availability)?.label)}
				</Button>
			}
		},
		{
			Header: "SHAPE",
			accessor: "shapeId",
			Cell: ({ row }) => {
				return <Tooltip label={t(_DIAMOND_SHAPE_OPTIONS.find(i => i.id == row.original.shapeId)?.label)}><Image className="diamond-shape-img" src={_DIAMOND_SHAPE_OPTIONS.find(i => i.id == row.original.shapeId)?.image} /></Tooltip>
			}
		},
		{
			Header: "CARAT",
			accessor: "carat",
			Cell: ({ row }) => {
				return <Text>{numberWithCurrencyFormat(row.original.carat, 2, true)}</Text>
			}
		},
		{
			Header: "COLOR",
			accessor: "colorId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_COLOR_OPTIONS.find(i => i.id == row.original.colorId)?.label)}</Text>
			}
		},
		{
			Header: "CLARITY",
			accessor: "clarityId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_CLARITY_OPTIONS.find(i => i.id == row.original.clarityId)?.label)}</Text>
			}
		},
		{
			Header: "CUT",
			accessor: "cutFinishLevelId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_FINISH_LEVEL_OPTIONS.find(i => i.id == row.original.cutFinishLevelId)?.label)}</Text>
			}
		},
		{
			Header: "POLISH",
			accessor: "polishFinishLevelId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_FINISH_LEVEL_OPTIONS.find(i => i.id == row.original.polishFinishLevelId)?.label)}</Text>
			}
		},
		{
			Header: "SYMMETRY",
			accessor: "symmetryFinishLevelId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_FINISH_LEVEL_OPTIONS.find(i => i.id == row.original.symmetryFinishLevelId)?.label)}</Text>
			}
		},
		{
			Header: "FLUO",
			accessor: "fluorescenceId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_FLUORESCENSE_OPTIONS.find(i => i.id == row.original.fluorescenceId)?.label)}</Text>
			}
		},
		{
			Header: "LAB",
			accessor: "labId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_LAB_OPTIONS.find(i => i.id == row.original.labId)?.label)}</Text>
			}
		},
		{
			Header: "CERTIFICATE",
			accessor: "certificateId",
			Cell: ({ row }) => {
				return <Link
					fontSize={'13px'}
					color='gold.600'
					href={getLabCertificateTrackUrl(row.original.labId, row.original.certificateId)} isExternal>
					{row.original.certificateId}
				</Link>
			}
		},
		{
			Header: "PRICE_PER_CARAT",
			accessor: "pricePerCarat",
			Cell: ({ row }) => {
				return <Text>${numberWithCurrencyFormat(_USER_DATA?.user?.roleId == Role._SUPPLIER ? row.original.pricePerCarat : row.original.markupPricePerCarat)}</Text>
			}
		},
		{
			Header: "TOTAL",
			Cell: ({ row }) => {
				return <Text>${numberWithCurrencyFormat(_USER_DATA?.user?.roleId == Role._SUPPLIER ? row.original.price : row.original.totalPrice)}</Text>
			}
		}
	];

	/// <summary>
	/// Author: CK
	/// Edited: Saitama
	/// </summary> 
	useEffect(() => {
		(async () => {
			let statusCode = await isVerifiedSupplier(_USER_DATA?.user);

			if (statusCode == SupplierProfileStatus._SUCCESS) {
				let currentApiUrl = `${Routes._INVENTORY_DIAMONDS_DRAFT}?status=${CommonStatus._ACTIVE}`;

				if (_USER_DATA?.user?.roleId == Role._SUPPLIER) {
					currentApiUrl += `&supplierId=${_USER_DATA?.user?.id}`;
				}

				setApiUrl(currentApiUrl);
			}
			else {
				_dispatch(
					showCustomDialog({
						success: false,
						content: statusCode == SupplierProfileStatus._PENDING_APPROVAL ? 'WE_ARE_CURRENTLY_PROCESSING_YOUR_ACCOUNT' : "PLEASE_COMPLETE_YOUR_COMPANY_PROFILE_TO_PROCEED",
						confirmTxt: statusCode == SupplierProfileStatus._PENDING_APPROVAL ? 'OK' : "COMPANY_PROFILE",
						onConfirm: () => {
							if (statusCode == SupplierProfileStatus._EMPTY_PROFILE) {
								_router.navigate(WebUrl._SUPPLIER_PROFILE_SETTINGS);
							}
						}
					})
				);
			}

		})();
	}, []);

	/// <summary>
	/// Author: CK
	/// </summary> 
	useEffect(() => {
		let diamonds = [];

		if (checkAll && allDiamonds.length > 0) {
			diamonds = allDiamonds.map(selected => selected.id);
		}

		setSelectedDiamonds(diamonds);
	}, [checkAll]);

	/// <summary>
	/// Author: CK
	/// </summary>
	useEffect(() => {
		if (selectedDiamond) {
			_dispatch(showCustomInputDialog({
				success: true,
				title: t("STOCK_NO_VALUE", { stockNo: selectedDiamond.stockNo }),
				content: t("AVAILABILITY"),
				onCancel: () => { setSelectedDiamond(); },
				onConfirm: (inputValue) => updateDiamond(inputValue),
				inputType: "select",
				options: _DIAMOND_AVAILABILITY_OPTIONS,
				isRequired: true,
				defaultValue: selectedDiamond.availability
			}));
		}
	}, [selectedDiamond]);

	/// <summary>
	/// Author: CK
	/// </summary>
	const updateDiamond = async (inputValue) => {
		try {
			let responseJson = await ApiEngine.put(`${Routes._INVENTORY_DIAMONDS_DRAFT}/${selectedDiamond.id}`, { availability: inputValue });

			if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}

			setSelectedDiamond();
			// _dispatch(disposeCustomInputDialog());
			setApiUrl(apiUrl + '&v=' + moment());
		}
		catch (err) {
			_dispatch(
				showCustomDialog({
					success: false,
					content: err
				})
			);
		}
	};

	/// <summary>
	/// Author: CK
	/// </summary> 
	function updateSelectedDiamonds(diamondId) {
		if (selectedDiamonds.filter(diamond => diamond == diamondId).length) {
			setSelectedDiamonds(selectedDiamonds.filter(diamond => diamond != diamondId));
		}
		else {
			setSelectedDiamonds([...selectedDiamonds, diamondId]);
		}
	}

	/// <summary>
	/// Author: CK
	/// </summary>
	const publishDiamonds = async () => {
		try {
			if (selectedDiamonds.length <= 0) {
				throw "Please select diamond to publish.";
			}

			let params = {
				selectedDiamonds: selectedDiamonds
			};

			var responseJson = await ApiEngine.post(Routes._INVENTORY_PUBLISH_DIAMONDS_DRAFT, params);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				_dispatch(
					showCustomDialog({
						success: true,
						content: responseJson[ApiKey._API_MESSAGE_KEY],
					})
				);
				setApiUrl(apiUrl + '&v=' + moment());
				setSelectedDiamonds([]);
				setCheckAll(false);
			}
			else {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}
		}
		catch (err) {
			_dispatch(
				showCustomDialog({
					success: false,
					content: err,
				})
			);
		}
	};

	/// <summary>
	/// Author: Saitama
	/// </summary>
	const handleFileListChange = ({ fileList: newFileList }) => {
		setUploadedMedia(newFileList);
	};

	/// <summary>
	/// Author: Saitama
	/// </summary>
	const uploadDiamondMedia = async () => {
		try {
			if (uploadedMedia.length <= 0 && mediaToDelete.length == 0) {
				throw "Please upload media(s).";
			}

			let params = {
				diamondDraftId: uploadModal.id
			};

			if (uploadedMedia.length > 0) {
				params["files"] = uploadedMedia;
			}

			if (mediaToDelete.length > 0) {
				params['removeIds'] = mediaToDelete.join(',');
			}
			var responseJson = await ApiEngine.put(Routes._DIAMOND_MEDIA_UPLOAD, createMultiPartFormBody(params), {
				headers: {
					'content-type': ApiKey._API_MULTIPART_FORM
				}
			});

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				_dispatch(
					showCustomDialog({
						success: true,
						content: responseJson[ApiKey._API_MESSAGE_KEY],
						onConfirm: () => {
							setApiUrl(apiUrl + '&v=' + moment());
							setUploadModal({});
							setUploadedMedia([]);
							setMediaToDelete([]);
						}
					})
				);
			}
			else {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}
		}
		catch (err) {
			_dispatch(
				showCustomDialog({
					success: false,
					content: err,
				})
			);
		}
	};

	/// <summary>
	/// Author: CK
	/// </summary>
	const deleteDiamonds = async () => {
		try {
			if (selectedDiamonds.length <= 0) {
				throw "Please select diamond to delete.";
			}

			var responseJson = await ApiEngine.post(Routes._INVENTORY_DIAMONDS_DRAFT_DELETE, selectedDiamonds);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				_dispatch(
					showCustomDialog({
						success: true,
						content: responseJson[ApiKey._API_MESSAGE_KEY],
					})
				);
				setApiUrl(apiUrl + '&v=' + moment());
				setSelectedDiamonds([]);
				setCheckAll(false);
			}
			else {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}
		}
		catch (err) {
			_dispatch(
				showCustomDialog({
					success: false,
					content: err,
				})
			);
		}
	};

	return (
		<Box pt={{ base: "80px", md: "60px", xl: "60px" }}>
			<Card
				justifyContent='center'
				align='center'
				direction='column'
				w='100%'
				mb='0px'>
				{
					_USER_DATA?.user?.roleId == Role._SUPPLIER &&
					<Flex justifyContent={'flex-end'}>
						<Button
							variant='solid'
							bg='red.400'
							color='white'
							fontSize="sm"
							fontWeight="500"
							type="button"
							leftIcon={<RiDeleteBin6Line />}
							mr={'5px'}
							onClick={() => {
								_dispatch(showCustomDialog({
									success: true,
									content: t("DELETE_DIAMOND"),
									onCancel: () => { },
									onConfirm: async () => {
										await deleteDiamonds();
									}
								}));
							}}
						>
							{t('DELETE')}
						</Button>
						<Button
							fontSize="sm"
							variant="brand"
							fontWeight="500"
							type="button"
							leftIcon={<MdCheckCircleOutline />}
							onClick={() => publishDiamonds()}
						>
							{t('PUBLISH')}
						</Button>
					</Flex>
				}
				<CustomTable
					columnsData={_COLUMNS}
					apiUrl={apiUrl}
					customFetchAction={(responseJson) => setAllDiamonds(responseJson[ApiKey._API_DATA_KEY])}
					RowAccordionContent={DiamondDetail}
					GridViewItem={DiamondDetailGrid}
				/>
			</Card>
			<DiamondDetailModal
				uploadDiamondMedia={uploadDiamondMedia}
				uploadModal={uploadModal}
				setUploadModal={setUploadModal}
				setMediaToDelete={setMediaToDelete}
				mediaToDelete={mediaToDelete}
				setUploadedMedia={setUploadedMedia}
			/>
		</Box>
	)
}