import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import {
    Box,
    Flex,
    Button,
    Text,
    Link,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Divider,
    Badge,
    Stack,
    Image,
    SimpleGrid
} from "@chakra-ui/react";
import {
    Role,
    _DIAMOND_SHAPE_OPTIONS,
    _DIAMOND_CLARITY_OPTIONS,
    _DIAMOND_COLOR_OPTIONS,
    _DIAMOND_FINISH_LEVEL_OPTIONS,
    _DIAMOND_FLUORESCENSE_OPTIONS,
    _DIAMOND_LAB_OPTIONS,
    _DIAMOND_LOCATION_OPTIONS,
    _DIAMOND_TINGE_SHADE_OPTIONS,
    _DIAMOND_TINGE_MILKY_OPTIONS,
    _DIAMOND_TINGE_EYE_CLEAN_OPTIONS,
    _DIAMOND_TREATMENT_OPTIONS,
    _DIAMOND_TYPE_OPTION,
    ApiKey,
    InvoiceStatus,
    RegexPattern
} from "../common/constant";
import moment from "moment";
import { createMultiPartFormBody, stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat, renderDiamondTypeBadge, getLabCertificateTrackUrl, renderInvoiceStatus, getFileTypeFromUrl } from "../common/util";
import { FaFileInvoiceDollar, FaMoneyCheckDollar, } from "react-icons/fa6";
import { TbReportMoney } from "react-icons/tb";
import { showCustomDialog } from "../application/action/app_action";
import ApiEngine from "../common/api-engine";
import { WebUrl } from "../routes";
import Routes from "../common/api_routes";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import CustomSwitch from "./custom-switch";
import CustomInput from "./custom-input";
import CustomPhoneInput from "./custom-phone-input";
import CustomSelect from "./custom-select";
import CustomMediaUploader from "./custom-media-uploader";

/// <summary>
/// Author: CK
/// </summary>
const InvoiceDiamondDetail = React.forwardRef((props, ref) => {
    const {
        item,
        ...restProps
    } = props;
    var _dispatch = useDispatch();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    const { t } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const [infoModal, setInfoModal] = useState({});
    const [sameAsProfile, setSameAsProfile] = useState(false);
    const [sameAsShipping, setSameAsShipping] = useState(false);
    const [jewellerProfile, setJewellerProfile] = useState({});
    const [receipt, setReceipt] = useState();
    const [previewReceipt, setPreviewReceipt] = useState([]);
    const _COUNTRY_LIST = require('../common/countries.json');

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        (async () => {
            try {
                if (!isObjectEmpty(infoModal)) {
                    var responseJson = await ApiEngine.get(`${Routes._USER}/${item.jewellerId}`, { headers: { "skipLoading": true } });

                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setJewellerProfile(responseJson[ApiKey._API_DATA_KEY]);
                    }

                    if (!stringIsNullOrEmpty(item?.bankReceiptAttachment)) {
                        setPreviewReceipt([{
                            id: 'bankReceiptAttachment',
                            mediaType: getFileTypeFromUrl(item?.bankReceiptAttachment),
                            mediaUrl: item?.bankReceiptAttachment
                        }])
                    }
                }
            }
            catch (err) {

            }
        })();
    }, [infoModal]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (sameAsProfile) {
            setValue('personInCharge', `${jewellerProfile?.firstName} ${jewellerProfile?.lastName}`);
            setValue('phoneNumber', jewellerProfile?.phoneNumber);
            setValue('email', jewellerProfile?.email);
            setValue('shipToCompanyName', jewellerProfile?.jewellerProfile?.companyName);
            setValue('shipToAddress', jewellerProfile?.shippingInfo?.address);
            setValue('shipToZipCode', jewellerProfile?.shippingInfo?.zipCode);
            setValue('shipToCity', jewellerProfile?.shippingInfo?.city);
            setValue('shipToState', jewellerProfile?.shippingInfo?.state);
            setValue('shipToCountry', jewellerProfile?.shippingInfo?.country);
            setValue('shipToPhoneNumber', jewellerProfile?.shippingInfo?.phoneNumber);
            setValue('shipToEmail', jewellerProfile?.shippingInfo?.email);
            setValue('shipToCompanyTaxId', jewellerProfile?.shippingInfo?.companyTaxId);
            setValue('shipToPortOfDischarge', jewellerProfile?.shippingInfo?.portOfDischarge);
        }
    }, [sameAsProfile]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (sameAsShipping) {
            setValue('billToCompanyName', watch('shipToCompanyName'));
            setValue('billToAddress', watch('shipToAddress'));
            setValue('billToZipCode', watch('shipToZipCode'));
            setValue('billToCity', watch('shipToCity'));
            setValue('billToState', watch('shipToState'));
            setValue('billToCountry', watch('shipToCountry'));
            setValue('billToPhoneNumber', watch('shipToPhoneNumber'));
            setValue('billToEmail', watch('shipToEmail'));
        }
    }, [sameAsShipping]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function downloadInvoice(url) {
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/octet-stream',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "INV_" + item.invoiceNo
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((error) => console.error('Download error:', error));
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    function closeInfoModal(refresh = false) {
        setInfoModal({});
        setSameAsProfile(false);
        setSameAsShipping(false);
        setPreviewReceipt([]);

        if (refresh) {
            props.onRefresh();
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const onSubmit = async (data, e) => {
        try {
            let params = { ...data };
            Object.keys(params).forEach(key => {
                if (params[key] === '' || params[key] === undefined) {
                    delete params[key];
                }
            });

            if (infoModal?.paid) {
                params['paid'] = true;

                if (receipt) {
                    params['bankReceiptAttachment'] = receipt;
                }
            }

            var responseJson = await ApiEngine.put(`${Routes._INVENTORY_INVOICE}/${item.id}/info`, createMultiPartFormBody(params), {
                headers: {
                    'content-type': ApiKey._API_MULTIPART_FORM
                }
            });

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            closeInfoModal(true);
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
    };

    return (
        <Box className="diamond-detail-accordion" bgColor={'gray.100'} p={'15px'}>
            <Flex flexDir={'column'}>
                <Flex flexDir={'row'} justifyContent={'space-between'} mb={"10px"}>
                    <Flex flexDir="column" alignItems={'center'}>
                        {renderInvoiceStatus(item.status)}
                    </Flex>
                    <Flex gap={2}>
                        <Button
                            isDisabled={item.status == InvoiceStatus._RECEIVED}
                            colorScheme="green"
                            fontSize="sm"
                            fontWeight="500"
                            type="button"
                            ml="5px"
                            leftIcon={<TbReportMoney />}
                            onClick={() => { setInfoModal({ paid: true }); }}
                        >
                            {t('UPDATE_PAYMENT_TRACKING')}
                        </Button>
                        <Button
                            isDisabled={item.status == InvoiceStatus._RECEIVED}
                            colorScheme="teal"
                            fontSize="sm"
                            fontWeight="500"
                            type="button"
                            ml="5px"
                            leftIcon={<FaMoneyCheckDollar />}
                            onClick={() => { setInfoModal({ paid: false }); }}
                        >
                            {t('UPDATE_BILLING_SHIPPING_INFO')}
                        </Button>
                        <Button
                            colorScheme="blue"
                            fontSize="sm"
                            fontWeight="500"
                            type="button"
                            ml="5px"
                            leftIcon={<FaFileInvoiceDollar />}
                            onClick={() => { downloadInvoice(item.invoiceUrl); }}
                        >
                            {t('VIEW_INVOICE')}
                        </Button>
                    </Flex>
                </Flex>
                <TableContainer width={'100%'}>
                    <Table variant='simple' className="hold-diamond-table">
                        <Thead backgroundColor={'gray.300'}>
                            <Tr>
                                <Th>{t("ORDER_NO")}</Th>
                                <Th>{t("DIAMOND_DETAILS")}</Th>
                                <Th>{t("LOCATION")}</Th>
                                <Th textAlign={'right'}>{t("TOTAL_PRICE")}<br />$/CT</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                item.orders.map(order => {
                                    let diamondDetail = order.diamond != undefined ? order.diamond : order;
                                    let diamondImg = diamondDetail.diamondMedias.find(item => item.mediaType === 'Image');

                                    return (
                                        <Tr>
                                            <Td>
                                                <Text fontSize="15px">
                                                    {order.orderNo}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Flex flexDirection={'row'} alignItems={'center'}>
                                                    {
                                                        diamondImg ?
                                                            <Image src={diamondImg.mediaUrl} />
                                                            : <Image src={_DIAMOND_SHAPE_OPTIONS.find(i => i.id == diamondDetail.shapeId)?.defaultImg} />
                                                    }
                                                    <Flex ml='10px' flexDir={'column'} alignItem='flex-start'>
                                                        <Flex alignItems={'center'}>
                                                            <Flex alignItems={'center'} mr='5px'>
                                                                {renderDiamondTypeBadge(diamondDetail.typeId)}
                                                                <Text
                                                                    ml='3px'
                                                                    fontSize={'13px'}
                                                                >
                                                                    {diamondDetail.stockNo}
                                                                </Text>
                                                            </Flex>
                                                            <Link
                                                                fontSize={'13px'}
                                                                color='gold.600'
                                                                href={getLabCertificateTrackUrl(diamondDetail.labId, diamondDetail.certificateId)} isExternal>
                                                                {t(_DIAMOND_LAB_OPTIONS.find(i => i.id == diamondDetail.labId)?.label)}-{diamondDetail.certificateId}
                                                            </Link>
                                                        </Flex>
                                                        <Text fontWeight={'bold'} fontSize={'16px'}>{diamondDetail.label}</Text>
                                                        {
                                                            diamondDetail.inWarehouse &&
                                                            <Badge variant='outline' colorScheme={'purple'} maxW={'110px'} borderRadius={0}>{t('IN_WAREHOUSE')}</Badge>
                                                        }
                                                    </Flex>
                                                </Flex>
                                            </Td>
                                            <Td>
                                                <Text fontSize="16px">
                                                    {t(_DIAMOND_LOCATION_OPTIONS.find(l => l.id == diamondDetail.locationId)?.label)}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Flex flexDir="column" alignItems={'flex-end'}>
                                                    <Text fontWeight={700} fontSize={'16px'}>${numberWithCurrencyFormat(order.totalPrice)}</Text>
                                                    <Text color="secondaryGray.500" fontWeight={500} fontSize={'12px'}>$/CT {numberWithCurrencyFormat(parseFloat(order.markupPricePerCarat ?? order.pricePerCarat))}</Text>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </Flex>
            <Modal size={'5xl'} scrollBehavior={'inside'} isOpen={!isObjectEmpty(infoModal)} onClose={() => closeInfoModal()}>
                <ModalOverlay />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalContent
                        containerProps={{
                            zIndex: '9999',
                        }} >
                        <ModalHeader fontSize={'18px'}>{`${t(!infoModal?.paid ? 'UPDATE_BILLING_SHIPPING_INFO' : 'UPDATE_PAYMENT_TRACKING')} (${item?.invoiceNo})`}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {
                                !infoModal?.paid ?
                                    <>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <CustomSwitch
                                                label={t('USE_JEWELLER_PROFILE')}
                                                id='sameAs'
                                                onChange={setSameAsProfile}
                                                value={sameAsProfile}
                                                style={{ direction: 'row' }} />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('PERSON_IN_CHARGE')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="personInCharge"
                                                defaultValue={item?.personInCharge}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_PERSON_IN_CHARGE"}
                                                        errors={errors}
                                                    />
                                                )}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('TELEPHONE')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="phoneNumber"
                                                defaultValue={item?.phoneNumber}
                                                render={({ onChange, value, name }) => (
                                                    <CustomPhoneInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        errors={errors} />
                                                )}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('EMAIL')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="email"
                                                defaultValue={item?.email}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_EMAIL"}
                                                        errors={errors}
                                                        patternErrorTxt={'INVALID_EMAIL'}
                                                    />
                                                )}
                                                rules={{
                                                    pattern: RegexPattern._EMAIL_VALIDATION
                                                }}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('COMPANY_TAX_ID')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="shipToCompanyTaxId"
                                                defaultValue={item?.shipToCompanyTaxId}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_COMPANY_TAX_ID"}
                                                        errors={errors}
                                                    />
                                                )}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('PORT_OF_DISCHARGE')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="shipToPortOfDischarge"
                                                defaultValue={item?.shipToPortOfDischarge}
                                                render={({ onChange, value, name }) => (
                                                    <CustomSelect
                                                        id={name}
                                                        onChange={(value) => {
                                                            onChange(value);
                                                        }}
                                                        value={value}
                                                        errors={errors}
                                                        showEmptyOption
                                                        options={_COUNTRY_LIST.map(c => { return { label: c.name, value: c.name } })}
                                                    />
                                                )}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('SHIPPING_COMPANY_NAME')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="shipToCompanyName"
                                                defaultValue={item?.shipToCompanyName}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_COMPANY_NAME"}
                                                        errors={errors}
                                                    />
                                                )}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('SHIPPING_ADDRESS')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="shipToAddress"
                                                defaultValue={item?.shipToAddress}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_SHIPPING_ADDRESS"}
                                                        errors={errors}
                                                    />
                                                )}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('SHIPPING_ZIPCODE')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="shipToZipCode"
                                                defaultValue={item?.shipToZipCode}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_SHIPPING_ZIPCODE"}
                                                        errors={errors}
                                                        patternErrorTxt={'INVALID_ZIP_CIDE'}
                                                    />
                                                )}
                                                rules={{
                                                    pattern: RegexPattern._ZIP_CODE_VALIDATION
                                                }}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('SHIPPING_CITY')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="shipToCity"
                                                defaultValue={item?.shipToCity}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_SHIPPING_CITY"}
                                                        errors={errors}
                                                    />
                                                )}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('SHIPPING_STATE')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="shipToState"
                                                defaultValue={item?.shipToState}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_SHIPPING_STATE"}
                                                        errors={errors}
                                                    />
                                                )}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('SHIPPING_COUNTRY')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="shipToCountry"
                                                defaultValue={item?.shipToCountry}
                                                render={({ onChange, value, name }) => (
                                                    <CustomSelect
                                                        id={name}
                                                        onChange={(value) => {
                                                            onChange(value);
                                                        }}
                                                        value={value}
                                                        errors={errors}
                                                        showEmptyOption
                                                        options={_COUNTRY_LIST.map(c => { return { label: c.name, value: c.name } })}
                                                    />
                                                )}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('SHIPPING_TELEPHONE')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="shipToPhoneNumber"
                                                defaultValue={item?.shipToPhoneNumber}
                                                render={({ onChange, value, name }) => (
                                                    <CustomPhoneInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        errors={errors} />
                                                )}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('SHIPPING_EMAIL')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="shipToEmail"
                                                defaultValue={item?.shipToEmail}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_SHIPPING_EMAIL"}
                                                        errors={errors}
                                                        patternErrorTxt={'INVALID_EMAIL'}
                                                    />
                                                )}
                                                rules={{
                                                    pattern: RegexPattern._EMAIL_VALIDATION
                                                }}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <CustomSwitch
                                                label={t('USE_SHIPPING_INFO')}
                                                id='sameAsShipping'
                                                onChange={setSameAsShipping}
                                                value={sameAsShipping}
                                                style={{ direction: 'row' }} />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('BILLING_COMPANY_NAME')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="billToCompanyName"
                                                defaultValue={item?.billToCompanyName}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_COMPANY_NAME"}
                                                        errors={errors}
                                                    />
                                                )}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('BILLING_ADDRESS')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="billToAddress"
                                                defaultValue={item?.billToAddress}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_BILLING_ADDRESS"}
                                                        errors={errors}
                                                    />
                                                )}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('BILLING_ZIPCODE')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="billToZipCode"
                                                defaultValue={item?.billToZipCode}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_BILLING_ZIPCODE"}
                                                        errors={errors}
                                                        patternErrorTxt={'INVALID_ZIP_CIDE'}
                                                    />
                                                )}
                                                rules={{
                                                    pattern: RegexPattern._ZIP_CODE_VALIDATION
                                                }}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('BILLING_CITY')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="billToCity"
                                                defaultValue={item?.billToCity}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_BILLING_CITY"}
                                                        errors={errors}
                                                    />
                                                )}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('BILLING_STATE')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="billToState"
                                                defaultValue={item?.billToState}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_BILLING_STATE"}
                                                        errors={errors}
                                                    />
                                                )}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('BILLING_COUNTRY')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="billToCountry"
                                                defaultValue={item?.billToCountry}
                                                render={({ onChange, value, name }) => (
                                                    <CustomSelect
                                                        id={name}
                                                        onChange={(value) => {
                                                            onChange(value);
                                                        }}
                                                        value={value}
                                                        errors={errors}
                                                        showEmptyOption
                                                        options={_COUNTRY_LIST.map(c => { return { label: c.name, value: c.name } })}
                                                    />
                                                )}
                                            />
                                        </SimpleGrid>
                                    </> :
                                    <>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('BANK_NAME')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="bankName"
                                                defaultValue={item?.bankName}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_BANK_NAME"}
                                                        errors={errors}
                                                    />
                                                )}
                                                rules={{
                                                    required: true,
                                                    message: "REQUIRED",
                                                }}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('BANK_ACC_NAME')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="bankAccountName"
                                                defaultValue={item?.bankAccountName}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_BANK_ACC_NAME"}
                                                        errors={errors}
                                                    />
                                                )}
                                                rules={{
                                                    required: true,
                                                    message: "REQUIRED",
                                                }}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('BANK_ACC_NO')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="bankAccountNo"
                                                defaultValue={item?.bankAccountNo}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_BANK_ACC_NO"}
                                                        errors={errors}
                                                    />
                                                )}
                                                rules={{
                                                    required: true,
                                                    message: "REQUIRED"
                                                }}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('BANK_REF_NO')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="bankReferenceNo"
                                                defaultValue={item?.bankReferenceNo}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_BANK_REF_NO"}
                                                        errors={errors}
                                                    />
                                                )}
                                                rules={{
                                                    required: true,
                                                    message: "REQUIRED"
                                                }}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('BANK_RECEIPT')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="bankReceiptAttachment"
                                                defaultValue={item?.bankReceiptAttachment}
                                                render={({ onChange, value, name }) => (
                                                    <CustomMediaUploader
                                                        id={name}
                                                        fileUrls={previewReceipt}
                                                        allowPdf
                                                        onChange={({ fileList: newFileList }) => {
                                                            setReceipt(newFileList);
                                                            onChange(newFileList);
                                                        }}
                                                        onDelete={(id) => { setReceipt([]); }}
                                                        errors={errors} />
                                                )}
                                                rules={{
                                                    required: true,
                                                    message: "REQUIRED"
                                                }}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('LOGISTIC_NAME')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="logisticName"
                                                defaultValue={item?.logisticName}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_LOGISTIC_NAME"}
                                                        errors={errors}
                                                    />
                                                )}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('LOGISTIC_TRACK_NO')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="logisticTrackNo"
                                                defaultValue={item?.logisticTrackNo}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_LOGISTIC_TRACK_NO"}
                                                        errors={errors}
                                                    />
                                                )}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('LOGISTIC_TRACK_URL')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="logisticTrackUrl"
                                                defaultValue={item?.logisticTrackUrl}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_LOGISTIC_TRACK_URL"}
                                                        errors={errors}
                                                    />
                                                )}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('ESTIMATED_SHIPPING_DAY')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="estimatedShippingDay"
                                                defaultValue={item?.estimatedShippingDay}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        inputType='number'
                                                        id={name}
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_ESTIMATED_SHIPPING_DAY"}
                                                        errors={errors}
                                                    />
                                                )}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                            <Text
                                                display={"flex"}
                                                fontSize={"md"}
                                                fontWeight={"500"}
                                                alignItems={'center'}
                                                mb={'20px'}
                                            >
                                                {t('REMARK')}
                                            </Text>
                                            <Controller
                                                control={control}
                                                name="remark"
                                                defaultValue={item?.remark}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        isTextArea
                                                        onChange={(value) => onChange(value)}
                                                        value={value}
                                                        placeHolder={"ENTER_REMARK"}
                                                        errors={errors}
                                                    />
                                                )}
                                            />
                                        </SimpleGrid>
                                    </>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                size={'sm'}
                                minW={'60px'}
                                variant={'link'}
                                color={'red.400'}
                                borderRadius={5}
                                onClick={() => closeInfoModal()}>
                                {t('CANCEL')}
                            </Button>
                            <Button
                                size={'sm'}
                                minW={'60px'}
                                variant="brand"
                                type="submit"
                                borderRadius={5}>
                                {t('SUBMIT')}
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </form>
            </Modal>
        </Box>
    )
});

export default InvoiceDiamondDetail;