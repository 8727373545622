import {
	Avatar,
	Box,
	Flex,
	FormLabel,
	Icon,
	Card,
	SimpleGrid,
	useColorModeValue,
	Text,
	Button,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
	Link,
	Skeleton,
	SkeletonCircle,
	SkeletonText,
	Image,
	Heading
} from "@chakra-ui/react";
import Usa from "assets/img/dashboards/usa.png";
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import {
	MdAddTask,
	MdAttachMoney,
	MdBarChart,
	MdFileCopy,
	MdContactPhone,
	MdOutlinePendingActions,
	MdOutlineEmail
} from "react-icons/md";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import {
	columnsDataCheck,
	columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from 'common/useRouter';
import { WebUrl } from "../../../routes";
import {
	Role,
	ApiKey,
	CompanyProfileStatus
} from "../../../common/constant";
import Routes from "../../../common/api_routes";
import ApiEngine from "../../../common/api-engine";
import { showCustomDialog, showCustomInputDialog, disposeCustomInputDialog } from "../../../application/action/app_action";
import { FaUserGroup, FaUser, FaWhatsapp, FaUserClock, FaHandsHoldingCircle, FaRegFile, FaFileInvoiceDollar, FaBoxesPacking, FaMoneyBillTrendUp } from "react-icons/fa6";
import { IoBagCheckOutline, IoDiamond } from "react-icons/io5";
import { RiDraftFill, RiFileUserFill, RiUserFollowFill, RiFileShieldFill, RiShieldUserLine } from "react-icons/ri";
import { GiRingBox } from "react-icons/gi";
import { FaShippingFast } from "react-icons/fa";
import { BsBagCheck } from "react-icons/bs";
import { stringIsNullOrEmpty } from "../../../common/util";

/// <summary>
/// Author: Saitama
/// </summary>
export default function UserReports() {
	var _dispatch = useDispatch();
	var _router = useRouter();
	const { t } = useTranslation();
	const _USER_DATA = useSelector((state) => state.authState.userData);
	const primaryColor = useColorModeValue("brand.950", "white");
	const brandColor = useColorModeValue("brand.800", "white");
	const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

	// Chakra Color Mode
	const textColor = useColorModeValue("black", "white");
	const iconColor = useColorModeValue("brand.500", "white");
	const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
	const bgHover = useColorModeValue(
		{ bg: "secondaryGray.400" },
		{ bg: "whiteAlpha.50" }
	);
	const bgFocus = useColorModeValue(
		{ bg: "secondaryGray.300" },
		{ bg: "whiteAlpha.100" }
	);
	const [dashboardData, setDashboardData] = useState();

	/// <summary>
	/// Author: CK
	/// </summary> 
	useEffect(() => {
		getDashboardData();
	}, []);

	/// <summary>
	/// Author: CK
	/// </summary>
	const getDashboardData = async () => {
		try {
			var responseJson = await ApiEngine.get(Routes._DASHBOARD_DATA);
	
			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				setDashboardData(responseJson[ApiKey._API_DATA_KEY]);
			}
			else {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}
		}
		catch (err) {
			_dispatch(
				showCustomDialog({
					success: false,
					content: err,
				})
			);
		}
	};

	return (
		<Box pt={{ base: "80px", md: "65px", xl: "65px" }}>
			<Heading style={{ fontStyle: 'italic' }} fontSize={'25px'} mb={'25px'} color={primaryColor}>👋&nbsp; {t('WELCOME')} {_USER_DATA?.user?.firstName}</Heading>
			{
				(_USER_DATA?.user?.roleId == Role._SUPPLIER && _USER_DATA?.user?.companyProfile == undefined) ?
					<Alert status='warning' mb={'15px'}>
						<AlertIcon />
						{t("PLEASE_COMPLETE_COMPANY_PROFILE")} <Link onClick={() => _router.navigate(WebUrl._SUPPLIER_PROFILE_SETTINGS)} ml="5px" color="teal">{t("CLICK_HERE_TO_SUBMIT")}</Link>
					</Alert>
					:
					(_USER_DATA?.user?.roleId == Role._SUPPLIER && _USER_DATA?.user?.companyProfile?.isApproved == CompanyProfileStatus._PENDING_APPROVAL) ?
						<Alert status='warning' mb={'15px'}>
							<AlertIcon />
							{t("COMPANY_PROFILE_PENDING_APPROVAL")}
						</Alert> :
						(_USER_DATA?.user?.roleId == Role._SUPPLIER && _USER_DATA?.user?.companyProfile?.isApproved == CompanyProfileStatus._PENDING_APPROVAL) ?
							<Alert status='error' mb={'15px'}>
								<AlertIcon />
								{t("COMPANY_PROFILE_REJECTED")}
							</Alert> : <></>
			}
			{
				dashboardData != undefined ?
					<>
						{
							(
								dashboardData?.pendingApprovalSupplierCount != undefined ||
								dashboardData?.pendingApprovalJewellerCount != undefined ||
								dashboardData?.totalSupplierCount != undefined ||
								dashboardData?.totalJewellerCount != undefined
							)
							&&
							<>
								<Text
									me='auto'
									color={textColor}
									fontSize='xl'
									fontWeight='700'
									lineHeight='100%'
									marginBottom={'15px'}>
									{t('USERS')}
								</Text>
								<SimpleGrid
									columns={{ base: 2, md: 2, lg: 4, "2xl": 4 }}
									gap='15px'
									mb='15px'>
									{
										dashboardData?.totalSupplierCount != undefined &&
										<MiniStatistics
											startContent={
												<IconBox
													w='56px'
													h='56px'
													bg={boxBg}
													icon={
														<Icon w='32px' h='32px' as={FaUserGroup} color={brandColor} />
													}
												/>
											}
											name={t('TOTAL_SUPPLIERS')}
											value={dashboardData?.totalSupplierCount}
										/>
									}
									{
										dashboardData?.totalJewellerCount != undefined &&
										<MiniStatistics
											startContent={
												<IconBox
													w='56px'
													h='56px'
													bg={boxBg}
													icon={
														<Icon w='32px' h='32px' as={FaUserGroup} color={brandColor} />
													}
												/>
											}
											name={t('TOTAL_JEWELLERS')}
											value={dashboardData?.totalJewellerCount}
										/>
									}
									{
										dashboardData?.pendingApprovalSupplierCount != undefined &&
										<MiniStatistics
											startContent={
												<IconBox
													w='56px'
													h='56px'
													bg='linear-gradient(90deg, #ff9f1c 0%, #ffbf69 100%)'
													icon={
														<Icon w='32px' h='32px' as={FaUserClock} color='white' />
													}
												/>
											}
											name={t('PENDING_APPROVAL_SUPPLIERS')}
											value={dashboardData?.pendingApprovalSupplierCount}
										/>
									}
									{
										dashboardData?.pendingApprovalJewellerCount != undefined &&
										<MiniStatistics
											startContent={
												<IconBox
													w='56px'
													h='56px'
													bg='linear-gradient(90deg, #ff9f1c 0%, #ffbf69 100%)'
													icon={
														<Icon w='32px' h='32px' as={FaUserClock} color='white' />
													}
												/>
											}
											name={t('PENDING_APPROVAL_JEWELLERS')}
											value={dashboardData?.pendingApprovalJewellerCount}
										/>
									}
								</SimpleGrid>
							</>
						}
						{
							(
								dashboardData?.diamondDraftCount != undefined ||
								dashboardData?.pendingApprovalDiamondCount != undefined ||
								dashboardData?.publishedDiamondCount != undefined ||
								dashboardData?.soldDiamondCount != undefined
							)
							&&
							<>
								<Text
									me='auto'
									color={textColor}
									fontSize='xl'
									fontWeight='700'
									lineHeight='100%'
									marginBottom={'15px'}>
									{t('INVENTORY')}
								</Text>
								<SimpleGrid
									columns={{ base: 2, md: 2, lg: 4, "2xl": 4 }}
									gap='15px'
									mb='15px'>
									{
										dashboardData?.diamondDraftCount != undefined &&
										<MiniStatistics
											startContent={
												<IconBox
													w='56px'
													h='56px'
													bg={boxBg}
													icon={
														<Icon w='32px' h='32px' as={RiDraftFill} color={brandColor} />
													}
												/>
											}
											name={t('DIAMONDS_DRAFT')}
											value={dashboardData?.diamondDraftCount}
										/>
									}
									{
										dashboardData?.pendingApprovalDiamondCount != undefined &&
										<MiniStatistics
											startContent={
												<IconBox
													w='56px'
													h='56px'
													bg='linear-gradient(90deg, #ff9f1c 0%, #ffbf69 100%)'
													icon={
														<Icon w='32px' h='32px' as={MdOutlinePendingActions} color='white' />
													}
												/>
											}
											name={t('PENDING_APPROVAL_DIAMONDS')}
											value={dashboardData?.pendingApprovalDiamondCount}
										/>
									}
									{
										dashboardData?.publishedDiamondCount != undefined &&
										<MiniStatistics
											startContent={
												<IconBox
													w='56px'
													h='56px'
													bg='linear-gradient(90deg, #cae9ff 0%, #1b4965 100%)'
													icon={
														<Icon w='32px' h='32px' as={IoDiamond} color='white' />
													}
												/>
											}
											name={t('DASHBOARD_TOTAL_DIAMONDS')}
											value={dashboardData?.publishedDiamondCount}
										/>
									}
									{
										dashboardData?.soldDiamondCount != undefined &&
										<MiniStatistics
											startContent={
												<IconBox
													w='56px'
													h='56px'
													bg='linear-gradient(90deg, #57cc99 0%, #80ed99 100%)'
													icon={
														<Icon w='32px' h='32px' as={IoBagCheckOutline} color='white' />
													}
												/>
											}
											name={t('SOLD_DIAMONDS')}
											value={dashboardData?.soldDiamondCount}
										/>
									}
								</SimpleGrid>
							</>
						}
						{
							(
								dashboardData?.holdOrderCount != undefined ||
								dashboardData?.pendingApproveOrderCount != undefined ||
								dashboardData?.pendingRequestForInvoiceCount != undefined ||
								dashboardData?.pendingUpdateInfoInvoiceCount != undefined ||
								dashboardData?.pendingShippingInvoiceCount != undefined ||
								dashboardData?.shippingInvoiceCount != undefined ||
								dashboardData?.completeShippedInvoiceCount != undefined ||
								dashboardData?.settlementCount != undefined
							)
							&&
							<>
								<Text
									me='auto'
									color={textColor}
									fontSize='xl'
									fontWeight='700'
									lineHeight='100%'
									marginBottom={'15px'}>
									{t('PURCHASEMENT')}
								</Text>
								<SimpleGrid
									columns={{ base: 2, md: 2, lg: 4, "2xl": 4 }}
									gap='15px'
									mb='15px'>
									{
										dashboardData?.holdOrderCount != undefined &&
										<MiniStatistics
											startContent={
												<IconBox
													w='56px'
													h='56px'
													bg='linear-gradient(90deg, #bcb8b1 0%, #8a817c 100%)'
													icon={
														<Icon w='32px' h='32px' as={FaHandsHoldingCircle} color={'white'} />
													}
												/>
											}
											name={t('HOLD_DIAMONDS')}
											value={dashboardData?.holdOrderCount}
										/>
									}
									{
										dashboardData?.pendingApproveOrderCount != undefined &&
										<MiniStatistics
											startContent={
												<IconBox
													w='56px'
													h='56px'
													bg='linear-gradient(90deg, #88d498 0%, #1a936f 100%)'
													icon={
														<Icon w='32px' h='32px' as={GiRingBox} color='white' />
													}
												/>
											}
											name={t('CONFIRM_GOODS')}
											value={dashboardData?.pendingApproveOrderCount}
										/>
									}
									{
										dashboardData?.pendingRequestForInvoiceCount != undefined &&
										<MiniStatistics
											startContent={
												<IconBox
													w='56px'
													h='56px'
													bg='linear-gradient(90deg, #90caf9 0%, #1976d2 100%)'
													icon={
														<Icon w='32px' h='32px' as={FaRegFile} color='white' />
													}
												/>
											}
											name={t('READY_FOR_INVOICE')}
											value={dashboardData?.pendingRequestForInvoiceCount}
										/>
									}
									{
										dashboardData?.pendingUpdateInfoInvoiceCount != undefined &&
										<MiniStatistics
											startContent={
												<IconBox
													w='56px'
													h='56px'
													bg='linear-gradient(90deg, #e0aaff 0%, #7b2cbf 100%)'
													icon={
														<Icon w='32px' h='32px' as={FaFileInvoiceDollar} color='white' />
													}
												/>
											}
											name={t('PROCESSING_INVOICES')}
											value={dashboardData?.pendingUpdateInfoInvoiceCount}
										/>
									}
									{
										dashboardData?.pendingShippingInvoiceCount != undefined &&
										<MiniStatistics
											startContent={
												<IconBox
													w='56px'
													h='56px'
													bg='linear-gradient(90deg, #ffb627 0%, #e2711d 100%)'
													icon={
														<Icon w='32px' h='32px' as={FaBoxesPacking} color={'white'} />
													}
												/>
											}
											name={t('PENDING_SHIPPING')}
											value={dashboardData?.pendingShippingInvoiceCount}
										/>
									}
									{
										dashboardData?.shippingInvoiceCount != undefined &&
										<MiniStatistics
											startContent={
												<IconBox
													w='56px'
													h='56px'
													bg='linear-gradient(90deg, #00b4d8 0%, #0077b6 100%)'
													icon={
														<Icon w='32px' h='32px' as={FaShippingFast} color={'white'} />
													}
												/>
											}
											name={t('SHIPPING')}
											value={dashboardData?.shippingInvoiceCount}
										/>
									}
									{
										dashboardData?.completeShippedInvoiceCount != undefined &&
										<MiniStatistics
											startContent={
												<IconBox
													w='56px'
													h='56px'
													bg='linear-gradient(90deg, #57cc99 0%, #80ed99 100%)'
													icon={
														<Icon w='32px' h='32px' as={BsBagCheck} color={'white'} />
													}
												/>
											}
											name={t('COMPLETED')}
											value={dashboardData?.completeShippedInvoiceCount}
										/>
									}
									{
										dashboardData?.settlementCount != undefined &&
										<MiniStatistics
											startContent={
												<IconBox
													w='56px'
													h='56px'
													bg='linear-gradient(90deg, #57cc99 0%, #38a3a5 100%)'
													icon={
														<Icon w='32px' h='32px' as={FaMoneyBillTrendUp} color={'white'} />
													}
												/>
											}
											name={t('SETTLEMENT')}
											value={dashboardData?.settlementCount}
										/>
									}
								</SimpleGrid>
							</>
						}
					</>
					:
					<>
						<SimpleGrid
							columns={{ base: 2, md: 2, lg: 4, "2xl": 4 }}
							gap='15px'
							mb='15px'>
							<Box padding='15px' borderRadius={'20px'} bg='white'>
								<Flex flexDir={'row'} alignItems={'center'}>
									<Flex>
										<SkeletonCircle size='56px' />
									</Flex>
									<Flex ml="18px" flex={1} flexDir={'column'}>
										<SkeletonText width={'100%'} noOfLines={1} skeletonHeight='3' />
										<SkeletonText width={'50%'} mt="5px" noOfLines={1} skeletonHeight='5' />
									</Flex>
								</Flex>
							</Box>
							<Box padding='15px' borderRadius={'20px'} bg='white'>
								<Flex flexDir={'row'} alignItems={'center'}>
									<Flex>
										<SkeletonCircle size='56px' />
									</Flex>
									<Flex ml="18px" flex={1} flexDir={'column'}>
										<SkeletonText width={'100%'} noOfLines={1} skeletonHeight='3' />
										<SkeletonText width={'50%'} mt="5px" noOfLines={1} skeletonHeight='5' />
									</Flex>
								</Flex>
							</Box>
							<Box padding='15px' borderRadius={'20px'} bg='white'>
								<Flex flexDir={'row'} alignItems={'center'}>
									<Flex>
										<SkeletonCircle size='56px' />
									</Flex>
									<Flex ml="18px" flex={1} flexDir={'column'}>
										<SkeletonText width={'100%'} noOfLines={1} skeletonHeight='3' />
										<SkeletonText width={'50%'} mt="5px" noOfLines={1} skeletonHeight='5' />
									</Flex>
								</Flex>
							</Box>
							<Box padding='15px' borderRadius={'20px'} bg='white'>
								<Flex flexDir={'row'} alignItems={'center'}>
									<Flex>
										<SkeletonCircle size='56px' />
									</Flex>
									<Flex ml="18px" flex={1} flexDir={'column'}>
										<SkeletonText width={'100%'} noOfLines={1} skeletonHeight='3' />
										<SkeletonText width={'50%'} mt="5px" noOfLines={1} skeletonHeight='5' />
									</Flex>
								</Flex>
							</Box>
						</SimpleGrid>
						<SimpleGrid
							columns={{ base: 2, md: 2, lg: 4, "2xl": 4 }}
							gap='15px'
							mb='15px'>
							<Box padding='15px' borderRadius={'20px'} bg='white'>
								<Flex flexDir={'row'} alignItems={'center'}>
									<Flex>
										<SkeletonCircle size='56px' />
									</Flex>
									<Flex ml="18px" flex={1} flexDir={'column'}>
										<SkeletonText width={'100%'} noOfLines={1} skeletonHeight='3' />
										<SkeletonText width={'50%'} mt="5px" noOfLines={1} skeletonHeight='5' />
									</Flex>
								</Flex>
							</Box>
							<Box padding='15px' borderRadius={'20px'} bg='white'>
								<Flex flexDir={'row'} alignItems={'center'}>
									<Flex>
										<SkeletonCircle size='56px' />
									</Flex>
									<Flex ml="18px" flex={1} flexDir={'column'}>
										<SkeletonText width={'100%'} noOfLines={1} skeletonHeight='3' />
										<SkeletonText width={'50%'} mt="5px" noOfLines={1} skeletonHeight='5' />
									</Flex>
								</Flex>
							</Box>
							<Box padding='15px' borderRadius={'20px'} bg='white'>
								<Flex flexDir={'row'} alignItems={'center'}>
									<Flex>
										<SkeletonCircle size='56px' />
									</Flex>
									<Flex ml="18px" flex={1} flexDir={'column'}>
										<SkeletonText width={'100%'} noOfLines={1} skeletonHeight='3' />
										<SkeletonText width={'50%'} mt="5px" noOfLines={1} skeletonHeight='5' />
									</Flex>
								</Flex>
							</Box>
							<Box padding='15px' borderRadius={'20px'} bg='white'>
								<Flex flexDir={'row'} alignItems={'center'}>
									<Flex>
										<SkeletonCircle size='56px' />
									</Flex>
									<Flex ml="18px" flex={1} flexDir={'column'}>
										<SkeletonText width={'100%'} noOfLines={1} skeletonHeight='3' />
										<SkeletonText width={'50%'} mt="5px" noOfLines={1} skeletonHeight='5' />
									</Flex>
								</Flex>
							</Box>
						</SimpleGrid>
					</>
			}
			{
				(_USER_DATA?.user?.roleId == Role._SUPPLIER || _USER_DATA?.user?.roleId == Role._JEWELLER)
				&&
				<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
					<Card align='center' direction='column' w='100%'>
						<Flex align='center' w='100%' px='15px' py='10px'>
							<Text
								me='auto'
								color={textColor}
								fontSize='xl'
								fontWeight='700'
								lineHeight='100%'>
								{t('SALES_PERSON')}
							</Text>
							<Icon as={MdContactPhone} color={brandColor} w='24px' h='24px' />
						</Flex>
						{
							dashboardData?.inChargeBy &&
							<Flex className="sa-detail-container" flexDir={'row'} alignItems={'center'}>
								<Flex>
									{
										stringIsNullOrEmpty(dashboardData?.inChargeBy.profilePicture) ?
											<Flex alignItems={'center'} justifyContent={'center'} className="sa-profile-img">
												<Icon as={FaUser} color={'white'} width={'40px'} height={"40px"} />
											</Flex>
											: <Image className="sa-profile-img" src={dashboardData?.inChargeBy.profilePicture} />
									}
								</Flex>
								<Flex flex={1} flexDir="column">
									<Heading size="md" mb={'10px'} style={{ fontStyle: 'italic' }}>{dashboardData?.inChargeBy.firstName} {dashboardData?.inChargeBy.lastName}</Heading>
									<Link
										mb="5px"
										fontSize={'16px'}
										color='#128c7e'
										href={`https://wa.me/${dashboardData?.inChargeBy.phoneNumber}`} isExternal>
										<Flex flexDir="row" alignItems={'center'}>
											<Icon as={FaWhatsapp} color={'#128c7e'} mr="2px" width={'20px'} height={"20px"} />
											+{dashboardData?.inChargeBy.phoneNumber}
										</Flex>
									</Link>
									<Link
										fontSize={'16px'}
										color='#0078d4'
										href={`mailto:${dashboardData?.inChargeBy.email}`} isExternal>
										<Flex flexDir="row" alignItems={'center'}>
											<Icon as={MdOutlineEmail} color={'#0078d4'} mr="2px" width={'20px'} height={"20px"} />
											{dashboardData?.inChargeBy.email}
										</Flex>
									</Link>
								</Flex>
							</Flex>
						}
					</Card>
				</SimpleGrid>
			}
		</Box>
	);
}
