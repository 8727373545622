import React, { useEffect, useState } from "react";
import { useRouter } from "../../../common/useRouter";
import { useTranslation } from "react-i18next";
import {
    Box,
    Flex,
    Button,
    SimpleGrid,
    Text
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../../common/api_routes";
import CustomTable from "../../../components/custom-table";
import CustomInput from "../../../components/custom-input";
import { WebUrl } from "../../../routes";
import { Role } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";
import { numberWithCurrencyFormat } from "common/util";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    const { t } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const [apiUrl, setApiUrl] = useState();
    const { control, handleSubmit, errors, reset, watch, setValue } = useForm();
    const _COLUMNS = [
        {
            Header: "SUPPLIER",
            Cell: ({ row }) => {
                return <Text fontWeight={700}>{row.original.supplier.supplierProfile.companyName}</Text>
            }
        },
        {
            Header: "ORIGINAL_PRICE",
            accessor: "originalPrice",
            Cell: ({ value }) => {
                return <Text fontWeight={700}>${numberWithCurrencyFormat(value)}</Text>
            }
        },
        {
            Header: "SELLING_PRICE",
            accessor: "totalPrice",
            Cell: ({ value }) => {
                return <Text fontWeight={700}>${numberWithCurrencyFormat(value)}</Text>
            },
            Footer: () => {
                return t('TOTAL_PROFIT')
            }
        },
        {
            Header: "PROFIT",
            accessor: "profit",
            Cell: ({ value }) => {
                return <Text fontWeight={700}>${numberWithCurrencyFormat(value)}</Text>
            },
            Footer: ({ page }) => {
                const pageTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.profit);
                }, 0);

                return `$${numberWithCurrencyFormat(pageTotal)}`
            }
        },
    ];

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        setValue('startDate', moment().format('YYYY-MM-DD 00:00:00'));
        setValue('endDate', moment().format('YYYY-MM-DD 23:59:59'));
        setApiUrl(`${Routes._SALES_REPORT}?startDate=${moment().format('YYYY-MM-DD 00:00:00')}&endDate=${moment().format('YYYY-MM-DD 23:59:59')}`);
    }, [])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const submitFilter = async (data) => {
        try {
            setApiUrl(`${Routes._SALES_REPORT}?startDate=${moment(data.startDate).format('YYYY-MM-DD 00:00:00')}&endDate=${moment(data.endDate).format('YYYY-MM-DD 23:59:59')}`);
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    return (
        <Box pt={{ base: "80px", md: "60px", xl: "60px" }}>
            <Card
                justifyContent='center'
                align='center'
                direction='column'
                w='100%'
                mb='10px'>
                <form onSubmit={handleSubmit(submitFilter)}>
                    <SimpleGrid columns={{ base: 1, md: 4, xl: 4 }} gap={'20px'}>
                        <Controller
                            control={control}
                            name="startDate"
                            defaultValue={''}
                            render={({ onChange, value, name }) => (
                                <CustomInput
                                    id={name}
                                    onChange={(value) => onChange(value)}
                                    value={value}
                                    errors={errors}
                                    label={'START_DATE'}
                                    inputType={'datetime-local'}
                                />
                            )}
                            rules={{
                                required: true,
                                message: "REQUIRED",
                            }}
                        />
                        <Controller
                            control={control}
                            name="endDate"
                            defaultValue={''}
                            render={({ onChange, value, name }) => (
                                <CustomInput
                                    id={name}
                                    onChange={(value) => onChange(value)}
                                    value={value}
                                    errors={errors}
                                    label={'END_DATE'}
                                    inputType={'datetime-local'}
                                />
                            )}
                            rules={{
                                required: true,
                                message: "REQUIRED",
                            }}
                        />
                    </SimpleGrid>
                    <Flex gap='10px' mt='10px'>
                        <Button
                            fontSize="sm"
                            variant="brand"
                            fontWeight="500"
                            h="35"
                            type="submit">
                            {t('FILTER')}
                        </Button>
                    </Flex>
                </form>
            </Card>
            <Card
                justifyContent='center'
                align='center'
                direction='column'
                w='100%'
                mb='0px'>
                <CustomTable
                    columnsData={_COLUMNS}
                    apiUrl={apiUrl}
                    renderFooter />
            </Card>
        </Box>
    )
}