import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Text,
    SimpleGrid,
} from "@chakra-ui/react";
import { showCustomDialog } from "../../../../application/action/app_action";
import { ApiKey, RegexPattern } from "../../../../common/constant";
import ApiEngine from "../../../../common/api-engine";
import Routes from "../../../../common/api_routes";
import CustomInput from "../../../../components/custom-input";
import CustomSwitch from "../../../../components/custom-switch";
import CustomSelect from "../../../../components/custom-select";
import CustomPhoneInput from "../../../../components/custom-phone-input";
import { checkIsLoggedIn } from "../../../../application/action/auth_action";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ onSave, onCancel, jewellerDetail }) => {
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch, reset } = useForm();
    var _dispatch = useDispatch();
    const _COUNTRY_LIST = require('../../../../common/countries.json');
    const [sameAsComp, setSameAsComp] = useState(false);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (sameAsComp) {
            setValue('address', jewellerDetail?.companyProfile?.address);
            setValue('zipCode', jewellerDetail?.companyProfile?.zipCode);
            setValue('city', jewellerDetail?.companyProfile?.city);
            setValue('state', jewellerDetail?.companyProfile?.state);
            setValue('country', jewellerDetail?.companyProfile?.country);
            setValue('email', jewellerDetail?.companyProfile?.companyEmail);
            setValue('phoneNumber', jewellerDetail?.companyProfile?.companyTelephone);
        }
    }, [sameAsComp]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const onSubmit = async (data, e) => {
        try {
            let userId = jewellerDetail?.id;

            if (jewellerDetail) {
                userId = jewellerDetail.id;
            }

            var responseJson = await ApiEngine.put(`${Routes._USER}/${userId}/shipping-info`, data);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            _dispatch(
                showCustomDialog({
                    success: true,
                    content: "OPERATION_SUCCESS",
                    onConfirm: () => {
                        _dispatch(checkIsLoggedIn());
                        onCancel();
                    } 
                })
            );
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                    wordBreak={'break-all'}
                >
                    {t('SHIPPING_ADDRESS_SAME_AS_COMPANY_INFO')}
                </Text>
                <CustomSwitch
                    id='sameAs'
                    onChange={setSameAsComp}
                    value={sameAsComp}
                    style={{ direction: 'row' }} />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('SHIPPING_ADDRESS')}
                </Text>
                <Controller
                    control={control}
                    name="address"
                    defaultValue={jewellerDetail?.shippingInfo?.address}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"ENTER_SHIPPING_ADDRESS"}
                            errors={errors}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('SHIPPING_ZIPCODE')}
                </Text>
                <Controller
                    control={control}
                    name="zipCode"
                    defaultValue={jewellerDetail?.shippingInfo?.zipCode}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"ENTER_SHIPPING_ZIPCODE"}
                            errors={errors}
                            patternErrorTxt={'INVALID_ZIP_CIDE'}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                        pattern: RegexPattern._ZIP_CODE_VALIDATION
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('SHIPPING_CITY')}
                </Text>
                <Controller
                    control={control}
                    name="city"
                    defaultValue={jewellerDetail?.shippingInfo?.city}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"ENTER_SHIPPING_CITY"}
                            errors={errors}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('SHIPPING_STATE')}
                </Text>
                <Controller
                    control={control}
                    name="state"
                    defaultValue={jewellerDetail?.shippingInfo?.state}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"ENTER_SHIPPING_STATE"}
                            errors={errors}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('SHIPPING_COUNTRY')}
                </Text>
                <Controller
                    control={control}
                    name="country"
                    defaultValue={jewellerDetail?.shippingInfo?.country}
                    render={({ onChange, value, name }) => (
                        <CustomSelect
                            id={name}
                            onChange={(value) => {
                                onChange(value);
                            }}
                            value={value}
                            errors={errors}
                            showEmptyOption
                            options={_COUNTRY_LIST.map(c => { return { label: c.name, value: c.name } })}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('SHIPPING_EMAIL')}
                </Text>
                <Controller
                    control={control}
                    name="email"
                    defaultValue={jewellerDetail?.shippingInfo?.email}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"ENTER_SHIPPING_EMAIL"}
                            errors={errors}
                            patternErrorTxt={'INVALID_EMAIL'}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                        pattern: RegexPattern._EMAIL_VALIDATION
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('SHIPPING_TELEPHONE')}
                </Text>
                <Controller
                    control={control}
                    name="phoneNumber"
                    defaultValue={jewellerDetail?.shippingInfo?.phoneNumber}
                    render={({ onChange, value, name }) => (
                        <CustomPhoneInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            errors={errors} />
                    )}
                    rules={{
                        required: "REQUIRED"
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('COMPANY_TAX_ID')}
                </Text>
                <Controller
                    control={control}
                    name="companyTaxId"
                    defaultValue={jewellerDetail?.shippingInfo?.companyTaxId}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"ENTER_COMPANY_TAX_ID"}
                            errors={errors}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('PORT_OF_DISCHARGE')}
                </Text>
                <Controller
                    control={control}
                    name="portOfDischarge"
                    defaultValue={jewellerDetail?.shippingInfo?.portOfDischarge}
                    render={({ onChange, value, name }) => (
                        <CustomSelect
                            id={name}
                            onChange={(value) => {
                                onChange(value);
                            }}
                            value={value}
                            errors={errors}
                            showEmptyOption
                            options={_COUNTRY_LIST.map(c => { return { label: c.name, value: c.name } })}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="50%"
                h="50"
                type="submit">
                {t('COMPLETE')}
            </Button>
        </form>
    );
}
