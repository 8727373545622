import React, { useEffect, useState } from "react";
import { useRouter } from "../../../common/useRouter";
import { useTranslation } from "react-i18next";
import {
    Box,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    Image,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Link,
    Button,
    HStack,
    Badge
} from "@chakra-ui/react";
import { FaGear, FaMagnifyingGlass, FaCheckDouble, FaEye, FaXmark } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../../common/api_routes";
import CustomTable from "../../../components/custom-table";
import { WebUrl } from "../../../routes";
import { Role, ApiKey } from "../../../common/constant";
import { showCustomDialog, showCustomInputDialog } from "../../../application/action/app_action";
import { isObjectEmpty, stringIsNullOrEmpty } from "../../../common/util";
import ApiEngine from "../../../common/api-engine";
import {
    CompanyProfileStatus
} from "../../../common/constant";
import moment from "moment";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    const { t } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const [apiUrl, setApiUrl] = useState(`${Routes._USER}?roleId=${Role._SUPPLIER}&companyProfileApproved=false`);
    const [detailsModal, setDetailsModal] = useState({});
    const _COLUMNS = [
        {
            Header: "CREATED_DATE_AND_TIME",
            accessor: "createdAt",
            Cell: ({ row }) => {
                return <Text fontWeight={700}>{moment(row.original.createdAt).format('YYYY-MM-DD h:mm:ss A')}</Text>
            }
        },
        {
            Header: "COMPANY_NAME",
            Cell: ({ row }) => {
                return <Text fontWeight={700}>{row.original.supplierProfile.companyName}</Text>
            }
        },
        {
            Header: "EMAIL",
            accessor: "email"
        },
        {
            Header: "NAME",
            Cell: ({ row }) => {
                return <Text fontWeight={700}>{`${row.original.firstName} ${row.original.lastName}`}</Text>
            }
        },
        {
            Header: "TELEPHONE",
            accessor: "phoneNumber"
        },
        {
            Header: "MIN_MAX_DIAMONDS",
            Cell: ({ row }) => {
                return <Text fontWeight={700}>{`${row.original.supplierProfile?.minDiamondRange ?? ""}-${row.original.supplierProfile?.maxDiamondRange ?? ""}`}</Text>
            }
        },
        {
            Header: "REMARK",
            Cell: ({ row }) => {
                return <Text fontWeight={700}>{`${row.original.companyProfile?.remark ? row.original.companyProfile.remark : "-"}`}</Text>
            }
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
                let status = row.original.companyProfile?.isApproved;
                let statusLabel = t("PENDING_APPROVAL");
                let badgeColorScheme = 'yellow';

                if (row.original.companyProfile == undefined) {
                    statusLabel = t("PENDING_COMPANY_PROFILE");
                    badgeColorScheme = 'orange';
                }
                else if (status == CompanyProfileStatus._REJECTED) {
                    statusLabel = t("REJECTED");
                    badgeColorScheme = 'red';
                }
                else if (status == CompanyProfileStatus._APPROVED) {
                    statusLabel = t("APPROVED");
                    badgeColorScheme = 'green';
                }

                return <Badge variant='subtle' colorScheme={badgeColorScheme}>{statusLabel}</Badge>
            }
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => {
                return <Menu>
                    <MenuButton as={IconButton} icon={<FaGear />} variant={'brand'} />
                    <MenuList>
                        <MenuItem icon={<FaMagnifyingGlass />} onClick={() => { setDetailsModal(row.original) }}>{t('VIEW')}</MenuItem>
                        <MenuItem icon={<FaEdit />} onClick={() => { _router.navigate(WebUrl._SUPPLIER_PROFILE_SETTINGS, { supplier: row.original }) }}>{t('EDIT')}</MenuItem>
                        <MenuItem icon={<FaCheckDouble color='green' />} onClick={() => {
                            _dispatch(showCustomDialog({
                                title: t('ARE_YOU_SURE_YOU'),
                                content: t('ARE_YOU_SURE_YOU_WANT_TO', { action: t('APPROVE'), user: row.original.supplierProfile.companyName }),
                                onCancel: () => { },
                                onConfirm: () => {
                                    approveCompanyProfile(row.original.id);
                                }
                            }));
                        }}>{t('APPROVE')}</MenuItem>
                        <MenuItem icon={<FaXmark color='red' />} onClick={() => {
                            _dispatch(showCustomInputDialog({
                                rowId: row.original.id,
                                success: true,
                                title: t("ARE_YOU_SURE_YOU"),
                                content:t('ARE_YOU_SURE_YOU_WANT_TO', { action: t('REJECT'), user: row.original.supplierProfile.companyName }),
                                onCancel: () => { },
                                onConfirm: (inputValue) => rejectCompanyProfile(inputValue),
                                inputType: "text",
                                defaultValue: ""
                            }));
                        }}>{t('REJECT')}</MenuItem>
                    </MenuList>
                </Menu>
            }
        }
    ];

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function approveCompanyProfile(id) {
        try {
            var responseJson = await ApiEngine.put(`${Routes._COMPANY_PROFILE}/${id}/approve`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            _dispatch(
                showCustomDialog({
                    success: true,
                    content: responseJson[ApiKey._API_MESSAGE_KEY],
                    onConfirm: () => {
                        _router.navigate(WebUrl._SUPPLIER_LIST);
                    }
                })
            );
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function rejectCompanyProfile(inputValue) {
        try {
            console.log("INPUT VALUE", inputValue);
            var responseJson = await ApiEngine.put(`${Routes._COMPANY_PROFILE}/${inputValue.id}/reject`, inputValue.value);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            _dispatch(
                showCustomDialog({
                    success: true,
                    content: responseJson[ApiKey._API_MESSAGE_KEY],
                    onConfirm: () => {
                        setApiUrl(apiUrl + "&v=" + moment())
                    }
                })
            );
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
    }

    return (
        <Box pt={{ base: "80px", md: "60px", xl: "60px" }}>
            <Card
                justifyContent='center'
                align='center'
                direction='column'
                w='100%'
                mb='0px'>
                <CustomTable
                    columnsData={_COLUMNS}
                    apiUrl={apiUrl} />
            </Card>
            <Modal size={'5xl'} isOpen={!isObjectEmpty(detailsModal)} onClose={() => setDetailsModal({})}>
                <ModalOverlay />
                <ModalContent
                    containerProps={{
                        zIndex: '99999',
                    }} >
                    <ModalHeader>{detailsModal?.supplierProfile?.companyName}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <TableContainer className="supplier-detail-table">
                            <Table variant='simple' textAlign={'center'}>
                                <Thead>
                                    <Tr>
                                        <Th colSpan={2} fontWeight={'900'} fontSize={'larger'}>{t('ACCOUNT_INFO')}</Th>
                                        <Th colSpan={2} />
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('USERNAME')}</Td>
                                        <Td>{detailsModal?.username}</Td>
                                        <Td />
                                        <Td />
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('NAME')}</Td>
                                        <Td>{`${detailsModal?.firstName} ${detailsModal?.lastName}`}</Td>
                                        <Td />
                                        <Td />
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('TELEPHONE')}</Td>
                                        <Td>{detailsModal?.phoneNumber}</Td>
                                        <Td />
                                        <Td />
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('MIN_MAX_DIAMONDS')}</Td>
                                        <Td>{`${detailsModal?.supplierProfile?.minDiamondRange} - ${detailsModal?.supplierProfile?.maxDiamondRange}`}</Td>
                                        <Td />
                                        <Td />
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <TableContainer className="supplier-detail-table">
                            <Table variant='simple' textAlign={'center'}>
                                <Thead>
                                    <Tr>
                                        <Th colSpan={2} fontWeight={'900'} fontSize={'larger'}>{t('COMPANY_PROFILE')}</Th>
                                        <Th colSpan={2} />
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('COMPANY_LOGO')}</Td>
                                        <Td >
                                            <HStack>
                                                <Image
                                                    boxSize={'50px'}
                                                    objectFit={'cover'}
                                                    src={!stringIsNullOrEmpty(detailsModal?.companyProfile?.companyLogo) ? detailsModal?.companyProfile?.companyLogo : require('../../../assets/img/no_image.png')} />
                                                <IconButton
                                                    colorScheme={'brand'}
                                                    variant={'brand'}
                                                    onClick={() => window.open(detailsModal?.companyProfile?.companyLogo, "_blank")}
                                                    isDisabled={stringIsNullOrEmpty(detailsModal?.companyProfile?.companyLogo)}
                                                    icon={<FaEye h={3} w={3} />}
                                                    size={'sm'}
                                                    ml={4}
                                                />
                                            </HStack>
                                        </Td>
                                        <Td />
                                        <Td />
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('DIRECTOR_PARTNER_NAME')}</Td>
                                        <Td>{detailsModal?.companyProfile?.directorName}</Td>
                                        <Td fontWeight={'800'}>{t('BUSINESS_REG_NUMBER')}</Td>
                                        <Td>
                                            {
                                                !stringIsNullOrEmpty(detailsModal?.companyProfile?.companyRegistrationDocument) ?
                                                    <Link
                                                        href={detailsModal?.companyProfile?.companyRegistrationDocument} isExternal>
                                                        {detailsModal?.companyProfile?.companyRegistrationNumber} <ExternalLinkIcon mx='2px' />
                                                    </Link> :
                                                    <Text>{detailsModal?.companyProfile?.companyRegistrationNumber}</Text>
                                            }
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('TYPE_OF_DOCUMENT')}</Td>
                                        <Td>
                                            {
                                                !stringIsNullOrEmpty(detailsModal?.companyProfile?.documentTypeFile) ?
                                                    <Link
                                                        href={detailsModal?.companyProfile?.documentTypeFile} isExternal>
                                                        {detailsModal?.companyProfile?.documentType} <ExternalLinkIcon mx='2px' />
                                                    </Link> :
                                                    <Text>{detailsModal?.companyProfile?.documentType}</Text>
                                            }
                                        </Td>
                                        <Td fontWeight={'800'}>{t('COMPANY_WEBSITE')}</Td>
                                        <Td>
                                            {
                                                !stringIsNullOrEmpty(detailsModal?.companyProfile?.companyWebsite) ?
                                                    <Link
                                                        href={detailsModal?.companyProfile?.companyWebsite?.startsWith('http') ?
                                                            detailsModal?.companyProfile?.companyWebsite :
                                                            `http://${detailsModal?.companyProfile?.companyWebsite}`} isExternal>
                                                        {detailsModal?.companyProfile?.companyWebsite} <ExternalLinkIcon mx='2px' />
                                                    </Link> :
                                                    <Text>-</Text>
                                            }
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('COMPANY_EMAIL')}</Td>
                                        <Td>{detailsModal?.companyProfile?.companyEmail}</Td>
                                        <Td fontWeight={'800'}>{t('COMPANY_TELEPHONE')}</Td>
                                        <Td>{detailsModal?.companyProfile?.companyTelephone}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('ADDRESS')}</Td>
                                        <Td>{detailsModal?.companyProfile?.address}</Td>
                                        <Td fontWeight={'800'}>{t('CITY')}</Td>
                                        <Td>{detailsModal?.companyProfile?.city}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('ZIPCODE')}</Td>
                                        <Td>{detailsModal?.companyProfile?.zipCode}</Td>
                                        <Td fontWeight={'800'}>{t('STATE')}</Td>
                                        <Td>{detailsModal?.companyProfile?.state}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('COUNTRY')}</Td>
                                        <Td>{detailsModal?.companyProfile?.country}</Td>
                                        <Td />
                                        <Td />
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <TableContainer className="supplier-detail-table">
                            <Table variant='simple' textAlign={'center'}>
                                <Thead>
                                    <Tr>
                                        <Th colSpan={2} fontWeight={'900'} fontSize={'larger'}>{t('COMPANY_REFERENCE')}</Th>
                                        <Th colSpan={2} />
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('COMPANY_NAME')}</Td>
                                        <Td>{detailsModal?.companyReferences?.[0]?.companyName ?? '-'}</Td>
                                        <Td fontWeight={'800'}>{t('COMPANY_NAME')}</Td>
                                        <Td>{detailsModal?.companyReferences?.[1]?.companyName ?? '-'}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('PERSON_NAME')}</Td>
                                        <Td>{detailsModal?.companyReferences?.[0]?.personName ?? '-'}</Td>
                                        <Td fontWeight={'800'}>{t('PERSON_NAME')}</Td>
                                        <Td>{detailsModal?.companyReferences?.[1]?.personName ?? '-'}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('DESIGNATION')}</Td>
                                        <Td>{detailsModal?.companyReferences?.[0]?.designation ?? '-'}</Td>
                                        <Td fontWeight={'800'}>{t('DESIGNATION')}</Td>
                                        <Td>{detailsModal?.companyReferences?.[1]?.designation ?? '-'}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('TELEPHONE')}</Td>
                                        <Td>{detailsModal?.companyReferences?.[0]?.contactNumber ?? '-'}</Td>
                                        <Td fontWeight={'800'}>{t('TELEPHONE')}</Td>
                                        <Td>{detailsModal?.companyReferences?.[1]?.contactNumber ?? '-'}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('EMAIL')}</Td>
                                        <Td>{detailsModal?.companyReferences?.[0]?.emailId ?? '-'}</Td>
                                        <Td fontWeight={'800'}>{t('EMAIL')}</Td>
                                        <Td>{detailsModal?.companyReferences?.[1]?.emailId ?? '-'}</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='red' onClick={() => setDetailsModal({})}>
                            {t('CLOSE')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    )
}