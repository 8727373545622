import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
    Image,
    Flex,
    Button,
    Text,
    Link,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Divider,
    AbsoluteCenter,
    Input
} from "@chakra-ui/react";
import {
    Role,
    _DIAMOND_SHAPE_OPTIONS,
    _DIAMOND_CLARITY_OPTIONS,
    _DIAMOND_COLOR_OPTIONS,
    _DIAMOND_FINISH_LEVEL_OPTIONS,
    _DIAMOND_FLUORESCENSE_OPTIONS,
    _DIAMOND_LAB_OPTIONS,
    _DIAMOND_LOCATION_OPTIONS,
    _DIAMOND_TINGE_SHADE_OPTIONS,
    _DIAMOND_TINGE_MILKY_OPTIONS,
    _DIAMOND_TINGE_EYE_CLEAN_OPTIONS,
    _DIAMOND_TREATMENT_OPTIONS,
    _DIAMOND_TYPE_OPTION,
    ApiKey,
    CommonStatus,
    RegexPattern
} from "../common/constant";
import moment from "moment";
import { createMultiPartFormBody, stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat, renderDiamondTypeBadge, getLabCertificateTrackUrl } from "../common/util";
import { showCustomDialog } from "../application/action/app_action";
import CustomMediaUploader from "components/custom-media-uploader";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import DiamondMedia from './diamond-media';
import CustomSelect from "./custom-select";
import { useForm, Controller } from "react-hook-form";

/// <summary>
/// Author: CK
/// </summary>
const ConfirmDiamondModal = React.forwardRef((props, ref) => {
    const {
        modalVisible = false,
        setModalVisible,
        selectedDiamonds,
        orderDiamonds,
        isFromHoldDiamond = false,
        title,
        ...restProps
    } = props;
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    var _dispatch = useDispatch();

    /// <summary>
    /// Author: CK
    /// </summary>
    const onSubmit = async (data, e) => {
        try {
            let orderDiamondParam = {
                orderIds:  selectedDiamonds.map(i => i.id).join(',')
            };

            orderDiamonds(orderDiamondParam);
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
    };

    return (
        <Modal size={'5xl'} isOpen={modalVisible} onClose={() => setModalVisible(false)}>
            <ModalOverlay />
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent
                    containerProps={{
                        zIndex: '9999',
                    }} >
                    <ModalHeader fontSize={'18px'}>{t(!stringIsNullOrEmpty(title) ? title : "CONFIRM_ORDER_DIAMONDS")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex>
                            <Text fontSize={"13px"} mb="5px">{t("TOTAL_DIAMONDS")}: {selectedDiamonds.length}</Text>
                        </Flex>
                        <TableContainer>
                            <Table variant='simple' className="hold-diamond-table">
                                <Thead backgroundColor={'gray.300'}>
                                    <Tr>
                                        <Th>{t("DIAMOND_DETAILS")}</Th>
                                        <Th>{t("LOCATION")}</Th>
                                        <Th textAlign={'right'}>{t("TOTAL_PRICE")}<br />$/CT</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        selectedDiamonds.map(item => {
                                            let diamondImg = item.diamond.diamondMedias.find(item => item.mediaType === 'Image');

                                            return (
                                                <Tr>
                                                    <Td>
                                                        <Flex flexDirection={'row'} alignItems={'center'}>
                                                            {
                                                                diamondImg ?
                                                                    <Image src={diamondImg.mediaUrl} />
                                                                    : <Image src={_DIAMOND_SHAPE_OPTIONS.find(i => i.id == item.diamond.shapeId)?.defaultImg} />
                                                            }
                                                            <Flex ml='10px' flexDir={'column'} alignItem='flex-start'>
                                                                <Flex alignItems={'center'}>
                                                                    <Flex alignItems={'center'} mr='5px'>
                                                                        {renderDiamondTypeBadge(item.diamond.typeId)}
                                                                        <Text
                                                                            ml='3px'
                                                                            fontSize={'13px'}
                                                                        >
                                                                            {item.diamond.stockNo}
                                                                        </Text>
                                                                    </Flex>
                                                                    <Link
                                                                        fontSize={'13px'}
                                                                        color='gold.600'
                                                                        href={getLabCertificateTrackUrl(item.diamond.labId, item.diamond.certificateId)} isExternal>
                                                                        {t(_DIAMOND_LAB_OPTIONS.find(i => i.id == item.diamond.labId)?.label)}-{item.diamond.certificateId}
                                                                    </Link>
                                                                </Flex>
                                                                <Text fontWeight={'bold'} fontSize={'16px'}>{item.diamond.label}</Text>
                                                            </Flex>
                                                        </Flex>
                                                    </Td>
                                                    <Td>
                                                        <Text fontSize="16px">
                                                            {t(_DIAMOND_LOCATION_OPTIONS.find(l => l.id == item.diamond.locationId)?.label)}
                                                        </Text>
                                                    </Td>
                                                    <Td>
                                                        <Flex flexDir="column" alignItems={'flex-end'}>
                                                            <Text fontWeight={700} fontSize={'16px'}>${numberWithCurrencyFormat(item.totalPrice)}</Text>
                                                            <Text color="secondaryGray.500" fontWeight={500} fontSize={'12px'}>$/CT {numberWithCurrencyFormat(parseFloat(item.pricePerCarat))}</Text>
                                                        </Flex>
                                                    </Td>
                                                </Tr>
                                            )
                                        })
                                    }
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <Divider marginY={'10px'} />
                        <Flex justifyContent={'flex-end'}>
                            <Flex flexDir="column" alignItems={'flex-end'}>
                                <Text color="secondaryGray.500" fontWeight={500} fontSize={'16px'}>$/CT {numberWithCurrencyFormat(selectedDiamonds.reduce((sum, i) => sum + parseFloat(i.pricePerCarat), 0))}</Text>
                                <Text fontWeight={700} fontSize={'18px'}>{t("TOTAL_PRICE")} USD: ${numberWithCurrencyFormat(selectedDiamonds.reduce((sum, i) => sum + parseFloat(i.totalPrice), 0))}</Text>
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            size={'sm'}
                            minW={'60px'}
                            variant={'link'}
                            color={'red.400'}
                            borderRadius={5}
                            onClick={() => setModalVisible(false)}>
                            {t('CANCEL')}
                        </Button>
                        <Button
                            size={'sm'}
                            minW={'60px'}
                            variant="brand"
                            type="submit"
                            borderRadius={5}>
                            {t('SUBMIT')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    )
});

export default ConfirmDiamondModal;