import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import {
    Box,
    Flex,
    Button,
    Text,
    Link,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Badge,
    Image,
    Textarea,
    IconButton
} from "@chakra-ui/react";
import {
    Role,
    _DIAMOND_SHAPE_OPTIONS,
    _DIAMOND_CLARITY_OPTIONS,
    _DIAMOND_COLOR_OPTIONS,
    _DIAMOND_FINISH_LEVEL_OPTIONS,
    _DIAMOND_FLUORESCENSE_OPTIONS,
    _DIAMOND_LAB_OPTIONS,
    _DIAMOND_LOCATION_OPTIONS,
    _DIAMOND_TINGE_SHADE_OPTIONS,
    _DIAMOND_TINGE_MILKY_OPTIONS,
    _DIAMOND_TINGE_EYE_CLEAN_OPTIONS,
    _DIAMOND_TREATMENT_OPTIONS,
    _DIAMOND_TYPE_OPTION,
    ApiKey,
    SettlementStatus
} from "../common/constant";
import { createMultiPartFormBody, numberWithCurrencyFormat, renderDiamondTypeBadge, getLabCertificateTrackUrl, getFileTypeFromUrl, stringIsNullOrEmpty } from "../common/util";
import { FaHandshakeSimple, FaCircleInfo, FaDownload, FaFileInvoiceDollar } from "react-icons/fa6";
import { showCustomDialog } from "../application/action/app_action";
import ApiEngine from "../common/api-engine";
import Routes from "../common/api_routes";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import CustomInput from "./custom-input";
import CustomMediaUploader from "./custom-media-uploader";

/// <summary>
/// Author: Saitama
/// </summary>
const SettlementDiamondDetails = React.forwardRef((props, ref) => {
    const {
        item,
        extraInfo,
        ...restProps
    } = props;
    var _dispatch = useDispatch();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    const { t } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const [settledModal, setSettledModal] = useState(false);
    const [receipt, setReceipt] = useState();
    const [previewReceipt, setPreviewReceipt] = useState([]);
    const [settlementInfo, setSettlementInfo] = useState(false);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function downloadFile(url) {
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/octet-stream',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${extraInfo?.supplier.supplierProfile.companyName}_${extraInfo?.id + 10000}_SETTLEMENT_RECEIPT`;
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((error) => console.error('Download error:', error));
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const onSubmit = async (data, e) => {
        try {
            let params = {
                status: SettlementStatus._APPROVED,
                remark: data.remark,
                file: receipt
            };
            var responseJson = await ApiEngine.put(`${Routes._SETTLEMENT_REPORT}/${extraInfo?.id}`, createMultiPartFormBody(params), {
                headers: {
                    'content-type': ApiKey._API_MULTIPART_FORM
                }
            });

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setSettledModal(false);
            props.onRefresh();
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
    };

    /// <summary>
    /// Author: CK
    /// </summary>
    async function generatePurchaseOrder(extraInfo) {
        try {
            let poUrl = extraInfo?.purchaseOrderPath;

            if (stringIsNullOrEmpty(poUrl)) {
                var responseJson = await ApiEngine.post(`${Routes._GENERATE_PURCHASE_ORDER.replace("{id}", extraInfo?.id)}`, {},
                    {
                        headers: {
                            'content-type': ApiKey._API_APPLICATION_JSON
                        }
                    });

                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    throw responseJson[ApiKey._API_MESSAGE_KEY];
                }

                poUrl = responseJson[ApiKey._API_DATA_KEY];
            }

            // download po document
            if (!stringIsNullOrEmpty(poUrl)) {
                fetch(poUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/octet-stream',
                    },
                })
                    .then((response) => response.blob())
                    .then((blob) => {
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = "PO_" + (10000 + extraInfo?.id)  
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    })
                    .catch((error) => console.error('Download error:', error));
            }
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
    }

    return (
        <Box className="diamond-detail-accordion" bgColor={'gray.100'} p={'15px'}>
            <Flex flexDir={'column'}>
                <Flex justifyContent={'flex-end'} mb={5} gap={'7.5px'}>
                    {
                        extraInfo?.status != SettlementStatus._PENDING &&
                        <Button
                            colorScheme={'blue'}
                            fontSize="sm"
                            fontWeight="500"
                            leftIcon={<FaCircleInfo />}
                            onClick={() => {
                                setSettlementInfo(true);
                            }}
                        >
                            {t('SETTLEMENT_INFO')}
                        </Button>
                    }
                    {
                        _USER_DATA?.user?.roleId != Role._SUPPLIER &&
                        <Button
                            colorScheme="blue"
                            fontSize="sm"
                            fontWeight="500"
                            type="button"
                            ml="5px"
                            leftIcon={<FaFileInvoiceDollar />}
                            onClick={() => { generatePurchaseOrder(extraInfo); }}
                        >
                            {t('GENERATE_PURCHASE_ORDER')}
                        </Button>
                    }
                    {
                        _USER_DATA?.user?.roleId != Role._SUPPLIER && extraInfo?.status == SettlementStatus._PENDING &&
                        <Button
                            colorScheme={'green'}
                            fontSize="sm"
                            fontWeight="500"
                            leftIcon={<FaHandshakeSimple />}
                            onClick={() => {
                                setSettledModal(true);
                            }}
                        >
                            {t('SETTLED')}
                        </Button>
                    }
                </Flex>
                <TableContainer width={'100%'}>
                    <Table variant='simple' className="hold-diamond-table">
                        <Thead backgroundColor={'gray.300'}>
                            <Tr>
                                {
                                    _USER_DATA?.user?.roleId != Role._SUPPLIER &&
                                    <Th>{t("INVOICE_NO")}</Th>
                                }
                                <Th>{t("DIAMOND_DETAILS")}</Th>
                                <Th>{t("LOCATION")}</Th>
                                <Th textAlign={'right'}>{t("TOTAL_PRICE")}<br />$/CT</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                item.map(order => {
                                    let diamondDetail = order.diamond != undefined ? order.diamond : order;
                                    let diamondImg = diamondDetail.diamondMedias.find(item => item.mediaType === 'Image');

                                    return (
                                        <Tr>
                                            {
                                                _USER_DATA?.user?.roleId != Role._SUPPLIER &&
                                                <Td>
                                                    <Text fontSize="15px">
                                                        {order.invoiceId + 10000}
                                                    </Text>
                                                </Td>
                                            }
                                            <Td>
                                                <Flex flexDirection={'row'} alignItems={'center'}>
                                                    {
                                                        diamondImg ?
                                                            <Image src={diamondImg.mediaUrl} />
                                                            : <Image src={_DIAMOND_SHAPE_OPTIONS.find(i => i.id == diamondDetail.shapeId)?.defaultImg} />
                                                    }
                                                    <Flex ml='10px' flexDir={'column'} alignItem='flex-start'>
                                                        <Flex alignItems={'center'}>
                                                            <Flex alignItems={'center'} mr='5px'>
                                                                {renderDiamondTypeBadge(diamondDetail.typeId)}
                                                                <Text
                                                                    ml='3px'
                                                                    fontSize={'13px'}
                                                                >
                                                                    {diamondDetail.stockNo}
                                                                </Text>
                                                            </Flex>
                                                            <Link
                                                                fontSize={'13px'}
                                                                color='gold.600'
                                                                href={getLabCertificateTrackUrl(diamondDetail.labId, diamondDetail.certificateId)} isExternal>
                                                                {t(_DIAMOND_LAB_OPTIONS.find(i => i.id == diamondDetail.labId)?.label)}-{diamondDetail.certificateId}
                                                            </Link>
                                                        </Flex>
                                                        <Text fontWeight={'bold'} fontSize={'16px'}>{diamondDetail.label}</Text>
                                                        {
                                                            diamondDetail.inWarehouse &&
                                                            <Badge variant='outline' colorScheme={'purple'} maxW={'110px'} borderRadius={0}>{t('IN_WAREHOUSE')}</Badge>
                                                        }
                                                    </Flex>
                                                </Flex>
                                            </Td>
                                            <Td>
                                                <Text fontSize="16px">
                                                    {t(_DIAMOND_LOCATION_OPTIONS.find(l => l.id == diamondDetail.locationId)?.label)}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Flex flexDir="column" alignItems={'flex-end'}>
                                                    <Text fontWeight={700} fontSize={'16px'}>${numberWithCurrencyFormat(order.totalPrice)}</Text>
                                                    <Text color="secondaryGray.500" fontWeight={500} fontSize={'12px'}>$/CT {numberWithCurrencyFormat(parseFloat(order.pricePerCarat))}</Text>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </Flex>
            <Modal isOpen={settledModal} onClose={() => setSettledModal(false)}>
                <ModalOverlay />
                <ModalContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ModalHeader>{`${t('SETTLEMENT')} (${extraInfo?.supplier.supplierProfile.companyName} - ${extraInfo?.id + 10000})`}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Text
                                display={"flex"}
                                ms={"4px"}
                                fontSize={"md"}
                                fontWeight={"500"}
                                mb={"5px"}
                            >
                                {t('BANK_RECEIPT')}
                            </Text>
                            <Controller
                                control={control}
                                name="file"
                                defaultValue={item?.bankReceiptAttachment}
                                render={({ onChange, value, name }) => (
                                    <CustomMediaUploader
                                        id={name}
                                        fileUrls={previewReceipt}
                                        allowPdf
                                        onChange={({ fileList: newFileList }) => {
                                            setReceipt(newFileList);
                                            onChange(newFileList);
                                        }}
                                        onDelete={(id) => { setReceipt([]); }}
                                        errors={errors} />
                                )}
                                rules={{
                                    required: true,
                                    message: "REQUIRED"
                                }}
                            />
                            <Controller
                                control={control}
                                name="remark"
                                defaultValue={''}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        isTextArea
                                        onChange={(value) => onChange(value)}
                                        value={value}
                                        label={'REMARK'}
                                        placeHolder={"ENTER_REMARK"}
                                        errors={errors}
                                    />
                                )}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Flex gap={5}>
                                <Button type={'submit'} colorScheme='green'>
                                    {t('SUBMIT')}
                                </Button>
                                <Button colorScheme='red' onClick={() => setSettledModal(false)}>
                                    {t('CANCEL')}
                                </Button>
                            </Flex>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
            <Modal isOpen={settlementInfo} onClose={() => setSettlementInfo(false)}>
                <ModalOverlay />
                <ModalContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ModalHeader>{`${t('SETTLEMENT')} (${extraInfo?.supplier.supplierProfile.companyName} - ${extraInfo?.id + 10000})`}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Text fontWeight={'800'}>{t('BANK_RECEIPT')}</Text>
                            <Box position="relative" w="75px" h="75px" overflow="hidden" role="group">
                                <Image
                                    boxSize={'75px'}
                                    objectFit={'cover'}
                                    src={getFileTypeFromUrl(extraInfo?.receipt) === 'PDF' ? require('../assets/img/pdf.png') : extraInfo?.receipt}
                                />
                                <IconButton
                                    position="absolute"
                                    top="50%"
                                    left="50%"
                                    transform="translate(-50%, -50%)"
                                    opacity="0"
                                    transition="opacity 0.3s ease"
                                    _groupHover={{ opacity: 1 }}
                                    icon={<FaDownload />}
                                    onClick={() => downloadFile(extraInfo?.receipt)} />
                            </Box>
                            <Text fontWeight={'800'} mt={10}>{t('REMARK')}</Text>
                            <Textarea isDisabled value={extraInfo?.remark} placeholder={t('EMPTY_REMARK')} />
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme='red' onClick={() => setSettlementInfo({})}>
                                {t('CLOSE')}
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </Box>
    )
});

export default SettlementDiamondDetails;