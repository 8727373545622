import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  Button,
  FormControl,
  Center,
  Stack,
  Link,
  Text
} from "@chakra-ui/react";
import { showCustomDialog } from "../../../application/action/app_action";
import Card from "../../../components/card/Card";
import CustomSelect from "../../../components/custom-select";
import CustomGroupCheckBox from "../../../components/custom-group-checkbox";
import { _DIAMOND_TYPE_OPTION, ApiKey, SupplierProfileStatus } from "../../../common/constant";
import ApiEngine from "../../../common/api-engine";
import Routes from "../../../common/api_routes";
import { createMultiPartFormBody, stringIsNullOrEmpty, isVerifiedSupplier } from "../../../common/util";
import {
  MdOutlineMoveToInbox
} from "react-icons/md";
import { message, Upload } from 'antd';
import { useRouter } from "../../../common/useRouter";
import { WebUrl } from "../../../routes";
const { Dragger } = Upload;

/// <summary>
/// Author: CK
/// </summary>
export default () => {
  const _USER_DATA = useSelector((state) => state.authState.userData);
  const { t } = useTranslation();
  const { control, handleSubmit, errors, setValue, watch } = useForm();
  var _dispatch = useDispatch();
  var _router = useRouter();
  const [uplaodedFile, setUplaodedFile] = useState();
  const [isVerified, setIsVerified] = useState(false);

  const props = {
    name: 'file',
    multiple: false,
    accept: '.csv',
    showUploadList: {
      showPreviewIcon: false,
      showDownloadIcon: false
    },
    beforeUpload: (file) => {
      setUplaodedFile(file)
      return false;
    },
    onRemove: (file) => {
      setUplaodedFile();
    }
  };

  /// <summary>
  /// Author: Saitama
  /// </summary>
  useEffect(() => {
    (async () => {
      let statusCode = await isVerifiedSupplier(_USER_DATA?.user);

      if (statusCode == SupplierProfileStatus._SUCCESS) {
        setIsVerified(true);
      }
      else {
        _dispatch(
          showCustomDialog({
            success: false,
            content: statusCode == SupplierProfileStatus._PENDING_APPROVAL ? 'WE_ARE_CURRENTLY_PROCESSING_YOUR_ACCOUNT' : "PLEASE_COMPLETE_YOUR_COMPANY_PROFILE_TO_PROCEED",
            confirmTxt: statusCode == SupplierProfileStatus._PENDING_APPROVAL ? 'OK' : "COMPANY_PROFILE",
            onConfirm: () => {
              if (statusCode == SupplierProfileStatus._EMPTY_PROFILE) {
                _router.navigate(WebUrl._SUPPLIER_PROFILE_SETTINGS);
              }
            }
          })
        );
      }

    })();
  }, []);

  /// <summary>
  /// Author: CK
  /// </summary>
  const submitForm = async (data, e) => {
    try {
      if (uplaodedFile == undefined || uplaodedFile == null) {
        throw "Please upload file.";
      }

      let params = {
        file: uplaodedFile
      };

      let responseJson = await ApiEngine.post(`${Routes._INVENTORY_UPLOAD}`, createMultiPartFormBody(params), {
        headers: {
          'content-type': ApiKey._API_MULTIPART_FORM
        }
      });

      if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
      else {
        _dispatch(
          showCustomDialog({
            success: true,
            content: responseJson[ApiKey._API_MESSAGE_KEY]
          })
        );
      }

      setUplaodedFile();
    }
    catch (err) {
      _dispatch(
        showCustomDialog({
          success: false,
          content: err,
        })
      );
    }
  };

  const handleDownload = () => {
    // URL of the CSV file
    const fileUrl = `${process.env.PUBLIC_URL}/sample.csv`;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'sample.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box pt={{ base: "80px", md: "60px", xl: "60px" }}>
      <form onSubmit={handleSubmit(submitForm)}>
        {
          isVerified &&
          <Card
            direction='column'
            w='100%'
            mb='0px'>
            <Flex mb={5} flexDir={'row'} justifyContent={'space-between'}>
              <Text>
                <Link color='teal.500' target={'_blank'} href='/diamond-supplier-csv-parameters.pdf'>{t('READ_FILE_DOCUMENTATION')}</Link> {t('TO_UPLOAD_FILE_FORMAT')}
              </Text>
              <Link color='teal.500' onClick={() => {
                handleDownload();
              }}>
                {t('DOWNLOAD_SAMPLE_FILE')}
              </Link>
            </Flex>
            <FormControl maxW={{ base: '100%', md: '50%' }} alignSelf='center'>
              <Stack mb={5}>
                <Dragger {...props}>
                  <Center width={'100%'} height={200}>
                    <MdOutlineMoveToInbox size={28} />
                    <p className="ant-upload-text">{t('CLICK_DRAG_UPLOAD_FILE')}</p>
                  </Center>
                </Dragger>
              </Stack>
              <Flex justifyContent={'flex-end'}>
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  mb="24px"
                  type="submit"
                >
                  {t('SUBMIT')}
                </Button>
              </Flex>
            </FormControl>
          </Card>
        }
      </form>
    </Box>
  );
}
