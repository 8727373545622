import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "../../../../common/useRouter";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    FormControl,
    Step,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
    Stack
} from "@chakra-ui/react";
import { showCustomDialog } from "../../../../application/action/app_action";
import Card from "../../../../components/card/Card";
import { HSeparator } from "../../../../components/separator/Separator";
import { WebUrl } from "../../../../routes";
import UserInfo from "./user-info";
import CompanyInfo from "./company-info";
import ShippingInfo from "./shipping-info";
import { checkIsLoggedIn } from "../../../../application/action/auth_action";
import { isObjectEmpty } from "../../../../common/util";
import ApiEngine from "../../../../common/api-engine";
import Routes from "../../../../common/api_routes";
import {
    ApiKey
} from "../../../../common/constant";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const { t } = useTranslation();
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _location = useLocation();
    const [jewellerDetails, setJewellerDetails] = useState(_location?.state?.jeweller);

    const _STEPPER_USER_INFO = 0;
    const _STEPPER_COMPANY_INFO = 1;
    const _STEPPER_SHIPPING_INFO = 2;
    const [stepper, setStepper] = useState([
        {
            key: _STEPPER_USER_INFO,
            title: 'USER_INFORMATION',
            isCompleted: false
        },
        {
            key: _STEPPER_COMPANY_INFO,
            title: 'COMPANY_INFORMATION',
            isCompleted: false
        },
        {
            key: _STEPPER_SHIPPING_INFO,
            title: 'SHIPPING_INFORMATION',
            isCompleted: false
        }
    ]);
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: stepper.length,
    });

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (jewellerDetails) {
            getJewellerDetails();

            if (!isObjectEmpty(jewellerDetails?.companyProfile)) {
                setStepper(stepper.map(item => ({
                    ...item,
                    isCompleted: true
                })));
            }
        }
        else if (!isObjectEmpty(_USER_DATA?.user?.companyProfile)) {
            setStepper(stepper.map(item => ({
                ...item,
                isCompleted: true
            })));
        }
    }, [])

    /// <summary>
    /// Author: CK
    /// </summary>
    async function getJewellerDetails() {
        var responseJson = await ApiEngine.get(`${Routes._USER}/${jewellerDetails.id}`, { headers: { "skipLoading": true } });

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setJewellerDetails(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function onCancel() {
        _router.back();
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const onSave = async (data, e) => {
        try {
            if (activeStep != stepper.length - 1) {
                let newStepper = [...stepper];
                let targetIndex = newStepper.findIndex(s => s.key == activeStep);
                newStepper[targetIndex]['isCompleted'] = true;
                newStepper[targetIndex + 1]['isCompleted'] = true;
                setStepper(newStepper);
                setActiveStep(activeStep + 1);
                getJewellerDetails();
            }
            else {

            }
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
    };

    return (
        <Box pt={{ base: "80px", md: "60px", xl: "60px" }}>
            <Card
                justifyContent='center'
                align='center'
                direction='column'
                w='100%'
                mb='0px'>
                <Stepper index={activeStep} colorScheme={'brand'} size={'md'} overflow={'auto'}>
                    {stepper.map((step, index) => (
                        <Step key={index} onClick={() => {
                            if (stepper[index]['isCompleted']) {
                                setActiveStep(index);
                            }
                        }}>
                            <Stack align={'center'}>
                                <StepIndicator>
                                    <StepStatus
                                        complete={<StepNumber />}
                                        incomplete={<StepNumber />}
                                        active={<StepNumber />}
                                    />
                                </StepIndicator>
                                <Box flexShrink='0'>
                                    <StepTitle>{t(step.title)}</StepTitle>
                                </Box>
                            </Stack>
                            <StepSeparator style={{ minWidth: '50px' }} />
                        </Step>
                    ))}
                </Stepper>
                <HSeparator my='20px' />
                <FormControl>
                    {
                        activeStep == _STEPPER_USER_INFO &&
                        <UserInfo
                            jewellerDetail={jewellerDetails}
                            onSave={onSave}
                            onCancel={onCancel} />
                    }
                    {
                        activeStep == _STEPPER_COMPANY_INFO &&
                        <CompanyInfo
                            jewellerDetail={jewellerDetails}
                            onSave={onSave}
                            onCancel={onCancel} />
                    }
                    {
                        activeStep == _STEPPER_SHIPPING_INFO &&
                        <ShippingInfo
                            jewellerDetail={jewellerDetails}
                            onSave={onSave}
                            onCancel={onCancel} />
                    }
                </FormControl>
            </Card>
        </Box>
    );
}
