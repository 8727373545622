import React, { useEffect, useState } from "react";
import { useRouter } from "../../../common/useRouter";
import { useTranslation } from "react-i18next";
import {
	Box,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	IconButton,
	Image,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Link,
	Button,
	HStack,
	Radio,
	RadioGroup,
	Stack,
	Badge
} from "@chakra-ui/react";
import { FaGear, FaMagnifyingGlass, FaEye, FaPeopleArrows } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { MdBlock } from "react-icons/md";
import { CgUnblock } from "react-icons/cg";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../../common/api_routes";
import CustomTable from "../../../components/custom-table";
import { WebUrl } from "../../../routes";
import { Role, ApiKey } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";
import { isObjectEmpty, stringIsNullOrEmpty } from "../../../common/util";
import ApiEngine from "../../../common/api-engine";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
	var _dispatch = useDispatch();
	var _router = useRouter();
	const { t } = useTranslation();
	const _USER_DATA = useSelector((state) => state.authState.userData);
	const [apiUrl, setApiUrl] = useState(`${Routes._USER}?roleId=${Role._SUPPLIER}&companyProfileApproved=true`);
	const [detailsModal, setDetailsModal] = useState({});
	const [salesPersonList, setSalesPersonList] = useState([]);
	const [targetSupplier, setTargetSupplier] = useState({});
	const [assignedSP, setAssignedSP] = useState('')
	const _COLUMNS = [
		{
			Header: "SALES_PERSON",
			Cell: ({ row }) => {
				return <>
					{
						!isObjectEmpty(row.original?.handledBy) ?
							<Text fontWeight={700}>{`${row.original?.handledBy?.username?.toUpperCase()} (${row.original?.handledBy?.firstName} ${row.original?.handledBy?.lastName})`}</Text> :
							<IconButton
								variant={'brand'}
								onClick={() => setTargetSupplier(row.original)}
								icon={<FaPeopleArrows h={3} w={3} />}
								size={'sm'}
							/>
					}
				</>
			}
		},
		{
			Header: "COMPANY_NAME",
			Cell: ({ row }) => {
				return <Text fontWeight={700}>{row.original.supplierProfile.companyName}</Text>
			}
		},
		{
			Header: "EMAIL",
			accessor: "email"
		},
		{
			Header: "NAME",
			Cell: ({ row }) => {
				return <Text fontWeight={700}>{`${row.original.firstName} ${row.original.lastName}`}</Text>
			}
		},
		{
			Header: "TELEPHONE",
			accessor: "phoneNumber"
		},
		{
			Header: "MIN_MAX_DIAMONDS",
			Cell: ({ row }) => {
				return <Text fontWeight={700}>{`${row.original.supplierProfile.minDiamondRange}-${row.original.supplierProfile.maxDiamondRange}`}</Text>
			}
		},
		{
			Header: "STATUS",
			accessor: "isActive",
			Cell: ({ value }) => {
				return <Badge colorScheme={value ? 'green' : 'red'}>{t(value ? 'ACTIVE' : 'DEACTIVE')}</Badge>
			}
		},
		{
			Header: "ACTION",
			Cell: ({ row }) => {
				return <Menu>
					<MenuButton as={IconButton} icon={<FaGear />} variant={'brand'} />
					<MenuList>
						<MenuItem icon={<FaMagnifyingGlass />} onClick={() => { setDetailsModal(row.original); }}>{t('VIEW')}</MenuItem>
                        <MenuItem icon={<FaEdit />} onClick={() => { _router.navigate(WebUrl._SUPPLIER_PROFILE_SETTINGS, { supplier: row.original }) }}>{t('EDIT')}</MenuItem>
						<MenuItem icon={<FaPeopleArrows />} onClick={() => { setTargetSupplier(row.original); }}>{t('ASSIGNED_SALES_PERSON')}</MenuItem>
						{
							row.original.isActive ?
								<MenuItem icon={<MdBlock />} onClick={() => { 
									_dispatch(showCustomDialog({
										success: true,
										content: t("BLOCK_USER", { email: row.original.email }),
										onCancel: () => { },
										onConfirm: async () => {
											await blockUser(row.original.id);
										}
									}));
								 }}>
									{t('BLOCK')}
								</MenuItem> :
								<MenuItem icon={<CgUnblock />} onClick={() => { 
									_dispatch(showCustomDialog({
										success: true,
										content: t("UNBLOCK_USER", { email: row.original.email }),
										onCancel: () => { },
										onConfirm: async () => {
											await unblockUser(row.original.id);
										}
									}));
								 }}>{t('UNBLOCK')}</MenuItem>
						}
					</MenuList>
				</Menu>
			}
		}
	];

	/// <summary>
	/// Author: Saitama
	/// </summary>
	useEffect(() => {
		if (!isObjectEmpty(targetSupplier)) {
			getSalesPerson();
		}
	}, [targetSupplier])

	/// <summary>
	/// Author: Saitama
	/// </summary>
	async function getSalesPerson() {
		try {
			var responseJson = await ApiEngine.get(`${Routes._USER}?roleId=${Role._SALES_PERSON}&isActive=true`);

			if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}

			if (responseJson[ApiKey._API_DATA_KEY]?.length == 0) {
				throw t('THERE_IS_NO_SALES_PERSON_AVAILABLE');
			}
			else {
				setSalesPersonList(responseJson[ApiKey._API_DATA_KEY].map(item => {
					return {
						value: item.id,
						label: item.username,
						name: `${item.firstName} ${item.lastName}`
					}
				}));
			}

			setAssignedSP(targetSupplier?.handledBy?.id);
		}
		catch (err) {
			_dispatch(
				showCustomDialog({
					success: false,
					content: err,
				})
			);
		}
	}

	/// <summary>
	/// Author: Saitama
	/// </summary>
	async function assignedSalesPerson() {
		try {
			var responseJson = await ApiEngine.put(`${Routes._USER}/${targetSupplier?.id}/attach/${assignedSP}`);

			if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}

			unAssignedSalesPerson();
		}
		catch (err) {
			_dispatch(
				showCustomDialog({
					success: false,
					content: err,
				})
			);
		}
	}

	/// <summary>
	/// Author: Saitama
	/// </summary>
	async function unAssignedSalesPerson() {
		try {
			if (targetSupplier?.handledById) {
				var responseJson = await ApiEngine.put(`${Routes._USER}/${targetSupplier?.id}/detach/${targetSupplier?.handledById}`);

				if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
					throw responseJson[ApiKey._API_MESSAGE_KEY];
				}
			}

			resetState();
			setApiUrl(`${Routes._USER}?roleId=${Role._SUPPLIER}&companyProfileApproved=true&v=${new Date()}`)
		}
		catch (err) {
			_dispatch(
				showCustomDialog({
					success: false,
					content: err,
				})
			);
		}
	}

	async function resetState() {
		setSalesPersonList([]);
		setAssignedSP('');
		setTargetSupplier({});
	}

	/// <summary>
	/// Author: CK
	/// </summary>
	const blockUser = async (userId) => {
		try {
			var  responseJson = await ApiEngine.post(`${Routes._USER}/${userId}/block`);

			if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}

			setApiUrl(`${Routes._USER}?roleId=${Role._SUPPLIER}&companyProfileApproved=true&v=${new Date()}`)
		}
		catch (err) {
			_dispatch(
				showCustomDialog({
					success: false,
					content: err
				})
			);
		}
	};

	/// <summary>
	/// Author: CK
	/// </summary>
	const unblockUser = async (userId) => {
		try {
			var  responseJson = await ApiEngine.post(`${Routes._USER}/${userId}/unblock`);

			if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}

			setApiUrl(`${Routes._USER}?roleId=${Role._SUPPLIER}&companyProfileApproved=true&v=${new Date()}`)
		}
		catch (err) {
			_dispatch(
				showCustomDialog({
					success: false,
					content: err
				})
			);
		}
	};

	return (
		<Box pt={{ base: "80px", md: "60px", xl: "60px" }}>
			<Card
				justifyContent='center'
				align='center'
				direction='column'
				w='100%'
				mb='0px'>
				<CustomTable
					columnsData={_COLUMNS}
					apiUrl={apiUrl} />
			</Card>
			<Modal size={'lg'} isOpen={salesPersonList.length > 0} onClose={() => resetState()}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{`${t('ASSIGNED_SALES_PERSON')} (${targetSupplier?.supplierProfile?.companyName})`}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<RadioGroup
							onChange={setAssignedSP}
							value={assignedSP}>
							<Stack direction='row' gap={5}>
								{
									salesPersonList.map((item, index) => (
										<Radio key={index} size='md' value={item.value}>{`${item?.label?.toUpperCase()} (${item?.name?.toUpperCase()})`}</Radio>
									))
								}
							</Stack>
						</RadioGroup>
					</ModalBody>
					<ModalFooter>
						<Button variant="brand" mr={3} onClick={() => { assignedSalesPerson(); }}>
							{t('ASSIGN')}
						</Button>
						<Button colorScheme='red' onClick={() => resetState()}>
							{t('CLOSE')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Modal size={'5xl'} isOpen={!isObjectEmpty(detailsModal)} onClose={() => setDetailsModal({})}>
				<ModalOverlay />
				<ModalContent
					containerProps={{
						zIndex: '99999',
					}} >
					<ModalHeader>{detailsModal?.supplierProfile?.companyName}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<TableContainer className="supplier-detail-table">
							<Table variant='simple' textAlign={'center'}>
								<Thead>
									<Tr>
										<Th colSpan={2} fontWeight={'900'} fontSize={'larger'}>{t('ACCOUNT_INFO')}</Th>
										<Th colSpan={2} />
									</Tr>
								</Thead>
								<Tbody>
									<Tr>
										<Td fontWeight={'800'}>{t('USERNAME')}</Td>
										<Td>{detailsModal?.username}</Td>
										<Td />
										<Td />
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('NAME')}</Td>
										<Td>{`${detailsModal?.firstName} ${detailsModal?.lastName}`}</Td>
										<Td />
										<Td />
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('TELEPHONE')}</Td>
										<Td>{detailsModal?.phoneNumber}</Td>
										<Td />
										<Td />
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('MIN_MAX_DIAMONDS')}</Td>
										<Td>{`${detailsModal?.supplierProfile?.minDiamondRange} - ${detailsModal?.supplierProfile?.maxDiamondRange}`}</Td>
										<Td />
										<Td />
									</Tr>
								</Tbody>
							</Table>
						</TableContainer>
						<TableContainer className="supplier-detail-table">
							<Table variant='simple' textAlign={'center'}>
								<Thead>
									<Tr>
										<Th colSpan={2} fontWeight={'900'} fontSize={'larger'}>{t('COMPANY_PROFILE')}</Th>
										<Th colSpan={2} />
									</Tr>
								</Thead>
								<Tbody>
									<Tr>
										<Td fontWeight={'800'}>{t('COMPANY_LOGO')}</Td>
										<Td >
											<HStack>
												<Image
													boxSize={'50px'}
													objectFit={'cover'}
													src={!stringIsNullOrEmpty(detailsModal?.companyProfile?.companyLogo) ? detailsModal?.companyProfile?.companyLogo : require('../../../assets/img/no_image.png')} />
												<IconButton
													colorScheme={'brand'}
													variant={'brand'}
													onClick={() => window.open(detailsModal?.companyProfile?.companyLogo, "_blank")}
													isDisabled={stringIsNullOrEmpty(detailsModal?.companyProfile?.companyLogo)}
													icon={<FaEye h={3} w={3} />}
													size={'sm'}
													ml={4}
												/>
											</HStack>
										</Td>
										<Td />
										<Td />
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('DIRECTOR_PARTNER_NAME')}</Td>
										<Td>{detailsModal?.companyProfile?.directorName}</Td>
										<Td fontWeight={'800'}>{t('BUSINESS_REG_NUMBER')}</Td>
										<Td>
											{
												!stringIsNullOrEmpty(detailsModal?.companyProfile?.companyRegistrationDocument) ?
													<Link
														href={detailsModal?.companyProfile?.companyRegistrationDocument} isExternal>
														{detailsModal?.companyProfile?.companyRegistrationNumber} <ExternalLinkIcon mx='2px' />
													</Link> :
													<Text>{detailsModal?.companyProfile?.companyRegistrationNumber}</Text>
											}
										</Td>
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('TYPE_OF_DOCUMENT')}</Td>
										<Td>
											{
												!stringIsNullOrEmpty(detailsModal?.companyProfile?.documentTypeFile) ?
													<Link
														href={detailsModal?.companyProfile?.documentTypeFile} isExternal>
														{detailsModal?.companyProfile?.documentType} <ExternalLinkIcon mx='2px' />
													</Link> :
													<Text>{detailsModal?.companyProfile?.documentType}</Text>
											}
										</Td>
										<Td fontWeight={'800'}>{t('COMPANY_WEBSITE')}</Td>
										<Td>
											{
												!stringIsNullOrEmpty(detailsModal?.companyProfile?.companyWebsite) ?
													<Link
														href={detailsModal?.companyProfile?.companyWebsite?.startsWith('http') ?
															detailsModal?.companyProfile?.companyWebsite :
															`http://${detailsModal?.companyProfile?.companyWebsite}`} isExternal>
														{detailsModal?.companyProfile?.companyWebsite} <ExternalLinkIcon mx='2px' />
													</Link> :
													<Text>-</Text>
											}
										</Td>
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('COMPANY_EMAIL')}</Td>
										<Td>{detailsModal?.companyProfile?.companyEmail}</Td>
										<Td fontWeight={'800'}>{t('COMPANY_TELEPHONE')}</Td>
										<Td>{detailsModal?.companyProfile?.companyTelephone}</Td>
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('ADDRESS')}</Td>
										<Td>{detailsModal?.companyProfile?.address}</Td>
										<Td fontWeight={'800'}>{t('CITY')}</Td>
										<Td>{detailsModal?.companyProfile?.city}</Td>
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('ZIPCODE')}</Td>
										<Td>{detailsModal?.companyProfile?.zipCode}</Td>
										<Td fontWeight={'800'}>{t('STATE')}</Td>
										<Td>{detailsModal?.companyProfile?.state}</Td>
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('COUNTRY')}</Td>
										<Td>{detailsModal?.companyProfile?.country}</Td>
										<Td />
										<Td />
									</Tr>
								</Tbody>
							</Table>
						</TableContainer>
						<TableContainer className="supplier-detail-table">
							<Table variant='simple' textAlign={'center'}>
								<Thead>
									<Tr>
										<Th colSpan={2} fontWeight={'900'} fontSize={'larger'}>{t('COMPANY_REFERENCE')}</Th>
										<Th colSpan={2} />
									</Tr>
								</Thead>
								<Tbody>
									<Tr>
										<Td fontWeight={'800'}>{t('COMPANY_NAME')}</Td>
										<Td>{detailsModal?.companyReferences?.[0]?.companyName ?? '-'}</Td>
										<Td fontWeight={'800'}>{t('COMPANY_NAME')}</Td>
										<Td>{detailsModal?.companyReferences?.[1]?.companyName ?? '-'}</Td>
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('PERSON_NAME')}</Td>
										<Td>{detailsModal?.companyReferences?.[0]?.personName ?? '-'}</Td>
										<Td fontWeight={'800'}>{t('PERSON_NAME')}</Td>
										<Td>{detailsModal?.companyReferences?.[1]?.personName ?? '-'}</Td>
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('DESIGNATION')}</Td>
										<Td>{detailsModal?.companyReferences?.[0]?.designation ?? '-'}</Td>
										<Td fontWeight={'800'}>{t('DESIGNATION')}</Td>
										<Td>{detailsModal?.companyReferences?.[1]?.designation ?? '-'}</Td>
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('TELEPHONE')}</Td>
										<Td>{detailsModal?.companyReferences?.[0]?.contactNumber ?? '-'}</Td>
										<Td fontWeight={'800'}>{t('TELEPHONE')}</Td>
										<Td>{detailsModal?.companyReferences?.[1]?.contactNumber ?? '-'}</Td>
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('EMAIL')}</Td>
										<Td>{detailsModal?.companyReferences?.[0]?.emailId ?? '-'}</Td>
										<Td fontWeight={'800'}>{t('EMAIL')}</Td>
										<Td>{detailsModal?.companyReferences?.[1]?.emailId ?? '-'}</Td>
									</Tr>
								</Tbody>
							</Table>
						</TableContainer>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme='red' onClick={() => setDetailsModal({})}>
							{t('CLOSE')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Box>
	)
}