import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  HStack,
  Text,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Icon
} from "@chakra-ui/react";
import { TbDots } from "react-icons/tb";

/// <summary>
/// Author: Saitama
/// </summary>
export function SidebarLinks(props) {
  const { routes, onClose } = props;
  const { t } = useTranslation();
  let location = useLocation();
  let activeColor = useColorModeValue("brand.900", "brand.100");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "white"
  );
  let activeIcon = useColorModeValue("brand.900", "brand.500");
  let textColor = useColorModeValue("brand.900", "brand.700");
  let brandColor = useColorModeValue("brand.900", "brand.500");

  /// <summary>
  /// Author: Saitama
  /// </summary>
  const activeRoute = (routeName) => {
    return location.pathname == routeName;
  };

  /// <summary>
  /// Author: Saitama
  /// </summary>
  const createLinks = (routes) => {
    return routes.filter(i => !i.isHidden).map((route, index) => {
      if (route.category) {
        return (
          <Flex flexDir='column' alignItems={'center'} width={'100%'}>
            <Icon className={"sibebar-menu-category-dots"} w='20px' h='20px' as={TbDots} color={brandColor} />
            <Box className={"sibebar-menu-category"} as="span" flex='1' textAlign='left'>
              {t(route.name)}
            </Box>
            {createLinks(route.items)}
          </Flex>
        );
      }
      else {
        return (
          <NavLink className={"sidebar-menu-link"} key={index} to={route.path}>
            {route.icon ? (
              <Box onClick={onClose}>
                <HStack gap={0}>
                  <Flex className={"sidebar-menu-link-text"} w='100%' alignItems='center' justifyContent='center' ml={route?.ml}>
                    <Flex
                      alignItems={'center'}
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                    >
                      {route.icon}
                    </Flex>
                    <Text
                      me='auto'
                      wordBreak='break-word'
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : textColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }>
                      {t(route.name)}
                    </Text>
                  </Flex>
                  <Box
                    h='28px'
                    w='4px'
                    bg={
                      activeRoute(route.path.toLowerCase())
                        ? brandColor
                        : "transparent"
                    }
                    borderRadius='5px'
                  />
                </HStack>
              </Box>
            ) : (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Text
                    me='auto'
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }>
                    {route.name}
                  </Text>
                  <Box h='28px' w='4px' bg='brand.400' borderRadius='5px' />
                </HStack>
              </Box>
            )}
          </NavLink>
        );
      }
    });
  };

  return createLinks(routes);
}

export default SidebarLinks;
