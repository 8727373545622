import React, { useEffect, useState } from "react";
import { useRouter } from "../../../common/useRouter";
import { useTranslation } from "react-i18next";
import { MdDiamond } from "react-icons/md";
import {
	Box,
	FormLabel,
	Badge,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	IconButton,
	Image,
	Text,
	Flex,
	Tooltip,
	Button,
	Link
} from "@chakra-ui/react";
import { FaGear, FaPen } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../../common/api_routes";
import CustomTable from "../../../components/custom-table";
import { WebUrl } from "../../../routes";
import {
	Role,
	_DIAMOND_SHAPE_OPTIONS,
	_DIAMOND_CLARITY_OPTIONS,
	_DIAMOND_COLOR_OPTIONS,
	_DIAMOND_FANCY_COLOR_OPTIONS,
	_DIAMOND_FINISH_LEVEL_OPTIONS,
	_DIAMOND_FLUORESCENSE_OPTIONS,
	_DIAMOND_LAB_OPTIONS,
	_DIAMOND_TYPE_OPTION,
	_DIAMOND_STOCK_STATUS_OPTIONS,
	DiamondStockStatus,
	SupplierProfileStatus,
	ApiKey,
	_DIAMOND_AVAILABILITY_OPTIONS,
	DiamondSortBy
} from "../../../common/constant";
import { isVerifiedSupplier } from "../../../common/util";
import { showCustomDialog, showCustomInputDialog, disposeCustomInputDialog } from "../../../application/action/app_action";
import moment from "moment";
import ApiEngine from "../../../common/api-engine";
import { NavLink, useLocation } from "react-router-dom";
import { stringToIntArray, createMultiPartFormBody, numberWithCurrencyFormat, renderDiamondTypeBadge, getLabCertificateTrackUrl } from '../../../common/util';
import DiamondDetailModal from "../../../components/diamond-detail-modal";
import DiamondDetail from "../../../components/diamond-detail";
import DiamondDetailGrid from "../../../components/diamond-detail-grid";

/// <summary>
/// Author: CK
/// </summary>
export default () => {
	var _dispatch = useDispatch();
	var _router = useRouter();
	const { t } = useTranslation();
	const _USER_DATA = useSelector((state) => state.authState.userData);
	const [apiUrl, setApiUrl] = useState();
	let { search } = useLocation();
	const _query = new URLSearchParams(search);
	const [postData, setPostData] = useState({});
	const [uploadModal, setUploadModal] = useState({});
	const [uploadedMedia, setUploadedMedia] = useState([]);
	const [mediaToDelete, setMediaToDelete] = useState([]);
	const [selectedDiamond, setSelectedDiamond] = useState();
	const [selectedDiamonds, setSelectedDiamonds] = useState([]);
	const [checkAll, setCheckAll] = useState(false);
	const [allDiamonds, setAllDiamonds] = useState([]);
	const [wareHouseDiamond, setWareHouseDiamond] = useState();

	const _COLUMNS = [
		{
			id: 'checkbox',
			headerWidth: '1%',
			customHeader: (
				<div className="checkbox checkbox-css checkbox-inline inbox-checkbox">
					<input id="checkboxSelectAll" type="checkbox" onChange={() => setCheckAll(!checkAll)} checked={checkAll} />
					<label htmlFor="checkboxSelectAll"></label>
				</div>
			),
			Cell: ({ row }) => {
				return (
					<div className="checkbox checkbox-css checkbox-inline inbox-checkbox">
						<input
							id={`checkbox${row.id}`}
							type="checkbox"
							onChange={() => updateSelectedDiamonds(row.original.id)}
							checked={selectedDiamonds.find(t => t == row.original.id)} />
						<label htmlFor={`checkbox${row.id}`}></label>
					</div>
				)
			},
			width: 30,
			disableSortBy: true,
		},
		{
			Header: "UPLOADED_DATE_TIME",
			accessor: "createdAt",
			Cell: ({ row }) => {
				return <Text>{moment(row.original.updatedAt).format('YYYY-MM-DD h:mm:ss A')}</Text>
			}
		},
		..._USER_DATA?.user?.roleId != Role._SUPPLIER ?
			[{
				Header: "SUPPLIER",
				accessor: "supplierName"
			}] : [],
		{
			Header: "STOCK_NO",
			accessor: "stockNo",
			Cell: ({ row }) => {
				return <Flex alignItems={'center'}>
					{renderDiamondTypeBadge(row.original.typeId)}
					<Text ml="3px">{row.original.stockNo}</Text>
				</Flex>
			}
		},
		{
			Header: "DETAIL",
			Cell: ({ row }) => {
				return <Tooltip label={t("DETAIL")}><IconButton style={{ padding: '10px' }} bgColor={"brand.800"} color={'white'} icon={<MdDiamond size={'16px'} />} onClick={() => setUploadModal(row.original)} /></Tooltip>
			}
		},
		{
			Header: "STATUS",
			accessor: "status",
			Cell: ({ row }) => {
				let status = row.original.status;
				let statusLabel = t(_DIAMOND_STOCK_STATUS_OPTIONS.find(i => i.id == row.original.status)?.label);
				let badgeColorScheme = 'blue';

				if (status == DiamondStockStatus._ON_HOLD) {
					badgeColorScheme = 'orange';
				}
				else if (status == DiamondStockStatus._PENDING_APPROVAL) {
					badgeColorScheme = 'orange';
				}
				else if (status == DiamondStockStatus._ORDERED
					|| status == DiamondStockStatus._PAYMENT_MADE
					|| status == DiamondStockStatus._QUALITY_CHECK
					|| status == DiamondStockStatus._PREPARE_DELIVERY
					|| status == DiamondStockStatus._SHIPED
					|| status == DiamondStockStatus._RECEIVED
				) {
					badgeColorScheme = 'green';
				}

				return <Badge variant='subtle' colorScheme={badgeColorScheme}>{statusLabel}</Badge>
			}
		},
		{
			Header: "AVAILABILITY",
			accessor: "availability",
			Cell: ({ row }) => {
				return <Button
					fontSize={'12px'}
					className="link-btn"
					variant='link'
					onClick={() => {
						setSelectedDiamond(row.original);
					}}
				>
					{t(_DIAMOND_AVAILABILITY_OPTIONS.find(i => i.id == row.original.availability)?.label)}
				</Button>
			}
		},
		{
			Header: "IN_WAREHOUSE",
			accessor: "inWarehouse",
			Cell: ({ row }) => {
				return <Button
					fontSize={'12px'}
					className="link-btn"
					variant='link'
					onClick={() => {
						setWareHouseDiamond(row.original);
					}}
				>
					{t(row.original.inWarehouse ? "YES" : "NO")}
				</Button>
			}
		},
		{
			Header: "SHAPE",
			accessor: "shapeId",
			Cell: ({ row }) => {
				return <Tooltip label={t(_DIAMOND_SHAPE_OPTIONS.find(i => i.id == row.original.shapeId)?.label)}><Image className="diamond-shape-img" src={_DIAMOND_SHAPE_OPTIONS.find(i => i.id == row.original.shapeId)?.image} /></Tooltip>
			}
		},
		{
			Header: "CARAT",
			accessor: "carat",
			Cell: ({ row }) => {
				return <Text>{numberWithCurrencyFormat(row.original.carat, 2, true)}</Text>
			}
		},
		{
			Header: "COLOR",
			accessor: "colorId",
			Cell: ({ row }) => {
				return <Text>{t(
					!row.original.isFancyColor ? _DIAMOND_COLOR_OPTIONS.find(i => i.id == row.original.colorId)?.label
						: _DIAMOND_FANCY_COLOR_OPTIONS.find(i => i.id == row.original.fancyColorId)?.label
				)}</Text>
			}
		},
		{
			Header: "CLARITY",
			accessor: "clarityId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_CLARITY_OPTIONS.find(i => i.id == row.original.clarityId)?.label)}</Text>
			}
		},
		{
			Header: "CUT",
			accessor: "cutFinishLevelId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_FINISH_LEVEL_OPTIONS.find(i => i.id == row.original.cutFinishLevelId)?.label)}</Text>
			}
		},
		{
			Header: "POLISH",
			accessor: "polishFinishLevelId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_FINISH_LEVEL_OPTIONS.find(i => i.id == row.original.polishFinishLevelId)?.label)}</Text>
			}
		},
		{
			Header: "SYMMETRY",
			accessor: "symmetryFinishLevelId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_FINISH_LEVEL_OPTIONS.find(i => i.id == row.original.symmetryFinishLevelId)?.label)}</Text>
			}
		},
		{
			Header: "FLUO",
			accessor: "fluorescenceId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_FLUORESCENSE_OPTIONS.find(i => i.id == row.original.fluorescenceId)?.label)}</Text>
			}
		},
		{
			Header: "LAB",
			accessor: "labId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_LAB_OPTIONS.find(i => i.id == row.original.labId)?.label)}</Text>
			}
		},
		{
			Header: "CERTIFICATE",
			accessor: "certificateId",
			Cell: ({ row }) => {
				return <Link
					fontSize={'13px'}
					color='gold.600'
					href={getLabCertificateTrackUrl(row.original.labId, row.original.certificateId)} isExternal>
					{row.original.certificateId}
				</Link>
			}
		},
		{
			Header: "PRICE_PER_CARAT",
			accessor: "pricePerCarat",
			Cell: ({ row }) => {
				return <Text>${numberWithCurrencyFormat(_USER_DATA?.user?.roleId == Role._SUPPLIER ? row.original.pricePerCarat : row.original.markupPricePerCarat)}</Text>
			}
		},
		{
			Header: "TOTAL",
			Cell: ({ row }) => {
				return <Text>${numberWithCurrencyFormat(_USER_DATA?.user?.roleId == Role._SUPPLIER ? row.original.price : row.original.totalPrice)}</Text>
			}
		}
	];

	/// <summary>
	/// Author: CK
	/// </summary> 
	function updateSelectedDiamonds(diamondId) {
		if (selectedDiamonds.filter(diamond => diamond == diamondId).length) {
			setSelectedDiamonds(selectedDiamonds.filter(diamond => diamond != diamondId));
		}
		else {
			setSelectedDiamonds([...selectedDiamonds, diamondId]);
		}
	}

	/// <summary>
	/// Author: CK
	/// </summary> 
	useEffect(() => {
		let diamonds = [];

		if (checkAll && allDiamonds.length > 0) {
			diamonds = allDiamonds.map(selected => selected.id);
		}

		setSelectedDiamonds(diamonds);
	}, [checkAll]);

	/// <summary>
	/// Author: CK
	/// </summary>
	const deleteDiamonds = async () => {
		try {
			if (selectedDiamonds.length <= 0) {
				throw "Please select diamond to delete.";
			}

			var responseJson = await ApiEngine.post(Routes._INVENTORY_DIAMONDS_DELETE, selectedDiamonds);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				_dispatch(
					showCustomDialog({
						success: true,
						content: responseJson[ApiKey._API_MESSAGE_KEY],
					})
				);
				setApiUrl(Routes._INVENTORY_DIAMONDS + '?v=' + moment());
				setSelectedDiamonds([]);
				setCheckAll(false);
			}
			else {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}
		}
		catch (err) {
			_dispatch(
				showCustomDialog({
					success: false,
					content: err,
				})
			);
		}
	};

	/// <summary>
	/// Author: CK
	/// Edited: Saitama
	/// </summary>
	useEffect(() => {
		(async () => {
			let statusCode = await isVerifiedSupplier(_USER_DATA?.user);

			if (statusCode == SupplierProfileStatus._SUCCESS) {
				let currentApiUrl = `${Routes._INVENTORY_DIAMONDS}?`;
				let currentPostData = {};

				if (_USER_DATA?.user?.roleId == Role._SUPPLIER) {
					currentPostData['supplierId'] = _USER_DATA?.user?.id;
				}

				if (_query?.get('minct')) {
					currentPostData['minCt'] = parseFloat(_query?.get('minct'));
				}

				if (_query?.get('maxct')) {
					currentPostData['maxct'] = parseFloat(_query?.get('maxct'));
				}

				if (_query?.get('isFancyColor')) {
					currentPostData['isFancyColor'] = _query?.get('isFancyColor');
				}

				if (_query?.get('shape')) {
					currentPostData['shape'] = stringToIntArray(_query?.get('shape'));
				}

				if (_query?.get('color')) {
					currentPostData['color'] = stringToIntArray(_query?.get('color'));
				}

				if (_query?.get('fancyColor')) {
					currentPostData['fancyColor'] = stringToIntArray(_query?.get('fancyColor'));
				}

				if (_query?.get('fancyColorIntensity')) {
					currentPostData['fancyColorIntensity'] = stringToIntArray(_query?.get('fancyColorIntensity'));
				}

				if (_query?.get('fancyColorOvertone')) {
					currentPostData['fancyColorOvertone'] = stringToIntArray(_query?.get('fancyColorOvertone'));
				}

				if (_query?.get('clarity')) {
					currentPostData['clarity'] = stringToIntArray(_query?.get('clarity'));
				}

				if (_query?.get('eyeclean')) {
					currentPostData['eyeClean'] = stringToIntArray(_query?.get('eyeclean'));
				}

				if (_query?.get('shade')) {
					currentPostData['shade'] = stringToIntArray(_query?.get('shade'));
				}

				if (_query?.get('milky')) {
					currentPostData['milky'] = stringToIntArray(_query?.get('milky'));
				}

				if (_query?.get('cut')) {
					currentPostData['cut'] = stringToIntArray(_query?.get('cut'));
				}

				if (_query?.get('polish')) {
					currentPostData['polish'] = stringToIntArray(_query?.get('polish'));
				}

				if (_query?.get('symmetry')) {
					currentPostData['symmetry'] = stringToIntArray(_query?.get('symmetry'));
				}

				if (_query?.get('fluo')) {
					currentPostData['fluoresence'] = stringToIntArray(_query?.get('fluo'));
				}

				if (_query?.get('location')) {
					currentPostData['location'] = stringToIntArray(_query?.get('location'));
				}

				if (_USER_DATA?.user?.roleId != Role._SUPPLIER) {
					currentPostData['status'] = DiamondStockStatus._ALL_PUBLISHED;
				}

				setPostData(currentPostData);
				setApiUrl(currentApiUrl);
			}
			else {
				_dispatch(
					showCustomDialog({
						success: false,
						content: statusCode == SupplierProfileStatus._PENDING_APPROVAL ? 'WE_ARE_CURRENTLY_PROCESSING_YOUR_ACCOUNT' : "PLEASE_COMPLETE_YOUR_COMPANY_PROFILE_TO_PROCEED",
						confirmTxt: statusCode == SupplierProfileStatus._PENDING_APPROVAL ? 'OK' : "COMPANY_PROFILE",
						onConfirm: () => {
							if (statusCode == SupplierProfileStatus._EMPTY_PROFILE) {
								_router.navigate(WebUrl._SUPPLIER_PROFILE_SETTINGS);
							}
						}
					})
				);
			}

		})();
	}, []);

	/// <summary>
	/// Author: CK
	/// </summary>
	useEffect(() => {
		if (selectedDiamond) {
			_dispatch(showCustomInputDialog({
				success: true,
				title: t("STOCK_NO_VALUE", { stockNo: selectedDiamond.stockNo }),
				content: t("AVAILABILITY"),
				onCancel: () => { setSelectedDiamond(); },
				onConfirm: (inputValue) => updateDiamond(inputValue),
				inputType: "select",
				options: _DIAMOND_AVAILABILITY_OPTIONS,
				isRequired: true,
				defaultValue: selectedDiamond.availability
			}));
		}
	}, [selectedDiamond]);

	/// <summary>
	/// Author: CK
	/// </summary>
	useEffect(() => {
		if (wareHouseDiamond) {
			_dispatch(showCustomInputDialog({
				success: true,
				title: t("STOCK_NO_VALUE", { stockNo: wareHouseDiamond.stockNo }),
				content: t("IN_WAREHOUSE"),
				onCancel: () => { setWareHouseDiamond(); },
				onConfirm: (inputValue) => updateDiamond(inputValue),
				inputType: "select",
				options: [{ label: t("YES"), value: true }, { label: t("NO"), value: false }],
				isRequired: true,
				defaultValue: wareHouseDiamond.inWarehouse
			}));
		}
	}, [wareHouseDiamond]);

	/// <summary>
	/// Author: CK
	/// </summary>
	const updateDiamond = async (inputValue) => {
		try {
			let params = {};

			if (selectedDiamond) {
				params['availability'] = inputValue;
			}
			else if (wareHouseDiamond) {
				params['inWarehouse'] = inputValue;
			}

			let responseJson = await ApiEngine.put(`${Routes._INVENTORY_DIAMONDS}/${selectedDiamond ? selectedDiamond.id : wareHouseDiamond.id}`, params);

			if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}

			setSelectedDiamond();
			setWareHouseDiamond();
			// _dispatch(disposeCustomInputDialog());
			setApiUrl(`${Routes._INVENTORY_DIAMONDS}?v=${new Date()}`);
		}
		catch (err) {
			_dispatch(
				showCustomDialog({
					success: false,
					content: err
				})
			);
		}
	};

	/// <summary>
	/// Author: Saitama
	/// </summary>
	const uploadDiamondMedia = async () => {
		try {
			if (uploadedMedia.length <= 0 && mediaToDelete.length == 0) {
				throw "Please upload media(s).";
			}

			let params = {
				diamondId: uploadModal.id
			};

			if (uploadedMedia.length > 0) {
				params["files"] = uploadedMedia;
			}

			if (mediaToDelete.length > 0) {
				params['removeIds'] = mediaToDelete.join(',');
			}
			var responseJson = await ApiEngine.put(Routes._DIAMOND_MEDIA_UPLOAD, createMultiPartFormBody(params), {
				headers: {
					'content-type': ApiKey._API_MULTIPART_FORM
				}
			});

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				_dispatch(
					showCustomDialog({
						success: true,
						content: responseJson[ApiKey._API_MESSAGE_KEY],
						onConfirm: () => {
							setApiUrl(Routes._INVENTORY_DIAMONDS + '?v=' + moment());
							setUploadModal({});
							setUploadedMedia([]);
							setMediaToDelete([]);
						}
					})
				);
			}
			else {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}
		}
		catch (err) {
			_dispatch(
				showCustomDialog({
					success: false,
					content: err,
				})
			);
		}
	};

	return (
		<Box pt={{ base: "80px", md: "60px", xl: "60px" }}>
			<Card
				justifyContent='center'
				align='center'
				direction='column'
				w='100%'
				mb='0px'>
				<Flex justifyContent={'flex-end'}>
					<Button
						variant='solid'
						bg='red.400'
						color='white'
						fontSize="sm"
						fontWeight="500"
						type="button"
						leftIcon={<RiDeleteBin6Line />}
						onClick={() => {
							_dispatch(showCustomDialog({
								success: true,
								content: t("DELETE_DIAMOND"),
								onCancel: () => { },
								onConfirm: async () => {
									await deleteDiamonds();
								}
							}));
						}}
					>
						{t('DELETE')}
					</Button>
				</Flex>
				<CustomTable
					columnsData={_COLUMNS}
					apiUrl={apiUrl}
					objFilter={postData}
					requestMethod={ApiKey._API_POST}
					customFetchAction={(responseJson) => setAllDiamonds(responseJson[ApiKey._API_DATA_KEY])}
					RowAccordionContent={DiamondDetail}
					GridViewItem={DiamondDetailGrid}
					SortMenus={[
						{
							category: "CARAT",
							options: [
								{
									label: "HIGH_TO_LOW",
									sortById: DiamondSortBy._CARAT,
									ascending: false
								},
								{
									label: "LOW_TO_HIGH",
									sortById: DiamondSortBy._CARAT,
									ascending: true
								}
							]
						},
						{
							category: "STOCK_NO",
							options: [
								{
									label: "DESC",
									sortById: DiamondSortBy._STOCK_ID,
									ascending: false
								},
								{
									label: "ASC",
									sortById: DiamondSortBy._STOCK_ID,
									ascending: true
								}
							]
						},
						{
							category: "SHAPE",
							options: [
								{
									label: "Z_TO_A",
									sortById: DiamondSortBy._SHAPE,
									ascending: false
								},
								{
									label: "A_TO_Z",
									sortById: DiamondSortBy._SHAPE,
									ascending: true
								}
							]
						},
						{
							category: "COLOR",
							options: [
								{
									label: "Z_TO_D",
									sortById: DiamondSortBy._COLOR,
									ascending: false
								},
								{
									label: "D_TO_Z",
									sortById: DiamondSortBy._COLOR,
									ascending: true
								}
							]
						},
						{
							category: "CLARITY",
							options: [
								{
									label: "FL_TO_I3",
									sortById: DiamondSortBy._CLARITY,
									ascending: true
								},
								{
									label: "I3_TO_FL",
									sortById: DiamondSortBy._CLARITY,
									ascending: false
								}
							]
						},
						{
							category: "CUT",
							options: [
								{
									label: "ID_TO_PR",
									sortById: DiamondSortBy._CUT,
									ascending: true
								},
								{
									label: "PR_TO_ID",
									sortById: DiamondSortBy._CUT,
									ascending: false
								}
							]
						},
						{
							category: "FLUORESCENCE",
							options: [
								{
									label: "VST_TO_NON",
									sortById: DiamondSortBy._FLUORESCENCE,
									ascending: false
								},
								{
									label: "NON_TO_VST",
									sortById: DiamondSortBy._FLUORESCENCE,
									ascending: true
								}
							]
						},
						{
							category: "LAB",
							options: [
								{
									label: "Z_TO_A",
									sortById: DiamondSortBy._LAB,
									ascending: false
								},
								{
									label: "A_TO_Z",
									sortById: DiamondSortBy._LAB,
									ascending: true
								}
							]
						},
						{
							category: "CERTIFICATE",
							options: [
								{
									label: "DESC",
									sortById: DiamondSortBy._CERTIFICATE,
									ascending: false
								},
								{
									label: "ASC",
									sortById: DiamondSortBy._CERTIFICATE,
									ascending: true
								}
							]
						}
					]}
				/>
			</Card>
			<DiamondDetailModal
				uploadDiamondMedia={uploadDiamondMedia}
				uploadModal={uploadModal}
				setUploadModal={setUploadModal}
				setMediaToDelete={setMediaToDelete}
				mediaToDelete={mediaToDelete}
				setUploadedMedia={setUploadedMedia}
			/>
		</Box>
	)
}