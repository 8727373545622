import { _WEB_VERSION } from './constant';
// export const _BASE_URL = 'https://localhost:5001'; // Stage
export const _BASE_URL = 'https://supplier-api.jw-diamonds.com'; // Production

const Routes = {
    _ACCOUNT_LOGIN: '/account/login',
    _USER_REGISTER: '/user/register',
    _FORGOT_PASSWORD: '/user/forgot-password',
    _CHANGE_PASSWORD: '/user/change-password',
    _ACCOUNT_VERIFY: 'account/verify',
    _ACCOUNT_LOGOUT: '/account/logout',
    _ACCOUNT_IS_LOGGED_IN: '/account/is-logged-in',
    _SUPPLIER_INFO: '/user/supplier-info',
    _INVENTORY_UPLOAD: '/inventory/upload',
    _INVENTORY_DIAMONDS_DRAFT: '/inventory/diamonds-draft',
    _INVENTORY_PENDING_APPROVAL_DIAMONDS: '/inventory/pending-approval-diamonds',
    _INVENTORY_PUBLISH_DIAMONDS_DRAFT: '/inventory/diamonds-draft/publish',
    _INVENTORY_DIAMONDS: '/inventory/diamonds',
    _DIAMOND_MEDIA_UPLOAD: '/inventory/medias',
    _INVENTORY_DIAMONDS_DRAFT_DELETE: '/inventory/diamonds-draft/delete',
    _INVENTORY_DIAMONDS_DELETE: '/inventory/diamonds/delete',
    _COMPANY_PROFILE: '/user/company-profile',
    _SUPPLIER_COMPANY_REFERENCE: '/user/company-reference',
    _USER: '/user',
    _INVENTORY_APPROVE_DIAMONDS: '/inventory/diamonds/approve',
    _INVENTORY_MARKUP_RULES: '/inventory/diamonds/markup-rule',
    _INVENTORY_ORDER: '/inventory/order',
    _INVENTORY_READY_FOR_INVOICE: 'inventory/order/ready-invoice',
    _INVENTORY_REJECT: '/inventory/order/reject',
    _INVENTORY_INVOICE: "/inventory/invoice",
    _INVENTORY_INVOICE_SHIPPED: "/inventory/invoice/shipped",
    _INVENTORY_INVOICE_RECEIVED: "/inventory/invoice/received",
    _SETTLEMENT_REPORT: "/report/settlement",
    _DASHBOARD_DATA: "/report/dashboard-data",
    _SALES_REPORT: "/report/sales-report",
    _GENERATE_PURCHASE_ORDER: "/report/settlement/{id}/generate-purchase-order"
};

export default Routes;
