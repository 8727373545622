import React, { useEffect, useState } from "react";
import { useRouter } from "../../../common/useRouter";
import { useTranslation } from "react-i18next";
import {
	Box,
	Badge,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	IconButton,
	Text
} from "@chakra-ui/react";
import { FaGear, FaPen } from "react-icons/fa6";
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../../common/api_routes";
import CustomTable from "../../../components/custom-table";
import { WebUrl } from "../../../routes";
import { Role } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
	var _dispatch = useDispatch();
	var _router = useRouter();
	const { t } = useTranslation();
	const _USER_DATA = useSelector((state) => state.authState.userData);
	const [apiUrl, setApiUrl] = useState();

	const _COLUMNS = [
        {
			Header: "REQUEST_NO",
            accessor: ""
		},
		{
			Header: "DIAMOND_DETAILS",
			accessor: ""
		},
        {
			Header: "HOLD_TIME",
			accessor: ""
		},
        {
			Header: "STATUS",
			accessor: ""
		},
	];

	return (
		<Box pt={{ base: "80px", md: "60px", xl: "60px" }}>
			<Card
				justifyContent='center'
				align='center'
				direction='column'
				w='100%'
				mb='0px'>
				<CustomTable
					columnsData={_COLUMNS}
					apiUrl={apiUrl} />
			</Card>
		</Box>
	)
}