import React, { useEffect, useState } from "react";
import { useRouter } from "../../../common/useRouter";
import { useTranslation } from "react-i18next";
import {
    Box,
    Flex,
    Button,
    Text,
    IconButton,
    Image,
    Tooltip,
    Link
} from "@chakra-ui/react";
import { MdDiamond, MdCheckCircleOutline } from "react-icons/md";
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../../common/api_routes";
import CustomTable from "../../../components/custom-table";
import { WebUrl } from "../../../routes";
import {
    Role,
    _DIAMOND_SHAPE_OPTIONS,
    _DIAMOND_CLARITY_OPTIONS,
    _DIAMOND_COLOR_OPTIONS,
    _DIAMOND_FINISH_LEVEL_OPTIONS,
    _DIAMOND_FLUORESCENSE_OPTIONS,
    _DIAMOND_LAB_OPTIONS,
    _DIAMOND_LOCATION_OPTIONS,
    _DIAMOND_TINGE_SHADE_OPTIONS,
    _DIAMOND_TINGE_MILKY_OPTIONS,
    _DIAMOND_TINGE_EYE_CLEAN_OPTIONS,
    _DIAMOND_TREATMENT_OPTIONS,
    _DIAMOND_TYPE_OPTION,
    ApiKey,
    CommonStatus,
    DiamondStockStatus,
    _DIAMOND_STOCK_STATUS_OPTIONS
} from "../../../common/constant";
import moment from "moment";
import ApiEngine from "../../../common/api-engine";
import { createMultiPartFormBody, stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat, getLabCertificateTrackUrl, renderDiamondTypeBadge } from "../../../common/util";
import { showCustomDialog } from "../../../application/action/app_action";
import CustomMediaUploader from "components/custom-media-uploader";
import { RiDeleteBin6Line } from "react-icons/ri";
import DiamondDetailModal from "../../../components/diamond-detail-modal";
import DiamondDetail from "../../../components/diamond-detail";
import DiamondDetailGrid from "../../../components/diamond-detail-grid";

/// <summary>
/// Author: CK
/// </summary>
export default () => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    const { t } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const [apiUrl, setApiUrl] = useState();
    const [selectedDiamonds, setSelectedDiamonds] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [allDiamonds, setAllDiamonds] = useState([]);
    const [uploadModal, setUploadModal] = useState({});
    const [uploadedMedia, setUploadedMedia] = useState([]);
    const [mediaToDelete, setMediaToDelete] = useState([]);
    const [postData, setPostData] = useState({});

    const _COLUMNS = [
        {
            id: 'checkbox',
            headerWidth: '1%',
            customHeader: (
                <div className="checkbox checkbox-css checkbox-inline inbox-checkbox">
                    <input id="checkboxSelectAll" type="checkbox" onChange={() => setCheckAll(!checkAll)} checked={checkAll} />
                    <label htmlFor="checkboxSelectAll"></label>
                </div>
            ),
            Cell: ({ row }) => {
                return (
                    <div className="checkbox checkbox-css checkbox-inline inbox-checkbox">
                        <input
                            id={`checkbox${row.id}`}
                            type="checkbox"
                            onChange={() => updateSelectedDiamonds(row.original.id)}
                            checked={selectedDiamonds.find(t => t == row.original.id)} />
                        <label htmlFor={`checkbox${row.id}`}></label>
                    </div>
                )
            },
            width: 30,
            disableSortBy: true,
        },
        {
            Header: "TYPE",
            accessor: "typeId",
            Cell: ({ row }) => {
                return <Text>{t(_DIAMOND_TYPE_OPTION.find(i => i.id == row.original.typeId)?.label)}</Text>
            }
        },
        {
            Header: "SUPPLIER",
            accessor: "supplierName"
        },
        {
			Header: "STOCK_NO",
			accessor: "stockNo",
			Cell: ({ row }) => {
				return <Flex alignItems={'center'}>
					{renderDiamondTypeBadge(row.original.typeId)}
					<Text ml="3px">{row.original.stockNo}</Text>
				</Flex>
			}
		},
        {
            Header: "DETAIL",
            Cell: ({ row }) => {
                return <Tooltip label={t("DETAIL")}><IconButton style={{ padding: '10px'}} bgColor={"brand.800"} color={'white'} icon={<MdDiamond size={'16px'} />} onClick={() => setUploadModal(row.original)} /></Tooltip>
            }
        },
        // {
        //     Header: "STATUS",
        //     accessor: "status",
        //     Cell: ({ row }) => {
        //         let status = row.original.status;
        //         let statusLabel = t(_DIAMOND_STOCK_STATUS_OPTIONS.find(i => i.id == row.original.status)?.label);
        //         let badgeColorScheme = 'blue';

        //         if (status == DiamondStockStatus._ON_HOLD) {
        //             badgeColorScheme = 'orange';
        //         }
        //         else if (status == DiamondStockStatus._PENDING_APPROVAL) {
        //             badgeColorScheme = 'orange';
        //         }
        //         else if (status == DiamondStockStatus._ORDERED
        //             || DiamondStockStatus._PAYMENT_MADE
        //             || DiamondStockStatus._QUALITY_CHECK
        //             || DiamondStockStatus._PREPARE_DELIVERY
        //             || DiamondStockStatus._SHIPED
        //             || DiamondStockStatus._RECEIVED
        //         ) {
        //             badgeColorScheme = 'green';
        //         }

        //         return <Badge variant='subtle' colorScheme={badgeColorScheme}>{statusLabel}</Badge>
        //     }
        // },
        // {
        //     Header: "UPLOADED_DATE_TIME",
        //     accessor: "createdAt",
        //     Cell: ({ row }) => {
        //         return <Text>{moment(row.original.createdAt).format('YYYY-MM-DD h:mm:ss A')}</Text>
        //     }
        // },
        {
            Header: "SHAPE",
            accessor: "shapeId",
            Cell: ({ row }) => {
                return <Tooltip label={t(_DIAMOND_SHAPE_OPTIONS.find(i => i.id == row.original.shapeId)?.label)}><Image className="diamond-shape-img" src={_DIAMOND_SHAPE_OPTIONS.find(i => i.id == row.original.shapeId)?.image} /></Tooltip>
            }
        },
        {
			Header: "CARAT",
			accessor: "carat",
			Cell: ({ row }) => {
				return <Text>{numberWithCurrencyFormat(row.original.carat, 2, true)}</Text>
			}
		},
        {
            Header: "COLOR",
            accessor: "colorId",
            Cell: ({ row }) => {
                return <Text>{t(_DIAMOND_COLOR_OPTIONS.find(i => i.id == row.original.colorId)?.label)}</Text>
            }
        },
        {
            Header: "CLARITY",
            accessor: "clarityId",
            Cell: ({ row }) => {
                return <Text>{t(_DIAMOND_CLARITY_OPTIONS.find(i => i.id == row.original.clarityId)?.label)}</Text>
            }
        },
        {
            Header: "CUT",
            accessor: "cutFinishLevelId",
            Cell: ({ row }) => {
                return <Text>{t(_DIAMOND_FINISH_LEVEL_OPTIONS.find(i => i.id == row.original.cutFinishLevelId)?.label)}</Text>
            }
        },
        {
            Header: "POLISH",
            accessor: "polishFinishLevelId",
            Cell: ({ row }) => {
                return <Text>{t(_DIAMOND_FINISH_LEVEL_OPTIONS.find(i => i.id == row.original.polishFinishLevelId)?.label)}</Text>
            }
        },
        {
            Header: "SYMMETRY",
            accessor: "symmetryFinishLevelId",
            Cell: ({ row }) => {
                return <Text>{t(_DIAMOND_FINISH_LEVEL_OPTIONS.find(i => i.id == row.original.symmetryFinishLevelId)?.label)}</Text>
            }
        },
        {
            Header: "FLUO",
            accessor: "fluorescenceId",
            Cell: ({ row }) => {
                return <Text>{t(_DIAMOND_FLUORESCENSE_OPTIONS.find(i => i.id == row.original.fluorescenceId)?.label)}</Text>
            }
        },
        {
            Header: "LAB",
            accessor: "labId",
            Cell: ({ row }) => {
                return <Text>{t(_DIAMOND_LAB_OPTIONS.find(i => i.id == row.original.labId)?.label)}</Text>
            }
        },
        {
            Header: "CERTIFICATE",
            accessor: "certificateId",
			Cell: ({ row }) => {
				return <Link
					fontSize={'13px'}
					color='gold.600'
					href={getLabCertificateTrackUrl(row.original.labId, row.original.certificateId)} isExternal>
					{row.original.certificateId}
				</Link>
			}
        },
        {
            Header: "PRICE_PER_CARAT",
            accessor: "pricePerCarat",
            Cell: ({ row }) => {
                return <Text>${numberWithCurrencyFormat(_USER_DATA?.user?.roleId == Role._SUPPLIER ? row.original.pricePerCarat : row.original.markupPricePerCarat)}</Text>
            }
        },
        {
            Header: "TOTAL",
            Cell: ({ row }) => {
                return <Text>${numberWithCurrencyFormat(_USER_DATA?.user?.roleId == Role._SUPPLIER ? row.original.price : row.original.totalPrice)}</Text>
            }
        }
    ];

    /// <summary>
    /// Author: CK
    /// Edited: Saitama
    /// </summary> 
    useEffect(() => {
        (async () => {
            let currentPostData = {};

            if (_USER_DATA?.user?.roleId == Role._SUPPLIER) {
                currentPostData['supplierId'] = _USER_DATA?.user?.id;
            }

            currentPostData['status'] = DiamondStockStatus._PENDING_APPROVAL;

            setPostData(currentPostData);
            setApiUrl(Routes._INVENTORY_DIAMONDS);
        })();
    }, []);

    /// <summary>
    /// Author: CK
    /// </summary> 
    useEffect(() => {
        let diamonds = [];

        if (checkAll && allDiamonds.length > 0) {
            diamonds = allDiamonds.map(selected => selected.id);
        }

        setSelectedDiamonds(diamonds);
    }, [checkAll]);

    /// <summary>
    /// Author: CK
    /// </summary> 
    function updateSelectedDiamonds(diamondId) {
        if (selectedDiamonds.filter(diamond => diamond == diamondId).length) {
            setSelectedDiamonds(selectedDiamonds.filter(diamond => diamond != diamondId));
        }
        else {
            setSelectedDiamonds([...selectedDiamonds, diamondId]);
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const approveDiamonds = async () => {
        try {
            if (selectedDiamonds.length <= 0) {
                throw "Please select diamond to approve.";
            }

            let params = {
                selectedDiamonds: selectedDiamonds
            };

            var responseJson = await ApiEngine.post(Routes._INVENTORY_APPROVE_DIAMONDS, params);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                _dispatch(
                    showCustomDialog({
                        success: true,
                        content: responseJson[ApiKey._API_MESSAGE_KEY],
                    })
                );
                setApiUrl(Routes._INVENTORY_DIAMONDS + '?v=' + moment());
                setSelectedDiamonds([]);
                setCheckAll(false);
            }
            else {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
    };

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const uploadDiamondMedia = async () => {
        try {
            if (uploadedMedia.length <= 0 && mediaToDelete.length == 0) {
                throw "Please upload media(s).";
            }

            let params = {
                diamondId: uploadModal.id
            };

            if (uploadedMedia.length > 0) {
                params["files"] = uploadedMedia;
            }

            if (mediaToDelete.length > 0) {
                params['removeIds'] = mediaToDelete.join(',');
            }
            var responseJson = await ApiEngine.put(Routes._DIAMOND_MEDIA_UPLOAD, createMultiPartFormBody(params), {
                headers: {
                    'content-type': ApiKey._API_MULTIPART_FORM
                }
            });

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                _dispatch(
                    showCustomDialog({
                        success: true,
                        content: responseJson[ApiKey._API_MESSAGE_KEY],
                        onConfirm: () => {
                            setApiUrl(Routes._INVENTORY_DIAMONDS + '?v=' + moment());
                            setUploadModal({});
                            setUploadedMedia([]);
                            setMediaToDelete([]);
                        }
                    })
                );
            }
            else {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
    };

    /// <summary>
    /// Author: CK
    /// </summary>
    const deleteDiamond = async (diamondId) => {
        try {
            var responseJson = await ApiEngine.delete(`${Routes._INVENTORY_DIAMONDS_DRAFT}/${diamondId}`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setApiUrl(`${apiUrl}&v=${new Date()}`);
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err
                })
            );
        }
    };

    return (
        <Box pt={{ base: "80px", md: "60px", xl: "60px" }}>
            <Card
                justifyContent='center'
                align='center'
                direction='column'
                w='100%'
                mb='0px'>
                <Flex justifyContent={'flex-end'}>
                    <Button
                        fontSize="sm"
                        variant="brand"
                        fontWeight="500"
                        type="button"
                        leftIcon={<MdCheckCircleOutline />} 
                        onClick={() => approveDiamonds()}
                    >
                        {t('APPROVE')}
                    </Button>
                </Flex>
                <CustomTable
                    columnsData={_COLUMNS}
                    apiUrl={apiUrl}
                    objFilter={postData}
                    requestMethod={ApiKey._API_POST}
                    customFetchAction={(responseJson) => setAllDiamonds(responseJson[ApiKey._API_DATA_KEY])}
                    RowAccordionContent={DiamondDetail}
                    GridViewItem={DiamondDetailGrid}
                />
            </Card>
            <DiamondDetailModal
                uploadDiamondMedia={uploadDiamondMedia}
                uploadModal={uploadModal}
                setUploadModal={setUploadModal}
                setMediaToDelete={setMediaToDelete}
                mediaToDelete={mediaToDelete}
                setUploadedMedia={setUploadedMedia}
            />
        </Box>
    )
}