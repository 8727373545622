import React, { useEffect, useState } from "react";
import { useRouter } from "../../../common/useRouter";
import { useTranslation } from "react-i18next";
import {
    Box,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    Image,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Link,
    Button,
    HStack,
    Badge
} from "@chakra-ui/react";
import { FaGear, FaMagnifyingGlass, FaCheckDouble, FaEye } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../../common/api_routes";
import CustomTable from "../../../components/custom-table";
import { WebUrl } from "../../../routes";
import { Role, ApiKey, JewellerKycStatus } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";
import { isObjectEmpty, stringIsNullOrEmpty } from "../../../common/util";
import ApiEngine from "../../../common/api-engine";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    const { t } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const [apiUrl, setApiUrl] = useState(`${Routes._USER}?roleId=${Role._JEWELLER}&CompanyProfileApproved=false`);
    const [detailsModal, setDetailsModal] = useState({});
    const _COLUMNS = [
        {
            Header: "COMPANY_NAME",
            Cell: ({ row }) => {
                return <Text fontWeight={700}>{row.original.jewellerProfile.companyName}</Text>
            }
        },
        {
            Header: "EMAIL",
            accessor: "email"
        },
        {
            Header: "NAME",
            Cell: ({ row }) => {
                return <Text fontWeight={700}>{`${row.original.firstName} ${row.original.lastName}`}</Text>
            }
        },
        {
            Header: "TELEPHONE",
            accessor: "phoneNumber"
        },
        {
            Header: "KYC_STATUS",
            Cell: ({ row }) => {
                return <Badge colorScheme={row.original?.companyProfile?.kycApproved == JewellerKycStatus._APPROVED ? 'green' : 'orange'}>
                    {t(row.original?.companyProfile?.kycApproved == JewellerKycStatus._APPROVED ? 'VERIFIED' : 'NOT_VERIFIED')}
                </Badge>
            }
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => {
                return <Menu>
                    <MenuButton as={IconButton} icon={<FaGear />} variant={'brand'} />
                    <MenuList>
                        <MenuItem icon={<FaMagnifyingGlass />} onClick={() => { setDetailsModal(row.original) }}>{t('VIEW')}</MenuItem>
                        <MenuItem icon={<FaEdit />} onClick={() => { _router.navigate(WebUrl._JEWELLER_PROFILE_SETTINGS, { jeweller: row.original }) }}>{t('EDIT')}</MenuItem>
                        <MenuItem icon={<FaCheckDouble />} onClick={() => {
                            _dispatch(showCustomDialog({
                                title: t('ARE_YOU_SURE_YOU'),
                                content: t('ARE_YOU_SURE_YOU_WANT_TO', { action: t('APPROVE'), user: row.original.jewellerProfile.companyName }),
                                onCancel: () => { },
                                onConfirm: () => {
                                    approveCompanyProfile(row.original.id);
                                }
                            }));
                        }}>{t('APPROVE')}</MenuItem>
                    </MenuList>
                </Menu>
            }
        }
    ];

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function approveCompanyProfile(id) {
        try {
            var responseJson = await ApiEngine.put(`${Routes._COMPANY_PROFILE}/${id}/approve`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            _dispatch(
                showCustomDialog({
                    success: true,
                    content: responseJson[ApiKey._API_MESSAGE_KEY],
                    onConfirm: () => {
                        _router.navigate(WebUrl._JEWELLER_LIST);
                    }
                })
            );
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function approveKYC(id) {
        try {
            var responseJson = await ApiEngine.put(`${Routes._USER}/${id}/kyc-decision`, {
                "kycApproved": true
            });

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setDetailsModal({
                ...detailsModal,
                requiredRefreshOnClose: true,
                companyProfile: {
                    ...detailsModal.companyProfile,
                    kycApproved: true
                }
            });
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
    }

    return (
        <Box pt={{ base: "80px", md: "60px", xl: "60px" }}>
            <Card
                justifyContent='center'
                align='center'
                direction='column'
                w='100%'
                mb='0px'>
                <CustomTable
                    columnsData={_COLUMNS}
                    apiUrl={apiUrl} />
            </Card>
            <Modal size={'5xl'} isOpen={!isObjectEmpty(detailsModal)} onClose={() => {
                if (detailsModal?.requiredRefreshOnClose) {
                    setApiUrl(`${Routes._USER}?roleId=${Role._JEWELLER}&CompanyProfileApproved=false&v=${new Date()}`);
                }

                setDetailsModal({})
            }}>
                <ModalOverlay />
                <ModalContent
                    containerProps={{
                        zIndex: '99999',
                    }} >
                    <ModalHeader>{detailsModal?.jewellerProfile?.companyName}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <TableContainer className="supplier-detail-table">
                            <Table variant='simple' textAlign={'center'}>
                                <Thead>
                                    <Tr>
                                        <Th colSpan={2} fontWeight={'900'} fontSize={'larger'}>{t('ACCOUNT_INFO')}</Th>
                                        <Th colSpan={2} />
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('PROFILE_PICTURE')}</Td>
                                        <Td>
                                            <HStack>
                                                <Image
                                                    boxSize={'50px'}
                                                    objectFit={'cover'}
                                                    src={!stringIsNullOrEmpty(detailsModal?.profilePicture) ? detailsModal?.profilePicture : require('../../../assets/img/no_image.png')} />
                                                <IconButton
                                                    colorScheme={'brand'}
                                                    variant={'brand'}
                                                    onClick={() => window.open(detailsModal?.profilePicture, "_blank")}
                                                    isDisabled={stringIsNullOrEmpty(detailsModal?.profilePicture)}
                                                    icon={<FaEye h={3} w={3} />}
                                                    size={'sm'}
                                                    ml={4}
                                                />
                                            </HStack>
                                        </Td>
                                        <Td />
                                        <Td />
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('EMAIL')}</Td>
                                        <Td>{detailsModal?.username}</Td>
                                        <Td />
                                        <Td />
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('NAME')}</Td>
                                        <Td>{`${detailsModal?.firstName} ${detailsModal?.lastName}`}</Td>
                                        <Td />
                                        <Td />
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('TELEPHONE')}</Td>
                                        <Td>{detailsModal?.phoneNumber}</Td>
                                        <Td />
                                        <Td />
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('WORK_PROFILE')}</Td>
                                        <Td>{detailsModal?.workProfile}</Td>
                                        <Td />
                                        <Td />
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('KYC_STATUS')}</Td>
                                        <Td>
                                            <Badge colorScheme={detailsModal?.companyProfile?.kycApproved == JewellerKycStatus._APPROVED ? 'green' : 'orange'}>
                                                {t(detailsModal?.companyProfile?.kycApproved == JewellerKycStatus._APPROVED ? 'VERIFIED' : 'NOT_VERIFIED')}
                                            </Badge>
                                        </Td>
                                        <Td>
                                            {
                                                detailsModal?.companyProfile?.kycApproved != JewellerKycStatus._APPROVED &&
                                                <Button
                                                    colorScheme={'green'}
                                                    size={'sm'}
                                                    onClick={() => {
                                                        _dispatch(showCustomDialog({
                                                            title: t('ARE_YOU_SURE_YOU'),
                                                            content: t('ARE_YOU_SURE_YOU_WANT_TO', { action: t('APPROVE_KYC'), user: detailsModal.jewellerProfile.companyName }),
                                                            onCancel: () => { },
                                                            onConfirm: () => {
                                                                approveKYC(detailsModal.id);
                                                            }
                                                        }));
                                                    }}>
                                                    {t('APPROVE_KYC')}
                                                </Button>
                                            }
                                        </Td>
                                        <Td />
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <TableContainer className="supplier-detail-table">
                            <Table variant='simple' textAlign={'center'}>
                                <Thead>
                                    <Tr>
                                        <Th colSpan={2} fontWeight={'900'} fontSize={'larger'}>{t('COMPANY_PROFILE')}</Th>
                                        <Th colSpan={2} />
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('BUSINESS_REG_NUMBER')}</Td>
                                        <Td>
                                            {
                                                !stringIsNullOrEmpty(detailsModal?.companyProfile?.companyRegistrationDocument) ?
                                                    <Link
                                                        href={detailsModal?.companyProfile?.companyRegistrationDocument} isExternal>
                                                        {detailsModal?.companyProfile?.companyRegistrationNumber} <ExternalLinkIcon mx='2px' />
                                                    </Link> :
                                                    <Text>{detailsModal?.companyProfile?.companyRegistrationNumber}</Text>
                                            }
                                        </Td>
                                        <Td fontWeight={'800'}>{t('NATURE_OF_BUSINESS')}</Td>
                                        <Td>{t(detailsModal?.companyProfile?.natureOfBusiness?.toUpperCase())}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('COMPANY_WEBSITE')}</Td>
                                        <Td>
                                            {
                                                !stringIsNullOrEmpty(detailsModal?.companyProfile?.companyWebsite) ?
                                                    <Link
                                                        href={detailsModal?.companyProfile?.companyWebsite?.startsWith('http') ?
                                                            detailsModal?.companyProfile?.companyWebsite :
                                                            `http://${detailsModal?.companyProfile?.companyWebsite}`} isExternal>
                                                        {detailsModal?.companyProfile?.companyWebsite} <ExternalLinkIcon mx='2px' />
                                                    </Link> :
                                                    <Text>-</Text>
                                            }
                                        </Td>
                                        <Td fontWeight={'800'}>{t('COMPANY_EMAIL')}</Td>
                                        <Td>{detailsModal?.companyProfile?.companyEmail}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('COMPANY_TELEPHONE')}</Td>
                                        <Td>{detailsModal?.companyProfile?.companyTelephone}</Td>
                                        <Td fontWeight={'800'}>{t('ADDRESS')}</Td>
                                        <Td>{detailsModal?.companyProfile?.address}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('CITY')}</Td>
                                        <Td>{detailsModal?.companyProfile?.city}</Td>
                                        <Td fontWeight={'800'}>{t('ZIPCODE')}</Td>
                                        <Td>{detailsModal?.companyProfile?.zipCode}</Td>

                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('STATE')}</Td>
                                        <Td>{detailsModal?.companyProfile?.state}</Td>
                                        <Td fontWeight={'800'}>{t('COUNTRY')}</Td>
                                        <Td>{detailsModal?.companyProfile?.country}</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <TableContainer className="supplier-detail-table">
                            <Table variant='simple' textAlign={'center'}>
                                <Thead>
                                    <Tr>
                                        <Th colSpan={2} fontWeight={'900'} fontSize={'larger'}>{t('SHIPPING_INFO')}</Th>
                                        <Th colSpan={2} />
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('SHIPPING_ADDRESS')}</Td>
                                        <Td>{detailsModal?.shippingInfo?.address}</Td>
                                        <Td fontWeight={'800'}>{t('SHIPPING_ZIPCODE')}</Td>
                                        <Td>{detailsModal?.shippingInfo?.zipCode}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('SHIPPING_CITY')}</Td>
                                        <Td>{detailsModal?.shippingInfo?.city}</Td>
                                        <Td fontWeight={'800'}>{t('SHIPPING_STATE')}</Td>
                                        <Td>{detailsModal?.shippingInfo?.state}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('SHIPPING_COUNTRY')}</Td>
                                        <Td>{detailsModal?.shippingInfo?.country}</Td>
                                        <Td />
                                        <Td />
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('SHIPPING_TELEPHONE')}</Td>
                                        <Td>{detailsModal?.shippingInfo?.phoneNumber}</Td>
                                        <Td fontWeight={'800'}>{t('SHIPPING_EMAIL')}</Td>
                                        <Td>{detailsModal?.shippingInfo?.email}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('COMPANY_TAX_ID')}</Td>
                                        <Td>{detailsModal?.shippingInfo?.companyTaxId}</Td>
                                        <Td fontWeight={'800'}>{t('PORT_OF_DISCHARGE')}</Td>
                                        <Td>{detailsModal?.shippingInfo?.portOfDischarge}</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='red' onClick={() => {
                            if (detailsModal?.requiredRefreshOnClose) {
                                setApiUrl(`${Routes._USER}?roleId=${Role._JEWELLER}&CompanyProfileApproved=false&v=${new Date()}`);
                            }

                            setDetailsModal({});
                        }}>
                            {t('CLOSE')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    )
}