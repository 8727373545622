import React, { useEffect, useState } from "react";
import { useRouter } from "../../../common/useRouter";
import { useTranslation } from "react-i18next";
import {
	Box,
	Badge,
	Flex,
	Button,
	Text,
	Image,
	Link
} from "@chakra-ui/react";
import { BsFileEarmarkExcel, BsFileEarmarkCheck } from "react-icons/bs";
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../../common/api_routes";
import CustomTable from "../../../components/custom-table";
import DiamondDetail from "../../../components/diamond-detail";
import { WebUrl } from "../../../routes";
import { Role, ApiKey, OrderStatus, _DIAMOND_LAB_OPTIONS, _DIAMOND_SHAPE_OPTIONS } from "../../../common/constant";
import { showCustomDialog, showCustomInputDialog } from "../../../application/action/app_action";
import { numberWithCurrencyFormat, renderDiamondTypeBadge, getLabCertificateTrackUrl } from "../../../common/util";
import ApiEngine from "../../../common/api-engine";
import ConfirmDiamondModal from "../../../components/confirm-diamond-modal";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
	var _dispatch = useDispatch();
	var _router = useRouter();
	const { t } = useTranslation();
	const _USER_DATA = useSelector((state) => state.authState.userData);
	const [apiUrl, setApiUrl] = useState(`${Routes._INVENTORY_ORDER}?status=${OrderStatus._PENDING}`);
	const [checkAll, setCheckAll] = useState(false);
	const [allDiamonds, setAllDiamonds] = useState([]);
	const [selectedDiamonds, setSelectedDiamonds] = useState([]);
	const [confirmDiamondModal, setConfirmDiamondModal] = useState(false);

	const _COLUMNS = [
		{
			id: 'checkbox',
			headerWidth: '1%',
			customHeader: (
				<div className="checkbox checkbox-css checkbox-inline inbox-checkbox">
					<input id="checkboxSelectAll" type="checkbox" onChange={() => setCheckAll(!checkAll)} checked={checkAll} />
					<label htmlFor="checkboxSelectAll"></label>
				</div>
			),
			Cell: ({ row }) => {
				return (
					<div className="checkbox checkbox-css checkbox-inline inbox-checkbox">
						<input
							id={`checkbox${row.id}`}
							type="checkbox"
							onChange={() => updateSelectedDiamonds(row.original)}
							checked={selectedDiamonds.find(t => t.id == row.original.id)}
							 />
						<label htmlFor={`checkbox${row.id}`}></label>
					</div>
				)
			},
			width: 30,
			disableSortBy: true,
		},
		{
			Header: "ORDER_NO",
			accessor: "orderNo"
		},
		{
			Header: "JEWELLER",
			Cell: ({ row }) => {
				return <Text>{`${row.original.jewellerCompanyName} (${row.original.jewellerEmail})`}</Text>
			}
		},
		{
			Header: "DIAMOND_DETAILS",
			accessor: "label",
			Cell: ({ row }) => {
				let diamondImg = row.original.diamond.diamondMedias.find(item => item.mediaType === 'Image');

				return <Flex flexDirection={'row'} alignItems={'center'}>
					{
						diamondImg ?
							<Image className="table-diamond-img" src={diamondImg.mediaUrl} />
							: <Image className="table-diamond-img" src={_DIAMOND_SHAPE_OPTIONS.find(i => i.id == row.original.diamond.shapeId)?.defaultImg} />
					}
					<Flex ml='10px' flexDir={'column'} alignItem='flex-start'>
						<Flex alignItems={'center'}>
							<Flex alignItems={'center'} mr='5px'>
								{renderDiamondTypeBadge(row.original.diamond.typeId)}
								<Text
									ml='3px'
									fontSize={'13px'}
								>
									{row.original.diamond.stockNo}
								</Text>
							</Flex>
							<Link
								fontSize={'13px'}
								color='gold.600'
								href={getLabCertificateTrackUrl(row.original.diamond.labId, row.original.diamond.certificateId)} isExternal>
								{t(_DIAMOND_LAB_OPTIONS.find(i => i.id == row.original.diamond.labId)?.label)}-{row.original.diamond.certificateId}
							</Link>
						</Flex>
						<Text fontWeight={'bold'} fontSize={'16px'}>{row.original.diamond.label}</Text>
						{
							row.original.diamond.inWarehouse && 
							<Badge variant='outline' colorScheme={'purple'} maxW={'110px'} borderRadius={0}>{t('IN_WAREHOUSE')}</Badge>
						}
					</Flex>
				</Flex>
			}
		},
		{
			Header: "STATUS",
			Cell: ({ row }) => {
				return <Badge variant='subtle' colorScheme={'orange'}>{t('PENDING')}</Badge>
			}
		},
		{
			Header: "TOTAL_PRICE",
			headerClassName: "col-align-right",
			Cell: ({ row }) => {
				return <Flex flexDir="column" alignItems={'flex-end'}>
					<Text fontWeight={700} fontSize={'16px'}>${numberWithCurrencyFormat(row.original.totalPrice)}</Text>
					<Text color="secondaryGray.500" fontWeight={500} fontSize={'12px'}>$/CT {numberWithCurrencyFormat(parseFloat(row.original.pricePerCarat))}</Text>
				</Flex>
			}
		}
	];

	/// <summary>
    /// Author: CK
    /// </summary> 
    useEffect(() => {
        let diamonds = [];

        if (checkAll && allDiamonds.length > 0) {
            diamonds = allDiamonds.map(selected => selected);
        }

        setSelectedDiamonds(diamonds);
    }, [checkAll]);

    /// <summary>
    /// Author: CK
    /// </summary> 
    function updateSelectedDiamonds(diamond) {
        if (selectedDiamonds.filter(i => i.id == diamond.id).length) {
            setSelectedDiamonds(selectedDiamonds.filter(i => i.id != diamond.id));
        }
        else {
            setSelectedDiamonds([...selectedDiamonds, diamond]);
        }
    }

	/// <summary>
	/// Author: Saitama
	/// </summary> 
	function resetData(triggerApiCall = false) {
		setSelectedDiamonds([]);
		setCheckAll(false);

		if (triggerApiCall) {
			setApiUrl(`${Routes._INVENTORY_ORDER}?status=${OrderStatus._PENDING}&v=${new Date()}`);
		}
	}

	/// <summary>
	/// Author: Saitama
	/// </summary> 
	async function rejectOrder(remark = '') {
		try {
			let params = {
				orderIds: selectedDiamonds.map(d => d.id).join(','), 
				remark: remark
			}
			var responseJson = await ApiEngine.post(Routes._INVENTORY_REJECT, params);

			if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}

			_dispatch(
				showCustomDialog({
					success: true,
					content: responseJson[ApiKey._API_MESSAGE_KEY],
					onConfirm: () => resetData(true),
				})
			);
		}
		catch (err) {
			_dispatch(
				showCustomDialog({
					success: false,
					content: err
				})
			);
		}
	}

	/// <summary>
	/// Author: Saitama
	/// </summary> 
	async function readyForInvoice(params) {
		try {
			var responseJson = await ApiEngine.put(Routes._INVENTORY_READY_FOR_INVOICE, params);

			if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}

			_dispatch(
				showCustomDialog({
					success: true,
					content: responseJson[ApiKey._API_MESSAGE_KEY],
					confirmTxt: t('BRING_ME_TO_READY_FOR_INVOICE'),
					onConfirm: () => _router.navigate(WebUrl._ORDER_READY_FOR_INVOICE_LIST),
					cancelTxt: t('STAY'),
					onCancel: () => resetData(true)
				})
			);
		}
		catch (err) {
			_dispatch(
				showCustomDialog({
					success: false,
					content: err
				})
			);
		}
	}

	return (
		<Box pt={{ base: "80px", md: "60px", xl: "60px" }}>
			<Card
				justifyContent='center'
				align='center'
				direction='column'
				w='100%'
				mb='0px'>
				<Flex justifyContent={'flex-end'} gap={'5px'}>
					<Button
						isDisabled={selectedDiamonds.length <= 0}
						colorScheme={'green'}
						fontSize="sm"
						fontWeight="500"
						leftIcon={<BsFileEarmarkCheck />}
						onClick={() => {
							setConfirmDiamondModal(true);
						}}
					>
						{t('READY_FOR_INVOICE')}
					</Button>
					<Button
						isDisabled={selectedDiamonds.length <= 0}
						colorScheme={'red'}
						fontSize="sm"
						fontWeight="500"
						leftIcon={<BsFileEarmarkExcel />}
						onClick={() => {
							_dispatch(showCustomInputDialog({
								success: true,
								title: t("ARE_YOU_SURE_YOU"),
								content: t("REJECT_ORDER_NO", { orderNo: selectedDiamonds.map(item => item.orderNo).join(', ') }),
								onCancel: () => { },
								onConfirm: (inputValue) => {
									rejectOrder(inputValue);
								},
								isTextArea: true,
								inputType: "text",
								defaultValue: ""
							}));
						}}
					>
						{t('REJECT')}
					</Button>
				</Flex>
				<CustomTable
					columnsData={_COLUMNS}
					apiUrl={apiUrl}
					customFetchAction={(responseJson) => setAllDiamonds(responseJson[ApiKey._API_DATA_KEY])}
					RowAccordionContent={DiamondDetail}
					RowAccordionContentItem={'diamond'} />
				<ConfirmDiamondModal
					modalVisible={confirmDiamondModal}
					setModalVisible={setConfirmDiamondModal}
					selectedDiamonds={selectedDiamonds}
					orderDiamonds={readyForInvoice}
					title="READY_FOR_INVOICE"
				/>
			</Card>
		</Box>
	)
}