import React, { useEffect, useState } from "react";
import { useRouter } from "../../../common/useRouter";
import { useTranslation } from "react-i18next";
import {
	Box,
	Button,
	Text,
	Flex,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	HStack,
	Image,
	Link
} from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../../common/api_routes";
import CustomTable from "../../../components/custom-table";
import { Role, SettlementStatus, ApiKey } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";
import { numberWithCurrencyFormat, renderSettlementStatus, isObjectEmpty, stringIsNullOrEmpty } from "../../../common/util";
import SettlementDiamondDetails from "../../../components/settlement-diamond-details";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
	var _dispatch = useDispatch();
	var _router = useRouter();
	const { t } = useTranslation();
	const _USER_DATA = useSelector((state) => state.authState.userData);
	const [apiUrl, setApiUrl] = useState(Routes._SETTLEMENT_REPORT);
	const [detailsModal, setDetailsModal] = useState({});

	const _COLUMNS = [
		{
			Header: "STATUS",
			accessor: "status",
			headerClassName: "col-align-center",
			Cell: ({ value }) => {
				return <Flex flexDir="column" alignItems={'center'}>
					{renderSettlementStatus(value)}
				</Flex>
			}
		},
		{
			Header: "SETTLEMENT_NO",
			headerClassName: "col-align-center",
			accessor: "id",
			Cell: ({ value }) => {
				return <Flex flexDir="column" alignItems={'center'}>
					<Text textAlign={'center'} fontSize={'16px'}>{value + 10000}</Text>
				</Flex>
			}
		},
		..._USER_DATA?.user?.roleId != Role._SUPPLIER ?
			[{
				Header: "SUPPLIER",
				headerClassName: "col-align-center",
				Cell: ({ row }) => {
					return <Flex flexDir="column" alignItems={'center'}>
						<Button
							fontSize={'12px'}
							className="link-btn"
							variant='link'
							onClick={() => {
								setDetailsModal(row.original.supplier);
							}}
						>
							{row.original.supplier.supplierProfile.companyName}
						</Button>
					</Flex>
				}
			}] : [],
		{
			Header: "TOTAL_PIECE",
			headerClassName: "col-align-center",
			accessor: "diamondCount",
			Cell: ({ value }) => {
				return <Flex flexDir="column" alignItems={'center'}>
					<Text textAlign={'center'} fontSize={'16px'}>{value}</Text>
				</Flex>
			}
		},
		{
			Header: "TOTAL_PRICE",
			accessor: "totalAmount",
			headerClassName: "col-align-center",
			Cell: ({ row }) => {
				return <Flex flexDir="column" alignItems={'center'}>
					<Text textAlign={'center'} fontWeight={700} fontSize={'16px'}>${numberWithCurrencyFormat(row.original.totalAmount)}</Text>
				</Flex>
			}
		},
	];

	return (
		<Box pt={{ base: "80px", md: "60px", xl: "60px" }}>
			<Card
				justifyContent='center'
				align='center'
				direction='column'
				w='100%'
				mb='0px'>
				<CustomTable
					columnsData={_COLUMNS}
					apiUrl={apiUrl}
					RowAccordionContent={SettlementDiamondDetails}
					RowAccordionContentItem={'settlementItems'} />
			</Card>
			<Modal size={'5xl'} isOpen={!isObjectEmpty(detailsModal)} onClose={() => setDetailsModal({})}>
				<ModalOverlay />
				<ModalContent
					containerProps={{
						zIndex: '99999',
					}} >
					<ModalHeader>{detailsModal?.supplierProfile?.companyName}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<TableContainer className="supplier-detail-table">
							<Table variant='simple' textAlign={'center'}>
								<Thead>
									<Tr>
										<Th colSpan={2} fontWeight={'900'} fontSize={'larger'}>{t('ACCOUNT_INFO')}</Th>
										<Th colSpan={2} />
									</Tr>
								</Thead>
								<Tbody>
									<Tr>
										<Td fontWeight={'800'}>{t('NAME')}</Td>
										<Td>{`${detailsModal?.firstName} ${detailsModal?.lastName}`}</Td>
										<Td />
										<Td />
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('TELEPHONE')}</Td>
										<Td>{detailsModal?.phoneNumber}</Td>
										<Td />
										<Td />
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('EMAIL')}</Td>
										<Td>{detailsModal?.email}</Td>
										<Td />
										<Td />
									</Tr>
								</Tbody>
							</Table>
						</TableContainer>
						<TableContainer className="supplier-detail-table">
							<Table variant='simple' textAlign={'center'}>
								<Thead>
									<Tr>
										<Th colSpan={2} fontWeight={'900'} fontSize={'larger'}>{t('COMPANY_PROFILE')}</Th>
										<Th colSpan={2} />
									</Tr>
								</Thead>
								<Tbody>
									<Tr>
										<Td fontWeight={'800'}>{t('COMPANY_LOGO')}</Td>
										<Td >
											<HStack>
												<Image
													boxSize={'50px'}
													objectFit={'cover'}
													src={!stringIsNullOrEmpty(detailsModal?.companyProfile?.companyLogo) ? detailsModal?.companyProfile?.companyLogo : require('../../../assets/img/no_image.png')} />
											</HStack>
										</Td>
										<Td />
										<Td />
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('DIRECTOR_PARTNER_NAME')}</Td>
										<Td>{detailsModal?.companyProfile?.directorName}</Td>
										<Td fontWeight={'800'}>{t('COMPANY_WEBSITE')}</Td>
										<Td>
											{
												!stringIsNullOrEmpty(detailsModal?.companyProfile?.companyWebsite) ?
													<Link
														href={detailsModal?.companyProfile?.companyWebsite?.startsWith('http') ?
															detailsModal?.companyProfile?.companyWebsite :
															`http://${detailsModal?.companyProfile?.companyWebsite}`} isExternal>
														{detailsModal?.companyProfile?.companyWebsite} <ExternalLinkIcon mx='2px' />
													</Link> :
													<Text>-</Text>
											}
										</Td>
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('COMPANY_EMAIL')}</Td>
										<Td>{detailsModal?.companyProfile?.companyEmail}</Td>
										<Td fontWeight={'800'}>{t('COMPANY_TELEPHONE')}</Td>
										<Td>{detailsModal?.companyProfile?.companyTelephone}</Td>
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('ADDRESS')}</Td>
										<Td>{detailsModal?.companyProfile?.address}</Td>
										<Td fontWeight={'800'}>{t('CITY')}</Td>
										<Td>{detailsModal?.companyProfile?.city}</Td>
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('ZIPCODE')}</Td>
										<Td>{detailsModal?.companyProfile?.zipCode}</Td>
										<Td fontWeight={'800'}>{t('STATE')}</Td>
										<Td>{detailsModal?.companyProfile?.state}</Td>
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('COUNTRY')}</Td>
										<Td>{detailsModal?.companyProfile?.country}</Td>
										<Td />
										<Td />
									</Tr>
								</Tbody>
							</Table>
						</TableContainer>
						<TableContainer className="supplier-detail-table">
							<Table variant='simple' textAlign={'center'}>
								<Thead>
									<Tr>
										<Th colSpan={2} fontWeight={'900'} fontSize={'larger'}>{t('COMPANY_REFERENCE')}</Th>
										<Th colSpan={2} />
									</Tr>
								</Thead>
								<Tbody>
									<Tr>
										<Td fontWeight={'800'}>{t('COMPANY_NAME')}</Td>
										<Td>{detailsModal?.companyReferences?.[0]?.companyName ?? '-'}</Td>
										<Td fontWeight={'800'}>{t('COMPANY_NAME')}</Td>
										<Td>{detailsModal?.companyReferences?.[1]?.companyName ?? '-'}</Td>
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('PERSON_NAME')}</Td>
										<Td>{detailsModal?.companyReferences?.[0]?.personName ?? '-'}</Td>
										<Td fontWeight={'800'}>{t('PERSON_NAME')}</Td>
										<Td>{detailsModal?.companyReferences?.[1]?.personName ?? '-'}</Td>
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('DESIGNATION')}</Td>
										<Td>{detailsModal?.companyReferences?.[0]?.designation ?? '-'}</Td>
										<Td fontWeight={'800'}>{t('DESIGNATION')}</Td>
										<Td>{detailsModal?.companyReferences?.[1]?.designation ?? '-'}</Td>
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('TELEPHONE')}</Td>
										<Td>{detailsModal?.companyReferences?.[0]?.contactNumber ?? '-'}</Td>
										<Td fontWeight={'800'}>{t('TELEPHONE')}</Td>
										<Td>{detailsModal?.companyReferences?.[1]?.contactNumber ?? '-'}</Td>
									</Tr>
									<Tr>
										<Td fontWeight={'800'}>{t('EMAIL')}</Td>
										<Td>{detailsModal?.companyReferences?.[0]?.emailId ?? '-'}</Td>
										<Td fontWeight={'800'}>{t('EMAIL')}</Td>
										<Td>{detailsModal?.companyReferences?.[1]?.emailId ?? '-'}</Td>
									</Tr>
								</Tbody>
							</Table>
						</TableContainer>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme='red' onClick={() => setDetailsModal({})}>
							{t('CLOSE')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Box>
	)
}