import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
    Box,
    SimpleGrid,
    FormLabel,
    Button
} from "@chakra-ui/react";
import { showCustomDialog } from "../../../application/action/app_action";
import Card from "../../../components/card/Card";
import CustomInput from "../../../components/custom-input";
import CustomPhoneInput from "../../../components/custom-phone-input";
import CustomSwitch from "../../../components/custom-switch";
import { ApiKey, RegexPattern, Role } from "../../../common/constant";
import ApiEngine from "../../../common/api-engine";
import Routes from "../../../common/api_routes";
import { WebUrl } from "../../../routes";
import { useRouter } from "../../../common/useRouter";
import { stringIsNullOrEmpty } from "../../../common/util";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _location = useLocation();
    const _PARAMS = _location?.state;
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const { t } = useTranslation();
    const { control, handleSubmit, errors, reset, watch, clearErrors } = useForm();

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(_PARAMS?.salesPersonId)) {
            getUser();
        }
    }, []);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function getUser () {
        try {
            var responseJson = await ApiEngine.get(`${Routes._USER}/${_PARAMS?.salesPersonId}`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            let data = responseJson[ApiKey._API_DATA_KEY];
            reset({
                username: data.username,
                title: data.salesProfile.title,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phoneNumber: data.phoneNumber,
                isActive: data.isActive 
            })
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const doPasswordMatch = (value) => {
        if (value) {
            clearErrors(["confirmPassword", "password"]);
            return true;
        }

        return false;
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const submitForm = async (data, e) => {
        try {
            var responseJson;
            let params = {
                ...data,
                roleId: Role._SALES_PERSON
            };
            
            if (!stringIsNullOrEmpty(_PARAMS?.salesPersonId)) {
                responseJson = await ApiEngine.put(`${Routes._USER}/${_PARAMS?.salesPersonId}`, params);
            }
            else {
                responseJson = await ApiEngine.post(Routes._USER, params);
            }

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            _dispatch(
                showCustomDialog({
                    success: true,
                    content: responseJson[ApiKey._API_MESSAGE_KEY],
                    onConfirm: () => {
                        _router.navigate(WebUrl._USER_LIST, true);
                    }
                })
            );
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
    };

    return (
        <Box pt={{ base: "80px", md: "60px", xl: "60px" }}>
            <Card
                justifyContent='center'
                align='center'
                direction='column'
                w='100%'
                mb='0px'>
                <form onSubmit={handleSubmit(submitForm)}>
                    <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                        <FormLabel
                            display={"flex"}
                            fontSize={"md"}
                            fontWeight={"500"}
                            alignItems={'center'}
                            mb={'20px'}
                        >
                            {t('USERNAME')}
                        </FormLabel>
                        <Controller
                            control={control}
                            name="username"
                            defaultValue={''}
                            render={({ onChange, value, name }) => (
                                <CustomInput
                                    isDisabled={!stringIsNullOrEmpty(_PARAMS?.salesPersonId)}
                                    id={name}
                                    onChange={(value) => onChange(value)}
                                    value={value}
                                    placeHolder={"ENTER_USERNAME"}
                                    errors={errors}
                                />
                            )}
                            rules={{
                                required: true,
                                message: "REQUIRED",
                            }}
                        />
                    </SimpleGrid>
                    <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                        <FormLabel
                            display={"flex"}
                            fontSize={"md"}
                            fontWeight={"500"}
                            alignItems={'center'}
                            mb={'20px'}
                        >
                            {t('PASSWORD')}
                        </FormLabel>
                        <Controller
                            control={control}
                            name="password"
                            defaultValue={''}
                            render={({ onChange, value, name }) => (
                                <CustomInput
                                    isPassword
                                    id={name}
                                    onChange={(value) => onChange(value)}
                                    value={value}
                                    placeHolder={"ENTER_PASSWORD"}
                                    errors={errors}
                                />
                            )}
                            rules={{
                                required: stringIsNullOrEmpty(_PARAMS?.salesPersonId),
                                message: "REQUIRED",
                            }}
                        />
                    </SimpleGrid>
                    <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                        <FormLabel
                            display={"flex"}
                            fontSize={"md"}
                            fontWeight={"500"}
                            alignItems={'center'}
                            mb={'20px'}
                        >
                            {t('CONFIRM_PASSWORD')}
                        </FormLabel>
                        <Controller
                            control={control}
                            name="confirmPassword"
                            defaultValue={''}
                            render={({ onChange, value, name }) => (
                                <CustomInput
                                    isPassword
                                    id={name}
                                    onChange={(value) => onChange(value)}
                                    value={value}
                                    placeHolder={"ENTER_CONFIRM_PASSWORD"}
                                    errors={errors}
                                />
                            )}
                            rules={{
                                required: stringIsNullOrEmpty(_PARAMS?.salesPersonId),
                                validate: {
                                    matchingPassword: value => doPasswordMatch(value === watch('password')) || 'PASSWORD_NOT_MATCH_MSG'
                                }
                            }}
                        />
                    </SimpleGrid>
                    <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                        <FormLabel
                            display={"flex"}
                            fontSize={"md"}
                            fontWeight={"500"}
                            alignItems={'center'}
                            mb={'20px'}
                        >
                            {t('TITLE')}
                        </FormLabel>
                        <Controller
                            control={control}
                            name="title"
                            defaultValue={''}
                            render={({ onChange, value, name }) => (
                                <CustomInput
                                    id={name}
                                    onChange={(value) => onChange(value)}
                                    value={value}
                                    placeHolder={"ENTER_TITLE"}
                                    errors={errors}
                                />
                            )}
                            rules={{
                                required: true,
                                message: "REQUIRED",
                            }}
                        />
                    </SimpleGrid>
                    <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                        <FormLabel
                            display={"flex"}
                            fontSize={"md"}
                            fontWeight={"500"}
                            alignItems={'center'}
                            mb={'20px'}
                        >
                            {t('FIRST_NAME')}
                        </FormLabel>
                        <Controller
                            control={control}
                            name="firstName"
                            defaultValue={''}
                            render={({ onChange, value, name }) => (
                                <CustomInput
                                    id={name}
                                    onChange={(value) => onChange(value)}
                                    value={value}
                                    placeHolder={"ENTER_FIRST_NAME"}
                                    errors={errors}
                                />
                            )}
                            rules={{
                                required: true,
                                message: "REQUIRED",
                            }}
                        />
                    </SimpleGrid>
                    <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                        <FormLabel
                            display={"flex"}
                            fontSize={"md"}
                            fontWeight={"500"}
                            alignItems={'center'}
                            mb={'20px'}
                        >
                            {t('LAST_NAME')}
                        </FormLabel>
                        <Controller
                            control={control}
                            name="lastName"
                            defaultValue={''}
                            render={({ onChange, value, name }) => (
                                <CustomInput
                                    id={name}
                                    onChange={(value) => onChange(value)}
                                    value={value}
                                    placeHolder={"ENTER_LAST_NAME"}
                                    errors={errors}
                                />
                            )}
                            rules={{
                                required: true,
                                message: "REQUIRED",
                            }}
                        />
                    </SimpleGrid>
                    <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                        <FormLabel
                            display={"flex"}
                            fontSize={"md"}
                            fontWeight={"500"}
                            alignItems={'center'}
                            mb={'20px'}
                        >
                            {t('EMAIL')}
                        </FormLabel>
                        <Controller
                            control={control}
                            name="email"
                            defaultValue={''}
                            render={({ onChange, value, name }) => (
                                <CustomInput
                                    isDisabled={!stringIsNullOrEmpty(_PARAMS?.salesPersonId)}
                                    id={name}
                                    onChange={(value) => onChange(value)}
                                    value={value}
                                    placeHolder={"ENTER_EMAIL"}
                                    errors={errors}
                                    patternErrorTxt={'INVALID_EMAIL'}
                                />
                            )}
                            rules={{
                                required: true,
                                message: "REQUIRED",
                                pattern: RegexPattern._EMAIL_VALIDATION
                            }}
                        />
                    </SimpleGrid>
                    <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                        <FormLabel
                            display={"flex"}
                            fontSize={"md"}
                            fontWeight={"500"}
                            alignItems={'center'}
                            mb={'20px'}
                        >
                            {t('TELEPHONE')}
                        </FormLabel>
                        <Controller
                            control={control}
                            name="phoneNumber"
                            defaultValue={''}
                            render={({ onChange, value, name }) => (
                                <CustomPhoneInput
                                    id={name}
                                    onChange={(value) => onChange(value)}
                                    value={value}
                                    errors={errors} />
                            )}
                            rules={{
                                required: "REQUIRED"
                            }}
                        />
                    </SimpleGrid>
                    <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                        <FormLabel
                            display={"flex"}
                            fontSize={"md"}
                            fontWeight={"500"}
                            alignItems={'center'}
                            mb={'20px'}
                        >
                            {t('IS_ACTIVE')}
                        </FormLabel>
                        <Controller
                            control={control}
                            name="isActive"
                            defaultValue={false}
                            render={({ onChange, value, name }) => (
                                <CustomSwitch
                                    id={name}
                                    onChange={(value) => onChange(value)}
                                    value={value} />
                            )}
                        />
                    </SimpleGrid>
                    <Button
                        fontSize="sm"
                        variant="brand"
                        fontWeight="500"
                        w="50%"
                        h="50"
                        type="submit">
                        {t('SUBMIT')}
                    </Button>
                </form>
            </Card>
        </Box>
    );
}
