import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Text,
    SimpleGrid,
    Badge
} from "@chakra-ui/react";
import { showCustomDialog } from "../../../../application/action/app_action";
import { ApiKey, JewellerKycStatus } from "../../../../common/constant";
import ApiEngine from "../../../../common/api-engine";
import Routes from "../../../../common/api_routes";
import CustomInput from "../../../../components/custom-input";
import CustomPhoneInput from "../../../../components/custom-phone-input";
import CustomMediaUploader from "../../../../components/custom-media-uploader";
import { createMultiPartFormBody } from "../../../../common/util";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ onSave, onCancel, jewellerDetail }) => {
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    var _dispatch = useDispatch();
    const [profilePicture, setProfilePicture] = useState();
    const [previewProfilePicture, setPreviewProfilePicture] = useState([]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (jewellerDetail?.profilePicture) {
            setPreviewProfilePicture([{
                id: 'profilePicture',
                mediaType: 'Image',
                mediaUrl: jewellerDetail?.profilePicture
            }])
        }
    }, []);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const onSubmit = async (data, e) => {
        try {
            if (profilePicture) {
                data['profilePicture'] = profilePicture;
            }

            var responseJson = await ApiEngine.put(`${Routes._USER}/${jewellerDetail?.id}/form`, createMultiPartFormBody(data), {
                headers: {
                    'content-type': ApiKey._API_MULTIPART_FORM
                }
            });

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            onSave();
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('JEWELLER')}
                </Text>
                <Controller
                    control={control}
                    name="email"
                    defaultValue={jewellerDetail?.jewellerProfile?.companyName}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            isDisabled
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"JEWELLER"}
                            errors={errors}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('KYC_STATUS')}
                </Text>
                <Badge colorScheme={jewellerDetail?.companyProfile?.kycApproved == JewellerKycStatus._APPROVED ? 'green' : 'orange'} mb={'20px'}>
                    {t(jewellerDetail?.companyProfile?.kycApproved == JewellerKycStatus._APPROVED ? 'VERIFIED' : 'NOT_VERIFIED')}
                </Badge>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('PROFILE_PICTURE')}
                </Text>
                <Controller
                    control={control}
                    name="profilePicture"
                    defaultValue={jewellerDetail?.profilePicture ?? ''}
                    render={({ onChange, value, name }) => (
                        <CustomMediaUploader
                            fileUrls={previewProfilePicture}
                            onChange={({ fileList: newFileList }) => {
                                setProfilePicture(newFileList);
                                onChange(newFileList);
                            }}
                            onDelete={(id) => { setProfilePicture([]); }} />
                    )}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('FIRST_NAME')}
                </Text>
                <Controller
                    control={control}
                    name="firstName"
                    defaultValue={jewellerDetail?.firstName}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"ENTER_FIRST_NAME"}
                            errors={errors}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('LAST_NAME')}
                </Text>
                <Controller
                    control={control}
                    name="lastName"
                    defaultValue={jewellerDetail?.lastName}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"ENTER_LAST_NAME"}
                            errors={errors}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('EMAIL')}
                </Text>
                <Controller
                    control={control}
                    name="email"
                    defaultValue={jewellerDetail?.email}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            isDisabled
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"ENTER_EMAIL"}
                            errors={errors}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('TELEPHONE')}
                </Text>
                <Controller
                    control={control}
                    name="phoneNumber"
                    defaultValue={jewellerDetail?.phoneNumber}
                    render={({ onChange, value, name }) => (
                        <CustomPhoneInput
                            isDisabled
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            errors={errors} />
                    )}
                    rules={{
                        required: "REQUIRED"
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('WORK_PROFILE')}
                </Text>
                <Controller
                    control={control}
                    name="workProfile"
                    defaultValue={jewellerDetail?.workProfile}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"ENTER_WORK_PROFILE"}
                            errors={errors}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="50%"
                h="50"
                type="submit">
                {t('NEXT')}
            </Button>
        </form>
    );
}
